import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.css'

class Radio extends Component {
	constructor(props) {
		super(props);
  }

	render() {
		return (
			<div className={styles.content}>
				<label className={styles.contentRadio}>
					<input type="checkbox" onChange={this.props.onChange}/>
					<span className={styles.radio}/>
				</label>
				{
					this.props.children !== undefined &&
					<label>{this.props.children}</label>
				}
			</div>

		);
	}
}

export default injectIntl(Radio)
