import React, { Component, Fragmnet } from 'react'
import { injectIntl } from 'react-intl'
import Container from '../Container'
import classNames from 'classnames'

import styles from './styles.css'
import GridContainer from '../GridContainer'

class Sidebar extends Component {
    render() {
        return (
            <GridContainer>
                {this.props.children}
            </GridContainer>
        )
    }
}

class Bar extends Component {
    render() {
        return (
            <div id={this.props.id || 'sidebar.bar'} className={styles.bar}>
                {this.props.children}
            </div>
        )
    }
}

class Content extends Component {
    render() {
        return (
            <div className={classNames([styles.content], [this.props.className || undefined])}>
                {this.props.children}
            </div>
        )
    }
}

class SectionContent extends Component {
    render() {
        if (this.props.visible) {
            return (
                <div className={styles.sectionContent}>
                    {this.props.children}
                </div>
            )
        }
        return null
    }
}

class Title extends Component {
    render() {
        let toggle_classnames = [styles.title]
        if (this.props.toggle) {
            toggle_classnames.push(styles.toggle)
            if (this.props.toggle_value) {
                toggle_classnames.push(styles.toggle_opened)
            }
        }
        return (
            <div onClick={() => {
                if (this.props.toggle) {
                    this.props.onToggle()
                }
            }} className={classNames(toggle_classnames)}>
                {this.props.icon &&
                    <div className={classNames(styles.icon, styles[this.props.icon])}></div>
                }
                <span>{this.props.title}</span>
            </div>
        )
    }
}

class Section extends Component {
    render() {
        return (
            <div className={styles.section}>
                {this.props.children}
            </div>
        )
    }
}

Section.Content = SectionContent

Sidebar.Bar = Bar
Sidebar.Content = Content
Sidebar.Section = Section
Sidebar.Title = Title

export default injectIntl(Sidebar)
