import React, { Component } from 'react'
import classnames from 'classnames'
import { injectIntl } from "react-intl";
import Button from "../Button";
import styles from './styles.css'
import KeyBoardComponent from '../KeyBoard'

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: props.query || '',
            inputs: {}
        }
    }
    ref = React.createRef()
    componentDidMount() {
        if (this.ref.current)
            this.ref.current.focus();
    }
    onChangeInputs = (inputs) => {
        console.log(inputs)
    }
    getInputValue = inputName => {
        return inputs[inputName] || "";
    };
    onChangeInputs = (inputs) => {
        this.setState({ query: inputs['query'], inputs: inputs })
    }
    onSubmitKeyboard = () => {
        this.props.onSearchChanged(this.state.query)
    }
    render() {
        const { totem } = this.props;
        return (
            <div className={classnames({[styles.main]: true, [styles.searchMain]: true, [styles.totem]: !!totem})}>
                <div className={styles.container}>
                    <div className={styles.searchInput}>
                        <form onSubmit={event => {
                            event.preventDefault()
                            this.props.onSearchChanged(this.state.query)
                        }}>
                            <input
                                ref={this.ref}
                                value={this.state.query}
                                placeholder={this.props.placeholder} onChange={(e) => {
                                    this.setState({ query: e.target.value })
                                }} type="search" name="query" id="query" />
                        </form>
                        <button onClick={this.props.onChangeSearchVisibility}>x</button>
                    </div>
                    {
                        this.props.showButton &&
                        <div style={{textAlign:'center', gridColumn: 'span 12'}}>
                            <Button
                                onClick={() => {
                                    this.props.onSearchChanged(this.state.query)
                                }}
                                label={this.props.intl.formatMessage({
                                    id: "search.info.action_text",
                                })}
                            />
                        </div>
                    }
                </div>
                {
                    totem &&
                    <KeyBoardComponent
                        onChangeInputs={this.onChangeInputs}
                        inputName={'query'}
                        onSubmit={this.onSubmitKeyboard} />
                }
            </div>
        )
    }
}

export default injectIntl(Search);
