import React, { Component } from 'react'
import Router from 'next/router'
import FacebookLogin from 'react-facebook-login'
import AppleLogin from 'react-apple-login';
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import { singin, registerFacebook } from '../../api'
import { GridContainer } from '../../components'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { login } from '../../utils/auth'
import { ToastContainer, toast } from 'react-toastify'

const responseFacebook = (response) => {
    var userId = response.id;
    registerFacebook(
        userId,
        response.name,
        response.email,
        response.picture.data.url, () => {
            singin(userId, '').then(data => {
                const { token } = data
                login({
                    lang: 'es',
                    token,
                    type: 'facebook'
                })
            }).catch(err => {
            })
        });
}

class Input extends Component {
    render() {
        return (
            <div>
                <input className={styles.input} {...this.props} />
            </div>
        )
    }
}

class SigninForm extends Component {
    state = {
        username: '',
        password: ''
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    _signin = () => {
        // TODO: remove certificates issues in server to solve this glitch
        singin(this.state.user, this.state.password).then(data => {
            const { token } = data
            login({
                lang: this.props.lang,
                destination: this.props.destination,
                token,
                type: 'web'
            })
        }).catch(err => {
            console.log('error', err);
            toast.error(this.translate("login.login.error"), {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false
              });
        })
    }
    _facebookSignin = () => {

    }
    _register = () => {
        Router.push(`/${this.props.lang}/${this.props.destination.rawId}/register`)
    }
    __recomendado = () =>{

    }
    render() {

        const { user, password } = this.state

        return (
            <>
                <GridContainer>
                    <div className={styles.container}>
                        <h1 className={styles.title}>{this.translate("login.login.row-title")}</h1>
                        <div className={styles.contentRegister}>
                            <p>{this.translate("login.login.register")}
                            <button
                                onClick={this._register}
                                className={styles.span} className={styles.register}>{this.translate("login.login.register.button")}</button>
                            </p>
                        </div>
                        <div className={styles.rrss}>
                            <FacebookLogin
                                appId="173530683203278"
                                autoLoad={false}
                                fields="name,email,picture"
                                scope="public_profile,user_birthday,email"
                                callback={responseFacebook}
                                cssClass={styles.facebook}
                                textButton={this.translate("login.login.login.with.facebook")}
                                />

                            <div className={styles.contentAppleLogin}>
                                <AppleLogin
                                    clientId={"com.discoolver"}
                                    redirectURI={`${WEB_ROOT}/apple/login`}
                                    responseType={"code id_token"}
                                    responseMode={"form_post"}
                                    scope={"name email"}
                                    designProp={
                                        {
                                        height: 30,
                                        width: 140,
                                        color: "black",
                                        border: false,
                                        type: "sign-in",
                                        border_radius: 0,
                                        scale: 1,
                                        locale: "en_US",
                                        }
                                    }
                                />
                            </div>
                        </div>

                        <div className={styles.separator}></div>

                        <Input
                            value={user}
                            onChange={(event) => {
                                this.setState({ user: event.target.value })
                            }}
                            placeholder={this.translate("login.login.mail")} />
                        <Input
                            value={password}
                            onChange={(event) => {
                                this.setState({ password: event.target.value })
                            }}
                            type="password"
                            placeholder={this.translate("login.login.password")} />
                        <button
                            onClick={this._signin}
                            className={styles.button}>{this.translate("login.login.row-title").toUpperCase()}</button>

                        <a href={`/${this.props.lang}/${this.props.destination.rawId}/recover`} className={styles.forgot}>{this.translate("login.login.recover")}</a>

                        <a
                            target="_blank"
                            href="https://cms-dev.discoolver.com/"
                            className={styles.span} className={styles.cms}>acceso recomendados</a>

                    </div>
                </GridContainer>
                <ToastContainer />
            </>
        )
    }
}

export default injectIntl(SigninForm)
