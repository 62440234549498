import React from "react";
import { injectIntl } from "react-intl";

import styles from "./Product.styles.css";

const Product = ({ name, informations, amount, group, price, intl }) => {
  const getPrice = (price) =>
    intl.formatNumber(price, {
      style: "currency",
      currency: "EUR",
    });

  return (
    <div>
      <div className={styles.row}>
        <span className={styles.label}>
          <span className={styles.tag}>{group}</span>
          <span className={styles.name}>{name}</span>
          <span className={styles.informations}>
            {informations} - {amount} x {getPrice(price / amount)}
          </span>
        </span>
        <span className={styles.price}>{getPrice(price)}</span>
      </div>
    </div>
  );
};

Product.defaultProps = {
  name: "",
  group: "",
  informations: "",
  amount: 1,
  price: 0,
};

export default injectIntl(Product);
