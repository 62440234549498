import React, { Component } from 'react'
import styles from './styles.css'
import Link from 'next/link'
import classnames from "classnames";

class SectionTitle extends Component {
    translate = (id) => {
        return this.props.intl.formatMessage({ id });
    }
    render() {
        var router = '';
        if(this.props.see_more){
            var hrefWorker = this.props.href;
            if(hrefWorker.indexOf('?') > 0){
                hrefWorker = hrefWorker.substring(hrefWorker, hrefWorker.indexOf('?'));
            }
            const hrefData = hrefWorker.split('/');
            router = `/?locale=${hrefData[1]}&destination=${hrefData[2]}`;
            if(hrefData.length > 4){
                router = `${router}&categoryRawId=${hrefData[3]}`;
            }
            if(hrefData.length > 5){
                router = `${router}&subcategoryRawId=${hrefData[4]}`;
            }
            if(hrefWorker.indexOf('?') > 0){
                router = `${router}&${hrefWorker.substring(hrefWorker.indexOf('?')+1)}`;
            }
        }
        return (
            <div className={styles.sectionContent}>
                <div className={classnames([styles.header], {[styles.mainTitle]: !this.props.see_more}) }>
                    <h2>{this.props.title}</h2>
                    <h3>{this.props.description}</h3>
                </div>
                {this.props.see_more && (
                    <Link href={this.props.href} as={this.props.href} >
                        <a>{this.translate('section.title.see-all-results')}</a>
                    </Link>
                )}
            </div>
        )
    }
}

export default SectionTitle
