const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { SHOPIFY } = publicRuntimeConfig
const Client = require('shopify-buy');

exports.isShopify = (raw_id) => {
  const data = SHOPIFY.filter(obj => {
    const key = Object.keys(obj)[0];
    return key.indexOf(`${raw_id}_shopify`) !== -1
  });
  return data.length > 0;
}

exports.createClient = (raw_id) => {
  const key = `${raw_id}_shopify`;
  const data = SHOPIFY.filter(obj => {
    const envkey = Object.keys(obj)[0];
    return envkey.indexOf(key) !== -1
  })[0][key].split(':');
  return Client.buildClient({
    domain: data[0],
    storefrontAccessToken: data[1],
    /*language: language*/
  });
}

exports.fetchNextPage = (client, collection, finish) => {
  client.fetchNextPage(collection).then((products) => {
    finish(products);
  })
}

exports.collections = (client, finish) => {
  client.collection.fetchAllWithProducts().then((collections) => {
    finish(collections);
  })
}

exports.products = (client, finish) => {
  client.product.fetchAll().then((products) => {
    finish(products);
  });
}

exports.product = (client, productId, finish) => {
  client.product.fetch(productId).then((product) => {
    finish(product);
  });
}

exports.search = (client, query, finish) => {
  client.product.fetchQuery({query: `availableForSale:true AND ((title:${query}*) OR (description:${query}*))`, sortBy:'id'}).then((products) => {
    finish(products);
  });
}

exports.createcheckout = (client, finish) => {
  return client.checkout.create().then((checkout) => {
    finish(checkout);
  });
}

exports.getCheckout = (client, checkout, finish) => {
  return client.checkout.fetch(checkout.id).then((checkout) => {
    finish(checkout);
  });
}

exports.addProduct = (client, checkout, variant, quantity, finish) => {
  const lineItemsToAdd = [
    {
      variantId: variant.id,
      quantity: quantity
    }
  ];
  // Add an item to the checkout
  return client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((checkout) => {
    finish(checkout);
  });
}

exports.updateProduct = (client, checkout, variant, quantity, finish) => {
  const lineItemsToUpdate = [
    {id: variant.id, quantity: quantity}
  ];

  // Update the line item on the checkout (change the quantity or variant)
  return client.checkout.updateLineItems(checkout.id, lineItemsToUpdate).then((checkout) => {
    finish(checkout);
  });
}

exports.deleteProduct = (client, checkout, variant, finish) => {
  const lineItemIdsToRemove = [
    variant
  ];

  // Remove an item from the checkout
  return client.checkout.removeLineItems(checkout.id, lineItemIdsToRemove).then((checkout) => {
    finish(checkout);
  });
}

exports.addAddress = (client, checkout, email, address1, city, country, firstName, lastName, phone, province, zip, finish) => {
  const shippingAddress = {
    address1: address1,
    address2: null,
    city: city,
    company: null,
    country: country,
    firstName: firstName,
    lastName: lastName,
    phone: phone,
    province: province,
    zip: zip
  };

  const input = {email: email};
  //client.checkout.updateAttributes(checkout.id, input).then((response) => {
    client.checkout.updateShippingAddress(checkout.id, shippingAddress).then((checkout) => {
      finish(checkout);
    });
  //});
}

exports.complete = (client, checkout) => {
  /*
  Completing a checkout
  The simplest way to complete a checkout is to use the webUrl
  property that is returned when creating a checkout.
  This URL redirects the customer to Shopify's online checkout to complete the purchase.
  */
}
