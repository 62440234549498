import React, { useRef, useEffect, useState } from "react";
import styles from "./styles.css";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import { withRouter } from "next/router";
import { skipKeywords } from "json-schema-traverse";
import Router from "next/router";

function ExperienceItem(props) {
  const cancelAutomaticLiked = props.cancelAutomaticLiked;
  const [liked, setLiked] = useState(!!props.liked);
  const parentComponent = useRef(null);

  const _load = () => {
    if (!parentComponent.current) return;
    const dataSource = parentComponent.current.getAttribute("data-src");
    if (!dataSource) return;
    parentComponent.current.style.backgroundImage = `url(${dataSource})`;
    parentComponent.current.removeAttribute("data-src");
  };

  const _getImage = () => {
    if (props.item) {
      if (props.type === "highlight" && props.item.tab) {
        return props.item.tab.normal;
      }
      if (props.item.gallery && props.item.gallery[0]) {
        return props.item.gallery[0].normal;
      }
      return props.item.urlTabPicture;
    }
  };

  const _getStyle = () => {
    if (props.item.images) {
      //return { backgroundImage: `url(${props.item.images.tab.miniature})` }
      const image = _getImage();
      return { backgroundImage: `url(${image})` };
    }
    return {};
  };

  //useEffect(() => {
  //    ('useEffect')
  //    window.addEventListener('load', _load, false);
  //    return () => window.removeEventListener('load', _load)
  //}, [])

  const style = _getStyle();
  const imageSource = _getImage();

  let component_styles = [styles.experienceitem];
  if (props.type == "first_item") component_styles.push(styles.first_item);

  let button_heart_style = [styles.heart];
  if (liked) {
    button_heart_style.push(styles.accent);
  }

  const _onClick = () => {
    if (props.isReccomended) {
      props.router.push(link.href, link.as).then(() => window.scrollTo(0, 0));
    } else if (props.onClick !== undefined) {
      if (props.item.link !== null) {
        Router.push(props.item.link.replace("https:", ""));
      } else {
        props.onClick(props.item.idNotLoose);
      }
    }
  };

  const _onHeartClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!cancelAutomaticLiked) {
      setLiked(!liked);
    }

    props.onHeartClick();
  };

  const translate = (id) => props.intl.formatMessage({ id });

  const link = {
    href: `/experience-details?lang=${props.intl.locale}&destination=${props.item.destinationRawId}&categoryRawId=${props.item.categoryRawId}&subCategoryRawId=${props.item.subcategoryRawId}&experience=${props.item.rawId}`, //${props.intl.locale}
    as: `/${props.intl.locale}/${props.item.destinationRawId}/${props.item.categoryRawId}/${props.item.subcategoryRawId}/${props.item.rawId}`,
  };

  let subcategory = new Array();
  if (props.item.subcategoryName !== undefined) {
    if (props.forYou === true) {
      const categories = props.item.categoryRawId.split(",");
      let index = 0;
      let count = 0,
        max = categories.length;
      for (; count < max; count++) {
        if (props.categoryRawId === categories[count]) {
          index = count;
          break;
        }
      }
      const names = props.item.subcategoryName.split(",");
      subcategory.push(names[index]);
    } else {
      const categories = props.item.categoryRawId.split(",");
      const names = props.item.subcategoryName.split(",");
      let count = categories.length,
        max = names.length;
      if (max == 1) {
        count = 0;
        subcategory.push(names[0]);
      } else {
        const randomIndex = Math.floor(Math.random() * (max - 1) + count);
        subcategory.push(names[randomIndex]);
      }
    }
  }

  return (
    <>
      <div
        onClick={_onClick}
        className={classnames(...component_styles)}
        onLoad={() => {}}
      >
        <div
          ref={parentComponent}
          style={style}
          className={styles.image}
          data-src={imageSource}
        >
          {subcategory.length > 0 && (
            <div className={styles.categories}>
              <p className={styles.category}>{subcategory[0]}</p>
            </div>
          )}
        </div>
        <div className={styles.infos}>
          <div>
            <h4>{props.item.name}</h4>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: props.item.descriptionShort }}
            ></p>
            {subcategory.length > 0 && (
              <div>
                {subcategory.map((name) => {
                  return <p className={styles.category}>{name}</p>;
                })}
              </div>
            )}
          </div>
        </div>
        {props.isReccomended && (
          <div className={styles.commands}>
            <button
              onClick={_onHeartClick}
              className={classnames(button_heart_style)}
            ></button>
          </div>
        )}
      </div>
    </>
  );
}

ExperienceItem.defaultProps = {
  onHeartClick() {},
  item: {},
};

export default injectIntl(withRouter(ExperienceItem));
