import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.css'

class Check extends Component {
	constructor(props) {
		super(props);
  }

	styles() {
		return Object.assign({}, this.props.style);
  }

	className() {
		var classDefault = styles.check;
		if (this.props.checked) {
			classDefault = styles.selected;
    }
		return classDefault;
  }

	check(e) {
		if (this.props.click != undefined) {
			this.props.click(!this.props.checked);
		}
  }

	render() {
		return (
			<label htmlFor={this.props.for} id={this.props.id} className={this.className()} style={this.styles()} onClick={this.check.bind(this)}>
				{this.props.children}
			</label>
		);
	}
}

export default injectIntl(Check)
