import React, { forwardRef, useContext, useState, useImperativeHandle, useRef, useEffect } from "react"
import FacebookLogin from 'react-facebook-login'
import AppleLogin from 'react-apple-login';
import styles from './styles.css'
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { APP, WEB_ROOT } = publicRuntimeConfig
import { injectIntl } from "react-intl"
import classnames from "classnames";
import { singin, registerFacebook } from "../../api"
import { login } from "../../utils/auth"

const responseFacebook = (response) => {
    var userId = response.id;
    registerFacebook(
        userId,
        response.name,
        response.email,
        response.picture.data.url, () => {
            singin(userId, '').then(data => {
                const { token } = data
                login({
                    lang: 'es',
                    token,
                    type: 'facebook'
                })
            }).catch(err => {
            })
        });
}

function Input(props) {
    return (
        <div>
            <input className={styles.input} {...props} />
        </div>
    )
}

const LoginModal = injectIntl(function (props) {

    const [form, setForm] = useState({
        user: '',
        password: ''
    })

    const _setForm = (name, value) => {
        setForm(f => ({ ...f, [name]: value }))
    }

    useEffect(() => {
        if (!props.visible) return
        const body = document.getElementsByTagName('body')[0]
        body.classList.add("modal-open");
        return () => body.classList.remove("modal-open")
    }, [props.visible])

    const _translate = (id) => props.intl.formatMessage({ id })

    const _signin = () => {
        singin(form.user, form.password).then(data => {
            const { token } = data
            login({
                lang: props.intl.locale,
                token,
                type: 'web'
            })
        }).catch(err => {
            (err.message)
        })
    }

    if (!props.visible) return null

    return (
        <div id={styles.modal_container}>
            <div className={styles.content}>
                <button onClick={props.onClose} className={styles.close}>close</button>
                <div className={classnames(styles.brand, styles[APP])}>
                    <span>Discoolver</span>
                </div>
                <div className={styles.container}>
                    <Input
                        onChange={e => {
                            _setForm('user', e.target.value)
                        }}
                        type="text"
                        value={form.user}
                        placeholder={_translate("login.login.mail")} />
                    <Input
                        onChange={e => {
                            _setForm('password', e.target.value)
                        }}
                        value={form.password}
                        placeholder={_translate("login.login.password")}
                        type="password" />
                    <button
                        onClick={_signin}
                        className={styles.button}>{_translate("login.login.row-title").toUpperCase()}</button>
                    <a href={`/${props.intl.locale}/${props.destination.rawId}/recover`} className={styles.forgot}>{_translate("login.login.recover")}</a>
                    <div className={styles.separator}></div>

                    <FacebookLogin
                        appId="173530683203278"
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile,user_birthday,email"
                        callback={responseFacebook}
                        cssClass={styles.facebook}
                        textButton={_translate("login.login.login.with.facebook")}
                        />

                    <div className={styles.contentAppleLogin}>
                        <AppleLogin
                            clientId={"com.discoolver"}
                            redirectURI={`${WEB_ROOT}/apple/login`}
                            responseType={"code id_token"}
                            responseMode={"form_post"}
                            scope={"name email"}
                            designProp={
                                {
                                height: 30,
                                width: 140,
                                color: "black",
                                border: false,
                                type: "sign-in",
                                border_radius: 0,
                                scale: 1,
                                locale: "en_US",
                                }
                            }
                            />
                    </div>
                </div>
            </div>
        </div>
    )
})


export default forwardRef(function (props, ref) {

    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => ({
        show: () => {
            setVisible(true)
        },
        hide: () => {
            setVisible(false)
        }
    }))

    if (!visible) return null

    return (
        <LoginModal
            onClose={() => {
                setVisible(false)
            }}
            {...props} visible={visible} />
    )
})
