import React, { Fragment, Component } from 'react'
import { HeaderBar, Footer, Hero, NavigationBar, CheckoutBar, CheckoutPaymentForm } from '..'
import Seo from "../Seo/index";
import Link from 'next/link'
import ReactGA from 'react-ga'
import classNames from "classnames"
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import Experiences from '../Experiences'
import GridContainer from '../GridContainer'
import styles from './styles.css'

class CheckoutPayment extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.state = {
      checkout: undefined
    }
  }
  componentDidMount = () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.setState({ checkout: JSON.parse(localStorage.getItem('checkout')) });
  }
  componentWillReceiveProps = (nextProps) => {

  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`
  }
  renderContent = () => {
    if (!this.props.experience.data.rawId) return null

    const { experience, categoryId, subcategoryId, destination, lang } = this.props

    let search_query = `/${lang}/${destination.rawId}/map`
    let search_query_router = `/map?lang=${lang}&destination=${destination.rawId}`
    if (categoryId) {
        search_query = `${search_query}?c=${categoryId}`
        search_query_router = `${search_query_router}&c=${categoryId}`
        if (subcategoryId) {
            search_query = `${search_query}&subc=${subcategoryId}`
            search_query_router = `${search_query_router}&subc=${subcategoryId}`
        }
    }

    search_query = `${search_query}&recommendationId=${experience.data.rawId}`
    search_query_router = `${search_query_router}&recommendationId=${experience.data.rawId}`

    let images = this.props.experience.data.pictures.filter((p) => p.main)[0]

    return (
      <Fragment>

        <CheckoutBar intl={this.props.intl} selected={[false, false, true]}/>

        <CheckoutPaymentForm
          {...this.props}
          checkout={this.state.checkout} />

        <Experiences
          swipeable={true}
          destination={this.props.destination}
          title={this.translate("recommended.recommended.near.title")}
          description={this.translate("recommended.recommended.near.subtitle", { name: this.props.experience.data.name })}
          data={this.props.experience.near}
          isReccomended={true} />
        <GridContainer>
          <div className={styles.seemap}>
            <Link
              href={search_query_router}
              as={search_query}
              >
              <a className={styles.button}>
                {this.translate("recommended.recommended.see_on_map")}
              </a>
            </Link>
          </div>
        </GridContainer>
      </Fragment>
    )
}
  getCategories = () => {
    return [{ rawId: 'para_ti', name: this.translate("sidebar.menu.for_you") }].concat(this.props.destination.categories)
  }
  render() {
    let { ogTitle, ogDescription, images } = this.props.experience.data
    const ogImage = images['tab']['ogimage'];
    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: 'website',
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: 'discoolver',
              images: [
                  {
                      url: ogImage,
                      width: 559,
                      height: 413
                  },
              ],
            },
            twitter: {
              handle: '@handle',
              site: '@site',
              cardType: 'summary_large_image',
            },
            facebook: {
              appId: 783375525490958,
            }
          }}
        />

        <HeaderBar
          onSearchChanged={this.onSearchChanged}
          destination={this.props.destination}
          locale={this.props.locale} />

        <NavigationBar
          ref={ref => {
              this.navigation = ref
          }}
          selected={this.props.categoryId}
          categories={this.getCategories()}
          onSearchChanged={this.onSearchChanged}
          onCategoryChange={this.onCategoryChange}
          show_results={false}
          show_filters={false}
          show_search={true} />

        {this.renderContent()}

        <Footer destination={this.props.destination} />
      </div>
    )
  }
}

export default CheckoutPayment
