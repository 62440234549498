import React, { Component } from 'react'
import styles from './styles.css'
import classnames from 'classnames'

class GridContainer extends Component {
    render() {
        return (
            <div className={classnames(styles.container, this.props.className)}>
                {this.props.children}
            </div>
        )
    }
}

export default GridContainer
