import React, { useRef } from "react";
import styles from "./styles.css";
import { injectIntl } from "react-intl";
import { withRouter } from "next/router";
import moment from "moment"

function TripItem(props) {
  const parentComponent = useRef(null);

  const _load = () => {
    if (!parentComponent.current) return;
    const dataSource = parentComponent.current.getAttribute("data-src");
    if (!dataSource) return;
    parentComponent.current.style.backgroundImage = `url(${dataSource})`;
    parentComponent.current.removeAttribute("data-src");
  };

  moment.locale(props.intl.locale);

  const numberOfDays = props.item.days.length
  const days = `${moment(props.item.days[0].day).format('DD/MM/YYYY')} - ${moment(props.item.days[numberOfDays-1].day).format('DD/MM/YYYY')}`

  const _getData = () => {
    const day = props.item.days[0];
    const recommended = day.recommendeds[0];
    return {
      name: recommended.name,
      descriptionShort: recommended.descriptionShort,
      image: recommended.image,
      style: { backgroundImage: `url(${recommended.image})` },
      link: {
        href: `/my-trip?lang=${props.intl.locale}&destination=${recommended.destinationRawId}&token=${recommended.token}`, //${props.intl.locale}
        as: `/${props.intl.locale}/${recommended.destinationRawId}/my-trip/${recommended.token}`,
      }
    }
  }

  const data = _getData();

  const _onClick = () => {
    props.router.push(data.link.href, data.link.as).then(() => window.scrollTo(0, 0));
  };

  return (
    <>
      <div
        onClick={_onClick}
        className={styles.tripitem}
        onLoad={() => {}}
      >
        <div
          ref={parentComponent}
          style={data.style}
          className={styles.image}
          data-src={data.image}
        >
          <div className={styles.days}>
            <p className={styles.day}>{days}</p>
          </div>
        </div>
        <div className={styles.infos}>
          <div>
            <h4>{data.name}</h4>
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: data.descriptionShort }}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
}

TripItem.defaultProps = {
  item: {},
};

export default injectIntl(withRouter(TripItem));
