import React, { Component } from 'react'
import classnames from 'classnames'
import { saveUser, saveAvatar } from '../../api'
import { GridContainer, ProfileTitle } from '../../components'
import styles from './styles.css'
import { injectIntl } from 'react-intl'

class Input extends Component {
    render() {
        return (
            <div className={styles.contentInput}>
                <label>{this.props.placeholder}</label>
                <input className={styles.input} {...this.props} />
            </div>
        )
    }
}

class Select extends Component {
    render() {
        return (
            <div className={styles.contentInput}>
                <label>{this.props.placeholder}</label>
                <select className={styles.select} {...this.props}>
                    {this.props.children}
                </select>
            </div>
        )
    }
}

class ProfileForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            username: this.props.user.login,
            email: this.props.user.mail,
            avatar: this.props.user.avatar,
            password: '',
            oldPassword: '',
            newPassword: '',
            city: this.props.user.city,
            language: this.props.user.language
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    _update = () => {
        const { oldPassword, newPassword, confirmPassword, city, language } = this.state
        const { user } = this.props;
        var formOk = true;
        if(oldPassword !== ''){
            if(newPassword !== confirmPassword || oldPassword === newPassword){
                formOk = false;
            }
        }
        if(formOk){
            saveUser(user.token, oldPassword, newPassword, city, language, () => {
            });
        }
    }

    handleBrowse =(e) => {
        e.preventDefault();
        this.logoInput.click();
    };

    onFileSelected = (e) => {
        const { user } = this.props;
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.currentTarget.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const self = this;
        reader.onload = function () {
            saveAvatar(user.token, reader.result, () => {
                self.setState({ avatar: reader.result })
                user.avatar = reader.result
            });
        };
        reader.onerror = function (error) {
        };
    };

    render() {

        const { username, email, oldPassword, newPassword, confirmPassword, city, language, avatar } = this.state
        const { destinations, user } = this.props;

        const destinationsOptions = destinations.map((d) => {
            return d.destinations.map((d) => {
                return <option value={d.rawId}>{d.name}</option>
            })
        })

        return (
            <GridContainer>
                <ProfileTitle
                    title={'Configuración general de la cuenta'}
                    description={''}
                    key={'my_profyle'}
                    anchor={'my_profyle'}
                    className={styles.firstContent} />
                <div className={styles.container}>
                    <div
                        style={
                            {
                                background: `url(${avatar})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }
                        }
                        className={styles.avatar}>
                    </div>
                    <input
                        className={styles.inputChangePhoto}
                        ref={ref => {
                            this.logoInput = ref
                        }}
                        type='file'
                        onChange={this.onFileSelected} />
                    <a className={styles.linkChangePhoto} href='' onClick={this.handleBrowse}>Cambiar foto</a>
                    {user.loginType === 'web' &&
                        <div>
                            <Input
                                value={username}
                                onChange={(event) => {
                                    this.setState({ username: event.target.value })
                                }}
                                placeholder={this.translate("profile.profile.user")}
                                disabled={true}/>
                            <Input
                                value={email}
                                onChange={(event) => {
                                    this.setState({ email: event.target.value })
                                }}
                                placeholder={this.translate("profile.profile.email")}
                                disabled={true} />
                            <Input
                                value={oldPassword}
                                onChange={(event) => {
                                    this.setState({ oldPassword: event.target.value })
                                }}
                                type="password"
                                placeholder={this.translate("profile.profile.old.password")} />
                            <Input
                                value={newPassword}
                                onChange={(event) => {
                                    this.setState({ newPassword: event.target.value })
                                }}
                                type="password"
                                placeholder={this.translate("profile.profile.new.password")} />
                            <Input
                                value={confirmPassword}
                                onChange={(event) => {
                                    this.setState({ confirmPassword: event.target.value })
                                }}
                                type="password"
                                placeholder={this.translate("profile.profile.confirm.password")} />
                        </div>
                    }

                    <Select
                        value={city}
                        onChange={(event) => {
                            this.setState({ city: event.target.value })
                        }}
                        placeholder={this.translate("profile.profile.city")} >
                        {destinationsOptions}
                    </Select>
                    <Select
                        value={language}
                        onChange={(event) => {
                            this.setState({ language: event.target.value })
                        }}
                        placeholder={this.translate("profile.profile.language")}>
                            <option value="es">ES</option>
                            <option value="en">EN</option>
                    </Select>
                    <button
                        onClick={this._update}
                        className={styles.button}>{this.translate("profile.profile.save").toUpperCase()}</button>
                </div>
            </GridContainer>
        )
    }
}

export default injectIntl(ProfileForm)
