import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { GridContainer, StoreProductItem } from '../'

class StoreProducts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: props.products
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    render() {
        let hasNextPage = false;
        if(this.props.products.length > 0)
            hasNextPage = this.props.products[this.props.products.length-1].hasNextPage;
        else
            return (
                <GridContainer className={styles.storeContainer}>
                    Cargando....
                </GridContainer>
            )

        const productItems = this.props.products.map((product, index) => {
          return (
              <StoreProductItem key={`store_product_item_${product.id}`} item={product} defaultLink={this.props.defaultLink} />
          )
        });

        return (
            <GridContainer className={styles.storeContainer}>

              {productItems}
              {
                  hasNextPage &&
                  <div className={styles.contentNextPage}>
                      <button
                            onClick={this.props.nextPage}
                        >{this.translate('store.products.see_more')}</button>
                  </div>

              }
            </GridContainer>
        )
    }
}

StoreProducts.defaultProps = {
  selected: '',
  products: [],
}

export default injectIntl(StoreProducts)
