export const limit_string = (str, limit = 0, appendix = '...', separator = ' ') => {
    if ((str || '').length <= limit) return str;
    let strNope = str.substr(0, str.lastIndexOf(separator, limit));
    return (strNope += appendix);
}

export const isAuth = () => {
    if (window.localStorage) {
        const user = JSON.parse(window.localStorage.getItem('user'));
        return !!(user && user.token)
    } else {
        return false
    }
}

export const getLocalStorageItem = (key = '') => {
} 
