import React, { Component } from 'react'
import styles from './styles.css'
import { ExperienceItem, Container, ProfileTitle, Arrow, CustomSlider, GridContainer } from '../'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import LoginModal from '../LoginModal'
import { withAppState } from '../../contexts/app-state'
import { addFavorite, removeFavorite } from '../../api'

class MyFavorities extends Component {
    loginModal = React.createRef()
    constructor(props){
        super(props);
        this.state = {
            favorites: this.props.favorites
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }

    onHeartClick = (item, index) => {
        const { user: { is_logged }, token } = this.props.appState
        const { favorites } = this.state;
        if (is_logged) {
            removeFavorite(token, item.rawId, () => {
                const index = this.props.user.favourites.indexOf(item.id);
                if(index !== -1){
                    this.props.user.favourites.splice(index, 1);
                }
                const indexObject = favorites.indexOf(item);
                if(indexObject !== -1){
                    favorites.splice(indexObject, 1);
                }
                this.setState({ favorites: favorites })
            });
            return
        }
        if (this.loginModal.current) this.loginModal.current.show()
    }

    render() {
        let experiences_data = this.state.favorites
        return (
            <GridContainer>
                <div className={styles.swiper_experiences}>
                    <ProfileTitle
                        title={`Mis favoritos`}
                        see_more={false}
                        description={`Actualmente tienes estos planes marcados como favoritos. Si deseas eliminar alguno de la lista, desmarca el icono <img src="/static/images/love.svg" />`}
                        key={'mis_favorites_title'}
                        anchor={'my_favorites'}
                        />
                    <div className={classNames(styles.swiper)}>
                        <CustomSlider>
                            {experiences_data.map((item, index) => {
                                let style_type = undefined
                                if (index === 0) {
                                    style_type = "first_item"
                                }
                                item.favorite = true;
                                return (
                                    <ExperienceItem
                                        onHeartClick={() => this.onHeartClick(item, index)}
                                        block_action={true}
                                        type={style_type}
                                        destination={this.props.destination}
                                        categoryRawId={this.props.categoryRawId}
                                        key={`swiper-${item.rawId}`}
                                        item={item}
                                        liked={true}
                                        cancelAutomaticLiked={true}
                                        isReccomended={true} />
                                )
                            })}
                        </CustomSlider>
                    </div>
                </div>
            </GridContainer>
        )
    }
}

MyFavorities.defaultProps = {
    swipeable: false,
    data: [],
    highlights: []
}

export default injectIntl(withAppState(MyFavorities))
