import React, { Component } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { GridContainer } from '../'
import { changePlan, getFinishPlanCallback } from '../../api'

const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { WEB_ROOT } = publicRuntimeConfig;

var QRCode = require('qrcode.react');

class PlanMyTripTree extends Component {
    constructor(props) {
        super(props)
        moment.locale(this.props.intl.locale)
        this.isTuritop = this.isTuritop.bind(this)
        this.notTuritop = this.notTuritop.bind(this)
        this.isContact = this.isContact.bind(this)
        this.onChangePlan = this.onChangePlan.bind(this)
        this.getLinkRecommended = this.getLinkRecommended.bind(this)
        this.state = {
          plan: this.props.plan
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    getLink = (href) => {
      if (href != undefined) {
        if (href !== '' && href !== '#') {
          if (href.indexOf('http://') === -1 && href.indexOf('https://') === -1) {
            return 'https://' + href;
          }
          return href;
        }
      }
      return "#!"
    }
    getLinkRecommended = (recommended) => {
      return `/${this.props.intl.locale}/${this.props.destination.rawId}/${recommended.categoryRawId}/${recommended.subcategoryRawId}/${recommended.rawId}`
    }
    isTuritop = (recommended) => {
      const turitopRegex = /^P[\d]+/;
      return turitopRegex.exec(recommended.urlMembership) !== null;
    }
    notTuritop = (recommended) => {
        return !this.isTuritop(recommended);
    }
    isContact = (recommended) => {
      return recommended.typeOfMembership === '4';
    }
    onChangePlan = (recommended) =>{
      changePlan(recommended.token, recommended.id, this.props.intl.locale, () => {
        getFinishPlanCallback(recommended.token, this.props.intl.locale, this.onReloadPlan)
      });
    }
    onReloadPlan = (plan) => {
      this.setState({ plan })
    }
    render() {
      const { intl } = this.props;
      const { plan } = this.state;
      let count = 0
      const days = plan.days.map(d => {
        count++
        const day = moment(d.day);
        const recommendeds = d.recommendeds.map(r => {
          const start = moment.utc(r.startDate);

          const contentShopping = !!r.urlMembership || r.containsProducts;

          return (
            <div className={styles.contentRow}>
              <label className={styles.empty}></label>
              <label className={styles.hour}>{start.format('HH:mm')}</label>
              <div className={styles.recommended}>
                <div className={styles.image} style={{backgroundImage:`url(${r.image})`}} />
                <div className={styles.data}>
                  <label className={styles.name}>{r.name}</label>
                  {
                    r.address &&
                    <label className={styles.address}>{r.address}</label>
                  }
                  
                </div>
              </div>
              <div className={styles.buy}>
                <button
                  className={classnames([styles.button, styles.buttonWhite])}
                  onClick={() => {
                    this.onChangePlan(r)
                  }}
                  >{this.translate('plan.my.trip.suggest.another.plan')}
                </button>
                {
                  contentShopping && !!r.urlMembership && this.isTuritop(r) &&
                  <div class="load-turitop" data-service={r.urlMembership} data-lang="es" data-embed="button" data-afftag="ttafid">
                    {r.periodicSchedule ? this.translate("details.reserve_now") : this.translate("details.buy_now")}
                  </div>
                }

                {
                  contentShopping && !!r.urlMembership && this.notTuritop(r) && this.isContact(r) &&
                  <a className={styles.button} href={this.getLinkRecommended(r)}>
                    {r.periodicSchedule ? this.translate("details.reserve_now") : this.translate("details.buy_now")}
                  </a>
                }

                {
                  contentShopping && !!r.urlMembership && this.notTuritop(r) && !this.isContact(r) &&
                  <a className={styles.button} href={this.getLink(r.urlMembership)}>
                    {r.periodicSchedule ? this.translate("details.reserve_now") : this.translate("details.buy_now")}
                  </a>
                }

                {
                  contentShopping && !!r.containsProducts &&
                  <a className={styles.button} href={this.getLinkRecommended(r)}>
                    {r.productType === 'PRODUCT'
                      ? this.translate("details.buy_now")
                      : this.translate("details.reserve_now")}
                  </a>
                }
              </div>
            </div>
          )
        });
        return (
          <div>

            <div className={styles.contentRow}>
              <div className={styles.calendar}>
                <label data-value={day.format('ddd')}></label>
                <span  data-value={day.format('D')}></span>
              </div>
              <label className={styles.day}>{this.translate('plan.my.tryp.finish.day')} {count}</label>
            </div>

            {recommendeds}

          </div>
        )
      });
      return (
          <GridContainer className={styles.contentPlan}>
            <div className={styles.title}><h1>{this.translate('plan.my.tryp.finish.title')}</h1></div>
            <div className={styles.contentTree}>
              {days}
            </div>
            <div className={styles.contentQRCode}>
              <QRCode value={`${WEB_ROOT}${this.props.pathname}`} />
              <h1>{this.translate('plan.my.tryp.finish.qrcode')}</h1>
            </div>
          </GridContainer>
        )
    }
}

PlanMyTripTree.defaultProps = {

}

export default injectIntl(PlanMyTripTree)
