import React, { Component } from 'react'
import Router from 'next/router'
import styles from './styles.css'
import classNames from "classnames"
import Link from 'next/link'

class Input extends Component {
  render() {
      return (
        <input
          className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
          {...this.props} />
      )
  }
}

class InputSmall extends Component {
  render() {
      return (
        <input
          className={classNames({[styles.inputSmall]: true, [styles.input_error]: this.props.error})}
          {...this.props} />
      )
  }
}

class CheckoutPaymentForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      province: '',
      city: '',
      zipCode: '',
      phone: '',
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',
      errorCountry: '',
      errorProvince: '',
      errorCity: '',
      errorZipCode: '',
      errorPhone: '',
    }
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  checkoutProducts = () => {
    const { categoryId, subcategoryId, lang, destination, experience} = this.props;
    Router.push(`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/shopify/checkout-address`)
  }
  _payment = () => {
    const { categoryId, subcategoryId, lang, destination, experience} = this.props;
    Router.push(`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/shopify/checkout-finish`)
  }
  render() {
    const { firstName, lastName, email, country, province, city, zipCode, phone, errorFirstName, errorLastName, errorEmail, errorCountry, errorProvince, errorCity, errorZipCode, errorPhone } = this.state;
    const { categoryId, subcategoryId, lang, destination, experience, checkout } = this.props;

    var products = undefined;
    if(checkout !== undefined){
      products = products.lineItems.map(item => {
        return (
          <div className={styles.checkoutContentProductsDetail}>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailName}>{item.title} ({item.variant.title})</label>
              <label className={styles.checkoutContentProductsDetailPrice}>{item.quantity * item.variant.price} €</label>
            </div>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailKey}>Cantidad</label>
              <label className={styles.checkoutContentProductsDetailValue}>{item.quantity}</label>
            </div>
          </div>
        )
      });
    }
    return (
      <div className={styles.checkoutContent}>
        <div className={styles.checkoutContentForm}>
          <label>Datos de pago</label>
          <div className={styles.checkoutContentFormRow}>

          </div>
        </div>
        <div className={styles.checkoutContentProducts}>
          <div className={styles.checkoutContentProductsRow}>
            <label>Resumen</label>
            <Link
              as={`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/shopify/checkout-products`}
              href={''} >
                <a>{'Modificar Compra'}</a>
            </Link>
          </div>
          {products}

          <div className={styles.checkoutContentProductsDetail}>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailName}>Zapatillas E-Jordan</label>
              <label className={styles.checkoutContentProductsDetailPrice}>234€</label>
            </div>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailKey}>Numero:</label>
              <label className={styles.checkoutContentProductsDetailValue}>44</label>
            </div>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailKey}>Cantidad:</label>
              <label className={styles.checkoutContentProductsDetailValue}>1</label>
            </div>
          </div>
          <div className={styles.checkoutContentProductsLine}></div>
          <div className={styles.checkoutContentProductsRow}>
            <label>Precio</label>
            <label>0€</label>
          </div>
          <button className={styles.button} onClick={this._payment}>Pagar</button>
        </div>
      </div>
    )
  }
}

export default CheckoutPaymentForm
