import React, { Component } from 'react'
import Router from 'next/router'
import styles from './styles.css'
import classNames from "classnames"
import Link from 'next/link'

class Input extends Component {
  render() {
      return (
        <input
          className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
          {...this.props} />
      )
  }
}

class InputSmall extends Component {
  render() {
      return (
        <input
          className={classNames({[styles.inputSmall]: true, [styles.input_error]: this.props.error})}
          {...this.props} />
      )
  }
}

class CheckoutAdressForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      province: '',
      city: '',
      zipCode: '',
      phone: '',
      address: '',
      errorFirstName: '',
      errorLastName: '',
      errorEmail: '',
      errorCountry: '',
      errorProvince: '',
      errorCity: '',
      errorZipCode: '',
      errorPhone: '',
      errorAddress: '',
    }
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  checkoutProducts = () => {
    const { categoryId, subcategoryId, lang, destination, experience} = this.props;
    Router.push(`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/ecommerce/checkout`)
  }
  _save = () => {
    const { categoryId, subcategoryId, lang, destination, experience} = this.props;
    const {
      firstName,
      lastName,
      email,
      country,
      province,
      city,
      zipCode,
      phone,
      address
    } = this.state;
    if(this._validForm()){
      this.props.addUserData({
        firstName,
        lastName,
        email,
        country,
        province,
        city,
        zipCode,
        phone,
        address
      });
    }

    //Router.push(`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/shopify/checkout-finish`)
  }
  _validForm = () => {
    const {
      firstName,
      lastName,
      email,
      country,
      province,
      city,
      zipCode,
      phone,
      address
    } = this.state;
    var result = true;
    if(firstName === ''){
      result = false;
      this.setState({ errorFirstName: 'Obligatorio' });
    }
    if(lastName === ''){
      result = false;
      this.setState({ errorLastName: 'Obligatorio' });
    }
    if(email === ''){
      result = false;
      this.setState({ errorEmail: 'Obligatorio' });
    }
    if(country === ''){
      result = false;
      this.setState({ errorCountry: 'Obligatorio' });
    }
    if(province === ''){
      result = false;
      this.setState({ errorProvince: 'Obligatorio' });
    }
    if(city === ''){
      result = false;
      this.setState({ errorCity: 'Obligatorio' });
    }
    if(zipCode === ''){
      result = false;
      this.setState({ errorZipCode: 'Obligatorio' });
    }
    if(phone === ''){
      result = false;
      this.setState({ errorPhone: 'Obligatorio' });
    }
    if(address === ''){
      result = false;
      this.setState({ errorAddress: 'Obligatorio' });
    }
    return result;
  }
  render() {
    const { firstName, lastName, email, country, province, city, zipCode, phone, address, errorFirstName, errorLastName, errorEmail, errorCountry, errorProvince, errorCity, errorZipCode, errorPhone, errorAddress } = this.state;
    const { categoryId, subcategoryId, lang, destination, experience, order} = this.props;

    var products = undefined;
    if(order !== undefined){
      products = order.lines.map(line => {
        return (
          <div className={styles.checkoutContentProductsDetail}>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailName}>{line.name}</label>
              <label className={styles.checkoutContentProductsDetailPrice}>{line.getAmount()}€</label>
            </div>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailKey}>Numero:</label>
              <label className={styles.checkoutContentProductsDetailValue}>{line.variant.name}</label>
            </div>
            <div className={styles.checkoutContentProductsRow}>
              <label className={styles.checkoutContentProductsDetailKey}>Cantidad:</label>
              <label className={styles.checkoutContentProductsDetailValue}>{line.quantity}</label>
            </div>
          </div>
        )
      });
    }

    return (
      <div className={styles.checkoutContent}>
        <div className={styles.checkoutContentForm}>
          <label>Datos de la reserva</label>
          <div className={styles.checkoutContentFormRow}>
            <Input
              value={firstName}
              onChange={(event) => {
                  this.setState({ firstName: event.target.value })
              }}
              error={errorFirstName !== '' ? true : false}
              placeholder={'Nombre'} />
            <Input
              value={lastName}
              onChange={(event) => {
                  this.setState({ lastName: event.target.value })
              }}
              error={errorLastName !== '' ? true : false}
              placeholder={'Apellidos'} />
          </div>
          <div className={styles.checkoutContentFormRow}>
            <Input
              value={email}
              onChange={(event) => {
                  this.setState({ email: event.target.value })
              }}
              error={errorEmail !== '' ? true : false}
              placeholder={'Email'} />
            <Input
              value={address}
              onChange={(event) => {
                  this.setState({ address: event.target.value })
              }}
              error={errorAddress !== '' ? true : false}
              placeholder={'Dirección'} />
          </div>
          <div className={styles.checkoutContentFormRow}>
            <InputSmall
              value={country}
              onChange={(event) => {
                  this.setState({ country: event.target.value })
              }}
              error={errorCountry !== '' ? true : false}
              placeholder={'País'} />
            <InputSmall
              value={province}
              onChange={(event) => {
                  this.setState({ province: event.target.value })
              }}
              error={errorProvince !== '' ? true : false}
              placeholder={'Provincia'} />
            <InputSmall
              value={city}
              onChange={(event) => {
                  this.setState({ city: event.target.value })
              }}
              error={errorCity !== '' ? true : false}
              placeholder={'Ciudad'} />
          </div>
          <div className={styles.checkoutContentFormRow}>
            <InputSmall
              value={zipCode}
              onChange={(event) => {
                  this.setState({ zipCode: event.target.value })
              }}
              error={errorZipCode !== '' ? true : false}
              placeholder={'Código postal'} />
            <InputSmall
              value={phone}
              onChange={(event) => {
                  this.setState({ phone: event.target.value })
              }}
              error={errorPhone !== '' ? true : false}
              placeholder={'Teléfono'} />
          </div>

        </div>
        <div className={styles.checkoutContentProducts}>
          <div className={styles.checkoutContentProductsRow}>
            <label>Resumen</label>
            <Link
              as={`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/ecommerce/checkout`}
              href={`/ecommerce/checkout?lang=${lang}&destination=${destination.rawId}&categoryRawId=${categoryId}&subCategoryRawId=${subcategoryId}&experience=${experience.data.rawId}`} >
                <a>{'Modificar Compra'}</a>
            </Link>
          </div>
          {products}
          <div className={styles.checkoutContentProductsLine}></div>
          <div className={styles.checkoutContentProductsRow}>
            <label>Precio</label>
            {
              order &&
              <label>{order.getAmount()}€</label>
            }
          </div>
          <button className={styles.button} onClick={this._save}>Finalizar</button>
        </div>
      </div>
    )
  }
}

export default CheckoutAdressForm
