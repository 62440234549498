import React, { Component } from 'react'
import styles from './styles.css'
import classnames from 'classnames'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import Link from 'next/link'
import GridContainer from '../GridContainer'
import HeroCalendar from '../HeroCalendar'
import Search from '../NavigationBar/Search'
import { Router } from 'next/router'


class DatesNavigation extends Component {
    static defaultProps = {
        date: new Date(),
        destinations: [],
        startDate: new Date(),
        endDate: new Date()
    }
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment(props.startDate),
            endDate: moment(props.endDate),
            destination: props.destination,
            focusedInput: null,
            show_search_input: false
        }
    }
    componentDidMount() {
        this.setState({ destination: this.props.destination })
        this.resize()
    }
    resize() {
        if (window.innerWidth <= 640) {
            this.setState({ orientation: "horizontal", numberOfMonths: 1 })
        }
    }
    getOptions = () => {
        moment.locale(this.props.intl.locale)
        let dates = []
        let date_with_moment = moment(this.props.date, 'YYYYMMDD').subtract(6, 'days')
        for (let index = 0; index < 11; index++) {
            let new_date = date_with_moment.add(1, 'days')
            dates.push({
                raw: new_date.format('YYYYMMDD'),
                week_day: new_date.format('ddd').toUpperCase(),
                day: new_date.format('D')
            })
        }
        return dates
    }
    translate = (id, params = {}) => {
        return this.props.intl.formatMessage({ id }, { ...params })
    }
    getDateInformations = () => {
        moment.locale(this.props.lang)
        return moment(this.props.date).format('ll')
    }


    onChangeSearchVisibility = () => {
        this.setState({ show_search_input: !this.state.show_search_input })
    }

    onFiltersChanged = () => {

    }
    render() {
        let search_btn_class = [styles.search]
        let map_btn_class = [styles.map]
        if (this.state.show_search_input) {
            search_btn_class.push(styles.selected)
        }
        if (this.props.showMap) {
            map_btn_class.push(styles.selected)
        }
        return (
            <div>
                <HeroCalendar
                    background={this.props.destination.image}
                    title={(
                        <React.Fragment>
                            <p>{`${this.translate("header.hero.title")} ${this.props.destination.name}`}</p>
                        </React.Fragment>
                    )}
                    date={this.props.date}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    destination={this.props.destination.rawId}
                    destinations={this.props.destinations}
                    onDestinationChanged={(destination) => {
                        Router.push(`/${this.props.intl.locale}/${destination.rawId}`)
                    }}
                    description={`${this.translate("header.hero.line1")} ${this.translate("header.hero.line2")}`}
                    locale={this.props.intl.locale}
                    filters={this.props.filters}
                    perfectFor={this.props.perfectFor}
                />
                <div className={styles.navigation}>
                    <GridContainer className={styles.categoriesContainer}>
                        <nav className={styles.categories}>
                            {this.getOptions().map((o, index) => {
                                let item_styles = [styles.item];
                                if (this.props.date === o.raw) {
                                    item_styles.push(styles.accent)
                                }
                                return (
                                    <Link
                                        href={`/calendar?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&date=${o.raw}`}
                                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/calendar?date=${o.raw}`}
                                        key={index} >
                                        <a className={classnames(item_styles)}>
                                            <span>{o.week_day}</span>
                                            <span>{o.day}</span>
                                        </a>
                                    </Link>
                                )
                            })}
                        </nav>
                        <div className={styles.filtersCommands}>
                            <React.Fragment>
                                <div className={styles.results}>
                                    <b>{this.props.results}</b> {this.translate(this.props.resultsText)}
                                </div>
                            </React.Fragment>
                            <div className={classnames(map_btn_class)}>
                                <button
                                    onClick={this.props.onChangeMap}
                                    className={styles.action}>
                                    <span className={classnames([styles.icon, styles.iconmap], {[styles.active]:this.props.showMap})}></span>
                                </button>
                            </div>
                            <div className={classnames(search_btn_class)}>
                                <button
                                    onClick={this.onChangeSearchVisibility}
                                    className={styles.action}>
                                    <span className={classnames([styles.icon, styles.magnify])}></span>
                                </button>
                            </div>
                        </div>
                    </GridContainer>
                </div>
                {this.state.show_search_input && (
                    <Search
                        query={this.props.query}
                        placeholder={this.translate(this.props.searchPlaceholder)}
                        onSearchChanged={this.props.onSearchChanged}
                        onChangeSearchVisibility={this.onChangeSearchVisibility} />
                )}
            </div>

        )
    }
}

DatesNavigation.defaultProps = {
    resultsText: 'search.info.results',
    searchPlaceholder: "search.info.search",
}

export default injectIntl(DatesNavigation)
