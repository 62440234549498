import React, { Component } from "react";
import styles from "./styles.css";
import classNames from "classnames";
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { APP } = publicRuntimeConfig
require("./global.css");
import Link from "next/link";
import Router from "next/router";
import { injectIntl } from "react-intl";
import GridContainer from "../GridContainer";
import FilterDialog from "../NavigationBar/FilterDialog";
import HomeNoAuth from "../HomeNoAuth";
//import MenuLogin from '../MenuLogin'
//import MenuFiltros from '../MenuFiltros'

const Item = (props) => {
  let { accent, label, className, ...rest } = props;
  let component_styles = [styles.item, className];
  if (accent) component_styles.push(styles.accent);
  return (
    <div className={classNames(component_styles)}>
      <Link {...rest}>
        <a {...rest}>{label}</a>
      </Link>
    </div>
  );
};

const ItemClick = (props) => {
  let { accent, label, ...rest } = props;
  let component_styles = [styles.item];
  if (accent) component_styles.push(styles.accent);
  return (
    <div className={classNames(component_styles)}>
      <a {...rest}>{label}</a>
    </div>
  );
};

class Dropdown extends Component {
  state = {
    is_open: false,
  };
  _getItem = () => {
    const { value, values } = this.props;
    const item = values.filter((i) => i.value === value)[0];
    if (item) {
      return item;
    }
    return {};
  };
  _onDropdownClick = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    let dropdown_styles = [styles.dropdown];
    let active_value = this._getItem();
    if (this.state.is_open) dropdown_styles.push(styles.dropdown_open);

    return (
      <button
        onClick={this._onDropdownClick}
        className={classNames(dropdown_styles)}
      >
        <span>{active_value.label}</span>
        <div className={styles.chevron}></div>
        <div className={styles.options}>
          {this.props.values.map((item, index) => {
            return (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.onChange(item);
                }}
                key={index}
                className={styles.dropdownitem}
              >
                {item.label}
              </a>
            );
          })}
        </div>
      </button>
    );
  }
}

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.query || "",
      is_open: false,
      show_search_input: false,
      show_filters: false,
      show_maps: true /* Añado esta propiedad */,
      show_categories: false,
      selected_filters: {},
      selected_categories: [],
    };
  }
  search = React.createRef();
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  componentDidUpdate() {
    const body = document.getElementsByTagName("body")[0];
    if (this.state.show_categories) {
      body.classList.add("modal-opened");
    } else {
      body.classList.remove("modal-opened");
    }
  }
  componentWillUnmount() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("modal-opened");
  }
  onChangeLang = (lang) => {
    let url_path = Router.asPath.split('/')
    url_path[1] = lang.value
    Router.push(url_path.join('/'))
  }
  render() {
    let search_classes = [styles.toggleButton, styles.searchButton];
    if (this.state.show_search_input) {
      search_classes.push(styles.searchOpen);
    }
    const { destination, categoryRawId, subCategoryRawId } = this.props;
    const category = destination.categories.filter(c => {
      return c.rawId === categoryRawId;
    });
    let subcategories = []
    if(category.length > 0){
      subcategories = category[0].subcategories;
    }
    let stylesBrand = [styles.brandContainer]
    if(this.props.filters.length > 0){
      stylesBrand.push(styles.whitFilter)
    }
    return (
      <React.Fragment>
        <header className={styles.navbar}>
          <GridContainer className={styles.navbarCotainer}>
            <div className={styles.mobileMenu}>
              <button
                onClick={() => {
                  this.setState({ show_categories: true });
                }}
                className={styles.toggleButton}
              >
                <span>Toggle Menu</span>
              </button>
            </div>
            <div className={classNames(stylesBrand)}>
              {!this.state.show_search_input && (
                <Link href={this.props.href} as={this.props.as} >
                  <a className={classNames(styles.brand, styles[APP])} alt="Discoolver">
                    Discoolver
                  </a>
                </Link>
              )}
              {this.state.show_search_input && (
                <div className={styles.inputContainer}>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.props.onSearchChanged(this.state.query);
                    }}
                  >
                    <input
                      onChange={(e) => {
                        this.setState({ query: e.target.value });
                      }}
                      type="search"
                      name="query"
                      value={this.state.query}
                      ref={(ref) => {
                        this.search = ref;
                      }}
                      placeholder={this.translate("search.info.search")}
                    />
                  </form>
                </div>
              )}
            </div>
            <nav className={styles.menu}>{this.props.children}</nav>
            <div className={styles.mobileCommands}>
              {/*Buton Maps
              {!this.state.show_search_input && this.props.filters.length > 0 && (
                <button
                  onClick={() => {
                    this.setState({ show_maps: !this.state.show_maps });
                  }}
                  className={classNames(styles.toggleButton, styles.mapIcon)}
                >
                  <span>{this.translate("search.filters")}</span>
                </button>
              )}
              */}
              {!this.state.show_search_input && this.props.filters.length > 0 && (
                <button
                  onClick={() => {
                    this.setState({ show_filters: !this.state.show_filters });
                  }}
                  className={classNames(
                    styles.toggleButton,
                    styles.filtersIcon
                  )}
                >
                  <span>{this.translate("search.filters")}</span>
                </button>
              )}
              <button
                onClick={() => {
                  this.setState(
                    { show_search_input: !this.state.show_search_input },
                    () => {
                      if (this.search) {
                        this.search.focus();
                      }
                    }
                  );
                }}
                className={classNames(search_classes)}
              >
                <span>{this.translate("search.search")}</span>
              </button>
            </div>
          </GridContainer>
          {this.state.show_filters && this.props.filters.length > 0 && (
            <FilterDialog
              filters={this.props.filters}
              filtersActive={this.props.filtersActive}
              show_filters={this.props.show_filters}
              filtersText={this.translate("filter.filtersText")}
              filterText={this.translate("filter.filterText")}
              closeFiltersText={this.translate("filter.closeFiltersText")}
              buttonAcceptText={this.translate("filter.buttonAcceptText")}
              allItemsAreSelectedText={this.translate(
                "filter.allItemsAreSelectedText"
              )}
              selectAllText={this.translate("filter.selectAllText")}
              searchText={this.translate("filter.searchText")}
              selected={this.state.selected_filters}

              subcategories={subcategories}
              onSubcategoryChange={this.props.onSubcategoryChange}
              subcategorySelected={subCategoryRawId}
              subcategoryFilterText={this.translate("filter.subcategoryFilterText")}

              onChangeFiltersVisibility={() => {
                this.setState({ show_filters: !this.state.show_filters });
              }}
              onFilterChanged={(selected, filter) => {
                let { selected_filters } = this.state;
                selected_filters[filter.idFilter] = selected;
                this.setState({ selected_filters });
                if (this.props.onChangeFilter !== undefined) {
                  this.props.onChangeFilter(selected_filters);
                }
              }}
            />
          )}
        </header>
        {/* aquí esta el navbar mobile */}
        {this.state.show_categories && (
          <div className={styles.categoriesContainer}>
            <div className={styles.categoriesContent}>
              <div className={styles.dialogCommand}>
                <button
                  className={classNames(
                    styles.toggleButton,
                    styles.close,
                    styles.closeButton
                  )}
                  onClick={() => {
                    this.setState({ show_categories: false });
                  }}
                ></button>
                <div className={styles.languageMobile}>
                  <NavBar.Dropdown
                    onChange={this.onChangeLang}
                    values={[
                      { value: 'es', label: 'ES' },
                      { value: 'en', label: 'EN' },
                      { value: 'fr', label: 'FR' },
                      { value: 'de', label: 'DE' },
                      { value: 'pt', label: 'PT' },
                      { value: 'it', label: 'IT' }
                    ]}
                    value={this.props.intl.locale} />
                </div>

                {/* componente de filtros nuevos */}
                <HomeNoAuth
                  destinations={this.props.destinations}
                  destination={this.props.destination}
                  categoryRawId={this.props.categoryRawId}
                  subCategoryRawId={this.props.subCategoryRawId}
                  relationships={this.props.relationships}
                  perfectFor={this.props.perfectFor}
                  startDate={this.props.startDate}
                  endDate={this.props.endDate}
                  locale={this.props.intl.locale}
                />
                {/* <MenuLogin/> */}
                {/* <MenuFiltros/> */}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

NavBar.defaultProps = {
  onSearchChanged() {},
  filters: [],
  show_filters: true,
};

NavBar.Item = Item;
NavBar.ItemClick = ItemClick;
NavBar.Dropdown = Dropdown;

export default injectIntl(NavBar);
