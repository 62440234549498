import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'
import classNames from "classnames";
import Categories from './Categories'
import Filters from './Filters'
import Search from './Search'
import Subcategories from './Subcategories'
import FilterDialog from './FilterDialog'
import { injectIntl } from 'react-intl'

class NavigationBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: props.categories,
            show_search_input: false,
            show_filters: false,
            selected_filters: {}
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    onItemChange = (rawId) => {
        this.props.onItemClick(rawId)
        this.props.onItemChange(rawId)
    }
    onChangeFiltersVisibility = () => {
        this.setState({ show_filters: !this.state.show_filters })
    }
    onChangeSearchVisibility = () => {
        this.setState({ show_search_input: !this.state.show_search_input })
    }
    render() {
        let search_buttton_classname = [styles.iconButton]
        let filters_button_classname = [styles.iconButton]
        let navigation_classname = [styles.navigation]
        if (!(this.props.show_filters && this.props.filters.length > 0) && this.props.show_search) {
            search_buttton_classname.push(styles.show_search_button_only)
        }
        if (this.state.show_search_input) {

            search_buttton_classname.push(styles.active)
        }
        if (this.state.show_filters) {
            filters_button_classname.push(styles.active)
            navigation_classname.push(styles.navigationOpened)
        }
        return (
            <div className={classnames(navigation_classname)}>
                <div className={styles.main}>
                    <div className={styles.container}>
                        <div className={styles.categories}>
                            <Categories
                                selected={this.props.selected}
                                items={this.props.categories}
                                onCategoryChange={this.props.onCategoryChange} />
                        </div>
                        <Filters
                            show_filters={this.props.filters.length > 0 && this.props.show_filters}
                            show_results={this.props.show_results}
                            commands={this.props.commands}
                            results={this.props.results}
                            resultsText={this.translate(this.props.resultsText)}
                            filterText={this.props.filterText}
                            is_filter_visible={this.state.show_filters}
                            is_search_visible={this.state.show_search_input}
                            onChangeFiltersVisibility={this.onChangeFiltersVisibility}
                            onChangeSearchVisibility={this.onChangeSearchVisibility}
                            showMap={this.props.showMap}
                            onChangeMap={this.props.onChangeMap} />
                    </div>
                </div>
                {/*
                 {this.props.subcategories.length > 0 && (
                    <Subcategories
                        opened={this.props.opened}
                        selected={this.props.subcategory_selected}
                        onSubcategoryChange={this.props.onSubcategoryChange}
                        items={this.props.subcategories} />
                )}
                */}

                {this.state.show_search_input && (
                    <Search
                        query={this.props.query}
                        placeholder={this.translate(this.props.searchPlaceholder)}
                        onSearchChanged={this.props.onSearchChanged}
                        onChangeSearchVisibility={this.onChangeSearchVisibility} />
                )}
                {(this.props.show_filters || this.props.show_search) && (
                    <div className={classnames(styles.main, styles.mobileCommands)}>
                        <div className={styles.containerIconsButton}>
                            {(this.props.show_filters && this.props.filters.length > 0) && (
                                <a href="#" className={classnames(filters_button_classname)} onClick={(event) => {
                                    event.preventDefault()
                                    this.onChangeFiltersVisibility()
                                }}>
                                    <span className={classNames(
                                        styles.icon,
                                        styles.filtersIcon
                                    )}></span>
                                </a>
                            )}
                            {this.props.onChangeMap &&
                                <a href="#" className={classnames(filters_button_classname, {[styles.mapActive]: this.props.showMap})} onClick={(event) => {
                                    event.preventDefault()
                                    this.props.onChangeMap();
                                }}>
                                    <span className={classNames(
                                        styles.icon,
                                        styles.iconmap
                                    )}></span>
                                </a>
                            }
                            {this.props.show_search && (
                                <a href="#" className={classnames(search_buttton_classname)} onClick={(event) => {
                                    event.preventDefault()
                                    this.onChangeSearchVisibility()
                                }}>
                                    <span className={classNames(
                                        styles.icon,
                                        styles.magnify
                                    )}></span>
                                </a>
                            )}
                        </div>
                    </div>
                )}
                {(this.state.show_filters && this.props.filters.length > 0) && (
                    <FilterDialog
                        filters={this.props.filters}
                        filtersActive={this.props.filtersActive}
                        show_filters={this.props.show_filters}
                        filtersText={this.translate("filter.filtersText")}
                        filterText={this.translate("filter.filterText")}
                        closeFiltersText={this.translate("filter.closeFiltersText")}
                        buttonAcceptText={this.translate("filter.buttonAcceptText")}
                        allItemsAreSelectedText={this.translate("filter.allItemsAreSelectedText")}
                        selectAllText={this.translate("filter.selectAllText")}
                        searchText={this.translate("filter.searchText")}
                        selected={this.state.selected_filters}
                        onChangeFiltersVisibility={this.onChangeFiltersVisibility}
                        subcategories={this.props.subcategories}
                        onSubcategoryChange={this.props.onSubcategoryChange}
                        subcategorySelected={this.props.subcategory_selected}
                        subcategoryFilterText={this.translate("filter.subcategoryFilterText")}
                        onFilterChanged={ (selected, filter) => {
                            let { selected_filters } = this.state
                            selected_filters[filter.idFilter] = selected
                            this.setState({ selected_filters })
                            if(this.props.onChangeFilter !== undefined){
                                this.props.onChangeFilter(selected_filters);
                            }
                        }} />
                )}
            </div>
        )
    }
}

NavigationBar.defaultProps = {
    selected: '',
    subcategory_selected: '',
    query: '',
    filters: [],
    categories: [],
    subcategories: [],
    show_filters: true,
    show_results: true,
    show_search: true,
    commands: true,
    results: 0,
    is_loading: false,
    filtersText: 'Filters',
    filterText: 'Filter',
    closeFiltersText: 'Close filters',
    searchText: 'Search',
    resultsText: 'search.info.results',
    searchPlaceholder: "search.info.search",
    onItemClick(rawId) { },
    onItemChange(rawId) { },
    onSearchChanged(query) { },
    onCategoryChange(rawId) { },
    onSubcategoryChange(rawId) { }
}

export default injectIntl(NavigationBar)
