import React from "react";
import { Footer, HeaderBar } from "../components";

const Layout = (props) => (
  <div className={props.subclass}>
    {props.children}
    <Footer
      categoryRawId={props.categoryRawId}
      subcategoryRawId={props.subcategoryRawId}
      destination={props.destination}
      {...props.footerProps}
    />
  </div>
);

export default Layout;
