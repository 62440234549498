import React, { Component } from 'react'
import Link from 'next/link'
import classnames from 'classnames'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { logout } from '../../utils/auth'

class NotFavourites extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  translate = (id) => {
    return this.props.intl.formatMessage({ id: `not.favourites.${id}` })
  }
  render() {
    return (
      <div className={styles.content}>
        <div className={styles.center}>
          <image className={styles.image} />
          <label className={styles.title}>{this.translate('title')}</label>
          <p className={styles.description}>{this.translate('description')}</p>
          <Link href={this.props.href} as={this.props.as} >
            <a className={styles.button}>
              {this.translate('button')}
            </a>
          </Link>
        </div>
      </div>
    )
  }
}

export default injectIntl(NotFavourites)
