import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "next/router";
import { injectIntl } from "react-intl";
import classnames from "classnames";
import GridContainer from "../GridContainer";
import CustomSlider from "../CustomSlider";
import styles from "./styles.css";
import Link from "next/link";
import AdvertisingItem from "./Item/index";

function Advertising(props) {
  console.log('sponsoreds', props)
  if(props === undefined){
    return(
      undefined
    )
  }
  const items = props.sponsoreds.map((item, index) => {
    return (
      <AdvertisingItem
        onHeartClick={props.onHeartClick}
        rawId={item.rawId}
        background={item.images.tab}
        title={item.name}
        description={item.descriptionShort}
        address={item.address}
        time={item.nextSchedule}
        showInCalendar={item.showInCalendar}
        href={`/experience-details?lang=${props.intl.locale}&destination=${item.destinationRawId}&categoryRawId=${item.categoryRawId}&subCategoryRawId=${item.subcategoryRawId}&experience=${item.rawId}`}
        as={`/${props.intl.locale}/${item.destinationRawId}/${item.categoryRawId}/${item.subcategoryRawId}/${item.rawId}`}
      />
    )
  });
  return (
    <div className={styles.advertisingContainer}>
      <GridContainer>
        <div className={styles.swiper_experiences}>
          <div className={classnames(styles.swiper)}>
            <CustomSlider
              variableWidth={false}
              arrow={false}
              autoplay={true}
              autoplaySpeed={5000}
            >
              {items}
            </CustomSlider>
          </div>
        </div>
      </GridContainer>
    </div>
  );
}

Advertising.defaultProps = {
  onHeartClick() {},
  sponsoreds: []
};

export default injectIntl(withRouter(Advertising));
