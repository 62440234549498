import Variant from './variant';
import Address from './address';
import OrderLine from './orderLine';
import Product from './product';

class Order {

  id: string;
  lines: OrderLine[];
  address: Address;
  email: string;

  static fromDouble(){
    return new this(1,
    [
      new OrderLine(Product.fromDouble("1", "Pizzaiolo"), Variant.fromDouble("1", "M", 25.0), 3),
      new OrderLine(Product.fromDouble("2", "Fuck the Slice"), Variant.fromDouble("2", "L", 35.0), 2),
      new OrderLine(Product.fromDouble("3", "Grosso Pack"), Variant.fromDouble("3", "XL", 55.0),4),
    ], "", "")
  }
  static fromJson(json){
    var lines = new Array();
    json.lines.forEach(line => {
      lines.push(OrderLine.fromJson(line));
    });
    return new this(json.id, lines, json.address, json.email);
  }
  static fromShopify(order){
    console.log('order', order)
    var lines = [];
    order.lineItems.forEach(line => {
      lines.push(OrderLine.fromShopify(line));
    });
    return new this(order.id, lines, Address.fromShopify(order), order.email);
  }
  constructor(id, lines, address, email){
    this.id = id;
    this.lines = lines;
    this.address = address;
    this.email = email;
  }

  getAmount() : number{
    var amount = 0.0;
    this.lines.forEach(line => {
      amount += line.getAmount();
    })
    return amount;
  }

  getCountLines(){
    return this.lines.length;
  }
}

export default Order;
