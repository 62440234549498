import React, { Component } from 'react'
import styles from './styles.css'
import classnames from 'classnames'

class ProfileTitle extends Component {
    render() {
        return (
            <div id={this.props.anchor} className={classnames(styles.sectionContent, this.props.className)}>
                <div className={styles.header}>
                    <span><h2>{this.props.title}</h2></span>
                    <h3 dangerouslySetInnerHTML={{ __html: this.props.description }}></h3>
                </div>
            </div>
        )
    }
}

export default ProfileTitle
