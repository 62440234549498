import React from "react";
import styles from "./BorderButton.styles.css";

const BorderButton = ({ href, label }) => {
  return (
    <a {...{ href }} className={styles.borderButton}>
      <span>{label}</span>
    </a>
  );
};

BorderButton.defaultProps = {
  label: "label",
};

export default BorderButton;
