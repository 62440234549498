import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.css'
import Slider from "react-slick";

class CustomSlider extends Component {
  constructor(props) {
    super(props);
    this.slide = this.slide.bind(this);
    this.state = {

    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.initialSlide !== nextProps.initialSlide) {
      this.slider.slickGoTo(nextProps.initialSlide)
    }
    return true;
  }

  slide = (x, y, z) => {
    if (y === 0 && z === 0) {
      if (x > 1) {
        this.slider.slickNext()
      } else if (x < -1) {
        this.slider.slickPrev()
      }
    }
  }

  render() {
    var defaultSettings = {
      initialSlide: this.props.initialSlide === undefined ? 0 : this.props.initialSlide,
      infinite: this.props.infinite === undefined ? true : this.props.infinite,
      autoplay: this.props.autoplay === undefined ? false : this.props.autoplay,
      autoplaySpeed: this.props.autoplaySpeed === undefined ? 3000 : this.props.autoplaySpeed,
      slidesToShow: 1,
      slickGoTo: this.props.initialSlide === undefined ? 0 : this.props.initialSlide,
      centerMode: this.props.centerMode === undefined ? false : this.props.centerMode,
      variableWidth: this.props.variableWidth === undefined ? true : this.props.variableWidth,
      arrows: this.props.arrows === undefined ? true : this.props.arrows,
      swipe: this.props.swipe === undefined ? true : this.props.swipe,
      swipeToSlide: true,
      speed: 300,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            //arrows: false,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            //arrows: false,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 1,
            rows: this.props.rowsMobile === undefined ? 1 : this.props.rowsMobile
          }
        }
      ]
    };
    let settings = defaultSettings;
    if (this.props.settings) {
      settings = this.props.settings;
    }
    return (
      <div onWheel={(e) => {
        //e.preventDefault();
        this.slide(e.deltaX, e.deltaY, e.deltaZ);
      }}>
        <Slider {...settings} className={styles.slider} ref={slider => this.slider = slider}>
          {this.props.children}
        </Slider>
      </div>
    );
  }
}

export default CustomSlider
