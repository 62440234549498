import React, { useRef, useEffect, useState } from 'react'
import styles from './styles.css'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import { withRouter } from 'next/router'
import { skipKeywords } from 'json-schema-traverse'
import Router from 'next/router'

function ToggleSwitch(props) {

    const parentComponent = useRef(null)

    const _onChange = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation()
        props.onChange(props.id)
    }

    const translate = (id) => props.intl.formatMessage({ id })

    return (
        <>
            <div className={styles.contentSwitch}>
              {
                props.text &&
                <label>{translate(props.text)}&nbsp;</label>
              }
              <label class={styles.switch}>
                <input type="checkbox" onChange={_onChange} />
                <span class={classnames(styles.slider, styles.round)}></span>
              </label>
            </div>
        </>
    )
}

ToggleSwitch.defaultProps = {
    onChange() { },
    label: ''
}

export default injectIntl(withRouter(ToggleSwitch))
