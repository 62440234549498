import React, { Component } from 'react'
var ReactDOM = require('react-dom');
import styles from './styles.css'
import classNames from 'classnames'
import { Container, CustomSlider } from '..'
import { injectIntl } from 'react-intl'
import Link from 'next/link'
import withSizes from 'react-sizes';

class Navigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryRawId: props.subCategorySelected,
            show_subcategories: false,
            show_filters: props.filterSelected !== 0 && props.show_filters_button,
            windowSize: 0,
            show_search: false,
            text: ''
        }
    }
    componentDidMount() {
        this.resize();
    }
    resize() {
        this.setState({ windowSize: window.innerWidth });
    }
    getOptions = () => {
        const labels = this.props.destination.categories.map((category) => {
            return {
                label: category.name,
                icon: styles[category.rawId],
                value: category.rawId,
                category: category
            }
        })
        if (this.props.notShowForYou) {
            return [...labels];
        }
        return [
            { label: this.translate('sidebar.menu.for_you'), icon: styles.forYou, value: '' },
            ...labels
        ]
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    show = (e) => {
        e.preventDefault()
        this.setState({ show_filters: true })
    }
    hide = (e) => {
        e.preventDefault()
        this.setState({ show_filters: false })
    }
    clear = (e) => {
        e.preventDefault()
        if (this.props.onFiltersClear) {
            this.props.onFiltersClear()
        }
    }
    render() {
        const { show_filters, windowSize } = this.state;
        const { filterSelected } = this.props;

        const showFilterText = windowSize <= 767 ? 'search.show_filter_xs' : 'search.show_filter';
        const showHideText = windowSize <= 767 ? 'search.hide_filter_xs' : 'search.hide_filter';
        const showClearText = windowSize <= 767 ? 'search.clear_filter_xs' : 'search.clear_filter';

        let index = this.props.destination.categories.findIndex((category) => {
            return category.rawId === this.props.selected;
        });
        if (!this.props.notShowForYou) {
            index++;
        }
        return (
            <div className={classNames({ [styles.navigation]: true, [styles.navigation_with_filter]: this.state.show_filters })}>
                <div className={classNames(styles.categories, styles.main, { [styles.show_filters_button]: this.props.show_filters_button })}>
                    <CustomSlider arrows={false} centerMode={false} infinite={false} arrowsMobile={false} swipe={true}>
                        {this.getOptions().map((o, index) => {
                            let item_styles = [styles.item];
                            if (o.value === this.props.selected) {
                                item_styles.push(styles.accent)
                            }
                            return (
                                <div>
                                    {this.props.clickCategory ? (
                                        <a key={o.value} href={`#`} onClick={() => {
                                            this.props.clickCategory({ category: o.category });
                                        }}>
                                            <a className={classNames(item_styles)}>
                                                <span className={classNames(styles.icon, o.icon)}></span>
                                                <span className>{o.label}</span>
                                            </a>
                                        </a>
                                    ) : (
                                            <Link key={o.value}
                                                href={`/${o.value}?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                                                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/${o.value}`}
                                                >
                                                <a className={classNames(item_styles)}>
                                                    <span className={classNames(styles.icon, o.icon)}></span>
                                                    <span className="text-category">{o.label}</span>
                                                </a>
                                            </Link>
                                        )}

                                </div>
                            )
                        })}
                    </CustomSlider>
                    <div className={classNames(styles.show_subcategories, { [styles.show_filters_button]: this.props.show_filters_button })}>
                        <div style={{ position: 'relative' }}>
                            {this.props.show_filters_button && this.props.category && (this.props.category.subcategories.length > 0) &&
                                <div className={styles.content_filter_button} style={{ display: 'inline-block' }}>
                                    {
                                        !show_filters ?
                                            (
                                                <a className={classNames({ [styles.show_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={this.show} href="#"><span>{this.translate(showFilterText)}</span></a>
                                            ) : (
                                                filterSelected > 0 ? (
                                                    <a className={classNames({ [styles.show_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={this.clear} href="#"><span>{this.translate(showClearText)}</span></a>
                                                )
                                                    :
                                                    (
                                                        <a className={classNames({ [styles.show_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={this.hide} href="#"><span>{this.translate(showHideText)}</span></a>
                                                    )
                                            )
                                    }
                                </div>
                            }
                            <div className={classNames({ [styles.content_search_button]: true, [styles.show_search_input]: this.state.show_search })}>
                                <input type="text" className={styles.search_input}
                                    onChange={(event) => {
                                        this.setState({ text: event.target.value });
                                    }} />
                                <a className={classNames({ [styles.search_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={(e) => {
                                    e.preventDefault()
                                    const { show_search, text } = this.state;
                                    if (!show_search) {
                                        this.setState({ show_search: true });
                                    } else {
                                        if (text !== '') {
                                            this.props.onSearchChanged(text);
                                        }
                                        this.setState({ show_search: false });
                                    }
                                }} href="#"><span>{this.translate("search.search")}</span></a>
                            </div>

                        </div>
                    </div>
                </div>

                {this.state.show_filters && (
                    <div className={styles.filters}>
                        <Container>
                            <div style={{ flex: 1 }}>
                                <div className={styles.mobileFilterBar}>
                                    <a className={classNames(styles.close_button)} onClick={this.hide} href="#"><span></span></a>
                                    <span className={styles.title}>{this.translate("search.show_filter_xs")}</span>
                                    {
                                        filterSelected > 0 ? (
                                            <a className={classNames({ [styles.show_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={this.clear} href="#"><span>{this.translate(showClearText)}</span></a>
                                        )
                                            :
                                            (
                                                <a className={classNames({ [styles.show_button]: true, [styles.show_buton_selcted]: this.state.show_filters })} onClick={this.hide} href="#"><span>{this.translate(showHideText)}</span></a>
                                            )
                                    }
                                </div>
                                <div className={styles.content}>
                                    <div className={styles.left}>
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                )}
            </div>
        )
    }
}

Navigation.defaultProps = {
    city: {},
    show_results: true,
    show_filters_button: true,
    subcategories: [],
    count: 0
}

class Filter extends Component {
    render() {
        return (
            <div className={styles.filter} {...this.props}>
                <b>{this.props.name}</b>
                {this.props.children}
            </div>
        )
    }
}

Navigation.Filter = Filter

class ScrollFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value != undefined ? this.props.value.name : undefined,
        }
    }

    componentWillUpdate = (nextProps, nextState) => {
        if (nextProps.value !== undefined && nextProps.value.name !== this.state.value) {
            this.setState({ value: nextProps.value != undefined ? nextProps.value.name : undefined });
        } else if (nextProps.value === undefined && this.state.value !== undefined) {
            this.setState({ value: undefined });
        }
    }

    render() {
        const { value } = this.state;
        return (
            <div className={classNames({ [styles.toggleContainer]: true, [styles.toggleContainerSelected]: value || this.props.selected })}>
                <button
                    onClick={(event) => {
                        const element = ReactDOM.findDOMNode(event.target).parentNode.parentNode;
                        this.props.show(this.props.filter, element.offsetLeft + 48, element.offsetTop + 78)
                    }}
                    className={styles.toggleButton}>
                    <span>
                        {value || this.props.name}
                    </span>
                </button>
            </div>
        )
    }
}
Navigation.ScrollFilter = ScrollFilter

class ScrollOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value != undefined ? this.props.value.name : undefined
        }
    }
    render() {
        return (
            <div className={styles.show_options} style={{ left: this.props.left, top: this.props.top }}>
                <div className={styles.show_options_content}>
                    <div key={0}>
                        <button onClick={() => {
                            this.props.change();
                        }} key={`button0`} className={styles.destinationItem}>
                            <span>{this.props.name}</span>
                        </button>
                    </div>
                    {this.props.items.map(c => {
                        return (
                            <div key={c.id}>
                                <button onClick={() => {
                                    this.props.change(c);
                                }} key={c.rawId} className={styles.destinationItem}>
                                    <span>{c.name}</span>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

Navigation.ScrollOptions = ScrollOptions

export default (injectIntl(Navigation))
