import React from "react";
import { injectIntl } from "react-intl";
import classnames from "classnames";

import styles from "./Resume.styles.css";
import Product from "./components/Product";
import moment from "moment"

const Resume = ({ intl, products, recommended, discount, finishDate }) => {
  const renderProduct = (item, index) => {
    console.log(item)
    let information = '';
    if(item.type === 'HOTEL'){
      let min= item.days.sort((a,b)=>a-b)[0], max = item.days.slice(-1)[0];
      information = `${min.format('DD/MM/YYYY')} - ${max.add(1, 'days').format('DD/MM/YYYY')}`;
    }else{
      information = item.days[0]._i;
    }
    return (
      <Product
        group={item.name}
        informations={information}
        amount={item.quantity}
        name={item.format}
        price={item.price}
        key={index}
      />
    );
  };

  let productTotal = products.reduce((acc, p) => (acc = acc + p.price), 0);
  let discountTotal = 0;
  if (discount != 0){
    discountTotal = productTotal * (discount / 100.0)
    productTotal = productTotal - discountTotal;
  }
  const groups = [];
  let count = 0;
  let daysLeft = false;
  let lastDay = undefined;

  console.log('products', products)

  products.forEach(p =>{
    const product = p.product;
    let formatDate = 'DD/MM/YYYY'
    if(p.information.trimEnd().length > 10){
      formatDate = 'DD/MM/YYYY HH:mm'
    }
    if(lastDay === undefined){
      lastDay = p.information;
    }else{
      const nextLastDay = moment(lastDay.trimEnd(), formatDate).add(1, 'days');
      if(nextLastDay.format(formatDate) !== p.information.trimEnd()){
        daysLeft = true;
      }
      lastDay = p.information;
    }
    if(daysLeft){
      count++;
      daysLeft = false;
    }
    const key = `${product.name}_${product.format}_${count}`;
    if(groups[key] == undefined){
      groups[key] = {
        name: product.name,
        format: product.format,
        type: product.type,
        quantity: p.quantity,
        price: p.price,
        days: [
          moment(p.information.trimEnd(), formatDate)
        ]
      }
    }else{
      const group = groups[key];
      group.price += p.price;
      group.days.push(moment(p.information.trimEnd(), formatDate));
    }
  });

  return (
    <>
      <div className={classnames(styles.row, styles.recommended)}>
        <div className={styles.contentName}>
          <span>{recommended.name}</span>
          {
            finishDate &&
            <span> {intl.formatMessage({ id: "checkout.resume.date.of.purchase" })}: <b className={styles.date}>{moment(finishDate).format('DD/MM/YYYY')}</b></span>
          }
        </div>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${recommended.images.main.normal})` }}
        />
      </div>
      <div className={styles.products}>
        {
          Object.keys(groups).map(key => {
            return (
              renderProduct(groups[key])
            )
          })
        }
      </div>
      {
        discount != 0 &&
        <div className={styles.discount}>
          <span className={styles.label}>
            {intl.formatMessage({ id: "checkout.resume.discount" })} ({discount}%)
          </span>
          <span className={styles.price}>
            - {intl.formatNumber(discountTotal, {
              style: "currency",
              currency: "EUR",
            })}
          </span>
        </div>
      }
      <div className={styles.row}>
        <span className={styles.label}>
          {intl.formatMessage({ id: "checkout.resume.total" })}
        </span>
        <span className={styles.price}>
          {intl.formatNumber(productTotal, {
            style: "currency",
            currency: "EUR",
          })}
        </span>
      </div>
    </>
  );
};

Resume.defaultProps = {
  products: [],
  recommended: {},
  discount: 0,
  finishDate: undefined
};

export default injectIntl(Resume);
