import React, { Component } from "react";
import { injectIntl } from "react-intl";
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { DESTINATION } = publicRuntimeConfig
import moment from "moment";
import { withAppState } from "../../contexts/app-state";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import styles from "./styles.css";
import Router from "next/router";
import Link from "next/link";
import GridContainer from "../GridContainer";
import classnames from "classnames";

class HomeNoAuth extends Component {
  constructor(props) {
    super(props);
    this.onDestinationChanged = this.onDestinationChanged.bind(this);
    this.onRelationshipsChanged = this.onRelationshipsChanged.bind(this);
    //this.onFirstTimeChanged = this.onFirstTimeChanged.bind(this);
    this.onPerfectForChanged = this.onPerfectForChanged.bind(this);
    this.onCategoryChanged = this.onCategoryChanged.bind(this);
    this.onSubcategoryChanged = this.onSubcategoryChanged.bind(this);
    this.goto = this.goto.bind(this);
    this.shareApp = this.shareApp.bind(this);
    this.isTuritop = this.isTuritop.bind(this);
    this.notTuritop = this.notTuritop.bind(this);
    moment.locale(this.props.locale);
    this.state = {
      show_options: false,
      show_options_relationships: false,
      show_options_firstTime: false,
      show_options_category: false,
      show_options_subcategory: false,
      show_options_perfert_for: false,
      destination: this.getDestination(this.props.destination),
      destinations: this.props.destinations,
      //firstTime: this.getFirstTime(),
      relationships: this.getSeletctedRelationships(),
      perfectFor: this.getSeletctedPerfectFor(),
      startDate: this.props.startDate
        ? moment(this.props.startDate)
        : undefined,
      endDate: this.props.endDate ? moment(this.props.endDate) : undefined,
      focusedInput: null,
      focusedStart: undefined,
      focusedEnd: undefined,
      orientation: "horizontal",
      numberOfMonths: 2,
      category: this.getCategory(),
      subcategory: this.getSubcategory(),
    };
  }
  componentDidMount() {
    moment.locale(this.props.locale);
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 640) {
      this.setState({ orientation: "horizontal", numberOfMonths: 1 });
    }
  }
  _getBackgroundStyle = () => {
    if (this.props.background) {
      return {
        backgroundImage: `url(${
          this.props.background.normal
            ? this.props.background.normal
            : this.props.background
        })`,
      };
    }
    return {};
  };
  getSeletctedDestination = () => {
    let destination = undefined;
    this.props.destinations.map((region) => {
      if (!destination) {
        const filtered = region.destinations.filter(
          (c) => c.rawId === this.props.destination
        );
        if (filtered.length > 0) {
          destination = filtered[0];
        }
      }
    });
    return destination;
  };
  getSeletctedRelationships = () => {
    if (!this.props.relationships) {
      return undefined;
    }
    let name = "";
    if (this.props.relationships == 1) {
      name = "home.filter.relationships.one";
    } else if (this.props.relationships == 2) {
      name = "home.filter.relationships.two";
    } else if (this.props.relationships == 3) {
      name = "home.filter.relationships.three";
    }
    return { id: this.props.relationships, name: name };
  };
  getSeletctedPerfectFor = () => {
    if (!this.props.perfectFor) {
      return undefined;
    }
    let name = "";
    if (this.props.perfectFor == 1) {
      name = "home.filter.perfert.for.one";
    } else if (this.props.perfectFor == 2) {
      name = "home.filter.perfert.for.two";
    } else if (this.props.perfectFor == 3) {
      name = "home.filter.perfert.for.three";
    } else if (this.props.perfectFor == 4) {
      name = "home.filter.perfert.for.four";
    } else if (this.props.perfectFor == 5) {
      name = "home.filter.perfert.for.five";
    }
    return { id: this.props.perfectFor, name: name };
  };
  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };
  getLink = (href) => {
    if (href != undefined) {
      if (href !== "" && href !== "#") {
        if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
          return "https://" + href;
        }
        return href;
      }
    }
    return "#!";
  };
  getDateInformations = () => {
    moment.locale(this.props.lang);
    let date = moment(this.props.date, "YYYYMMDD");
    return {
      week_day: date.format("dddd").toUpperCase(),
      day: date.format("DD"),
      month: date.format("MMMM YYYY"),
    };
  };
  onDestinationChanged = (value) => {
    const destination = this.getDestination(value);
    if (destination !== undefined) {
      this.setState({ show_options: false, destination: destination });
    }
  };
  getDestination = (destination) => {
    let rawId = 'ronda'
    if(destination !== undefined){
      rawId = destination.rawId;
    }
    var foundDestination = undefined;
    this.props.destinations.forEach((d) => {
      let destination = d.destinations.filter((c) => c.rawId === rawId)[0];
      if (destination !== undefined) {
        foundDestination = destination;
      }
    });
    return foundDestination;
  };
  getCategory = () => {
    const { destination, categoryRawId } = this.props;
    if(categoryRawId === undefined){
      return undefined;
    }
    let categories = destination.categories.filter(c => {
      return c.rawId === categoryRawId
    });
    if(categories !== undefined){
      return categories[0];
    }
    return undefined;
  }
  getSubcategory = () => {
    const { subcategoryRawId } = this.props;
    if(subcategoryRawId === undefined){
      return undefined;
    }
    let category = getCategory();
    let subcategories = category.subcategories.filter(c => {
      return c.rawId === subcategoryRawId
    });
    if(subcategories !== undefined){
      return subcategories[0];
    }
    return undefined;
  }
  onRelationshipsChanged = (value) => {
    this.setState({ show_options_relationships: false, relationships: value });
  };
  onPerfectForChanged = (value) => {
    this.setState({ show_options_perfert_for: false, perfectFor: value });
  };
  onCategoryChanged = (value) => {
    if(value === undefined){
      this.setState({ show_options_category: false, category: undefined, subcategory: undefined });
    }else{
      this.setState({ show_options_category: false, category: value, subcategory: value.subcategories[0] });
    }
  }
  onSubcategoryChanged = (value) => {
    this.setState({ show_options_subcategory: false, subcategory: value });
  }
  onFirstTimeChanged = (value) => {
    this.setState({ show_options_firstTime: false, firstTime: value });
  };
  onAccommodationChanged = (value) => {
    this.setState({ show_options_accommodation: false, accommodation: value });
  };
  onApartmentChanged = (value) => {
    this.setState({ show_options_apartment: false, apartment: value });
  };

  renderDestinations = () => {
    const { destination } = this.state;
    return (
      <div className={classnames(styles.search, styles.buttonToggle)}>

        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: !this.state.show_options,
                show_options_perfert_for: false,
                show_options_relationships: false,
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span className={styles.hint}>
              {destination
                ? destination.name
                : this.translate("home.home.select_the_city")}
            </span>
          </button>
          {DESTINATION === '' && this.state.show_options && (
            <div className={classnames(styles.show_options, styles.places)}>
              {this.props.destinations.map((c) => {
                return (
                  <div key={c.id}>
                    <span className={styles.destinationTitle}>{c.name}</span>
                    {c.destinations.map((d, index) => {
                      return (
                        <button
                          onClick={() => {
                            this.onDestinationChanged(d);
                          }}
                          key={d.rawId}
                          className={styles.destinationItem}
                        >
                          <span>{d.name}</span>
                        </button>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                <button
                  onClick={() => {
                    this.onDestinationChanged(undefined);
                  }}
                  key="destination_0"
                  className={classnames(
                    styles.destinationItem,
                    styles.separator
                  )}
                >
                  <span>
                    <b>{this.translate("search.clear_filter_xs")}</b>
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderFirstTime = () => {
    const { firstTime } = this.state;
    return (
      <div
        className={classnames(
          styles.search,
          styles.buttonToggle,
          styles.espacioEntreButton
        )}
      >
        <div className={styles.toggleContainer}>
          <button onClick={() => {}} className={styles.toggleButton}>
            <span className={styles.hint}>Es la primera vez que vengo</span>
          </button>
        </div>
      </div>
    );
  };

  renderRelationships = () => {
    const { relationships } = this.state;
    return (
      <div
        className={classnames(
          styles.search,
          styles.buttonToggle,
          styles.espacioEntreButton
        )}
      >
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_relationships: !this.state
                  .show_options_relationships,
                show_options_perfert_for: false,
                show_options_category: false,
                show_options_subcategory: false,
                focusedInput: null
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_relationships: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span>
              {relationships && this.translate(relationships.name)}
              {!relationships &&
                this.translate("home.filter.relationships.zero")}
            </span>
          </button>
          {this.state.show_options_relationships && (
            <div className={styles.show_options}>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 1,
                    name: "home.filter.relationships.one",
                  });
                }}
                key="relationships_1"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.one")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 2,
                    name: "home.filter.relationships.two",
                  });
                }}
                key="relationships_2"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.two")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 3,
                    name: "home.filter.relationships.three",
                  });
                }}
                key="relationships_3"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.three")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged(undefined);
                }}
                key="relationships_0"
                className={classnames(styles.destinationItem, styles.separator)}
              >
                <span>
                  <b>{this.translate("search.clear_filter_xs")}</b>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderDate = () => {
    const { orientation, numberOfMonths } = this.state;
    return (
      <div
        className={classnames(styles.rangeDates, {
          [styles.DateInputFocused]: this.state.focusedInput !== null,
        })}
      >
        <DateRangePicker
          readOnly={true}
          noBorder={true}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.setState({ startDate, endDate })
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => this.setState({
            focusedInput,
            show_options: false,
            show_options_relationships: false,
            show_options_perfert_for: false,
            show_options_category: false,
            show_options_subcategory: false
          })}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          showClearDates={true}
        />
      </div>
    );
  };

  renderDates = () => {
    const { orientation, numberOfMonths } = this.state;
    return (
      <div
        className={classnames(styles.rangeDates, {
          [styles.DateInputFocused]: this.state.focusedInput !== null,
        })}
      >
        <DateRangePicker
          readOnly={true}
          noBorder={true}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.setState({ startDate, endDate })
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => this.setState({
            focusedInput,
            show_options: false,
            show_options_relationships: false,
            show_options_perfert_for: false,
            show_options_category: false,
            show_options_subcategory: false
          })}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          showClearDates={true}
          startDatePlaceholderText={this.translate("home.filter.dates.start")}
          endDatePlaceholderText={this.translate("home.filter.dates.end")}
        />
      </div>
    );
  };
  renderPerfectFor = () => {
    const { perfectFor } = this.state;
    return (
      <div className={styles.perfectFor}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_relationships: false,
                show_options_perfert_for: !this.state.show_options_perfert_for,
                show_options_category: false,
                show_options_subcategory: false,
                focusedInput: null
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_perfert_for: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span>
              {perfectFor && this.translate(perfectFor.name)}
              {!perfectFor && this.translate("home.filter.perfert.for.zero")}
            </span>
          </button>
          {this.state.show_options_perfert_for && (
            <div className={styles.show_options}>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 1,
                    name: "home.filter.perfert.for.one",
                  });
                }}
                key="perfert_for_1"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.one")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 2,
                    name: "home.filter.perfert.for.two",
                  });
                }}
                key="perfert_for_2"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.two")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 3,
                    name: "home.filter.perfert.for.three",
                  });
                }}
                key="perfert_for_3"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.three")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 4,
                    name: "home.filter.perfert.for.four",
                  });
                }}
                key="perfert_for_4"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.four")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 5,
                    name: "home.filter.perfert.for.five",
                  });
                }}
                key="perfert_for_5"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.five")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged(undefined);
                }}
                key="perfert_for_1"
                className={classnames(styles.destinationItem, styles.separator)}
              >
                <span>
                  <b>{this.translate("search.clear_filter_xs")}</b>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderByType = () => {
    if (this.props.type === "calendar") {
      let date_informations = this.getDateInformations();
      return (
        <GridContainer>
          <div className={styles.calendar}>
            <div className={styles.container}>
              <div className={styles.dates}>
                <span>{date_informations.week_day}</span>
                <span className={styles.day}>{date_informations.day}</span>
                <span>{date_informations.month}</span>
              </div>
              <span>
                <span className={styles.badge}>
                  {this.props.results >= 100 ? "+99" : this.props.results}
                </span>{" "}
                {this.translate("calendarResult.calendar.description")}
              </span>
              {this.renderDestinations()}
              {this.renderPerfectFor()}
              {this.renderDate()}
              <a className={styles.goto} onClick={this.gotoCalendar}>
                <span>{this.translate("search.info.action_text")}</span>
              </a>
            </div>
          </div>
        </GridContainer>
      );
    }
    if (this.props.type === "details") {
      return (
        <GridContainer>
          <div className={styles.details}>
            <h1>{this.props.title}</h1>
            <h2>{this.props.description}</h2>
            {!!this.props.action && this.notTuritop() && (
              <a
                className={styles.button}
                href={this.getLink(this.props.action)}
                target="_blank"
              >
                {this.props.actionText}
              </a>
            )}
            {!!this.props.action && this.isTuritop() && (
              <div
                class="load-turitop"
                data-service={this.props.action}
                data-lang="es"
                data-embed="button"
                data-afftag="ttafid"
                data-cssclass={styles.button}
              >
                {this.props.actionText}
              </div>
            )}
          </div>
        </GridContainer>
      );
    }
    if (this.props.type === "category") {
      return (
        <GridContainer>
          <div className={styles.details}>
            <h1>{this.props.title}</h1>
            <h2>{this.props.description}</h2>
          </div>
        </GridContainer>
      );
    }
    return (
      <GridContainer className={styles.heroContainer}>
        <div className={styles.titles}>
          <h1>{this.props.title}</h1>
          <h2>{this.props.description}</h2>
        </div>
        {this.renderDestinations()}
        {this.renderDates()}
        {this.renderRelationships()}
        {this.renderPerfectFor()}
        {this.renderCategory()}
        {this.renderSubcategory()}
        <a className={styles.goto} onClick={this.goto}>
          <span>{this.translate("search.info.action_text")}</span>
        </a>
      </GridContainer>
    );
  };

  renderCategory = () => {
    const { category } = this.state;
    const categories = this.props.destination.categories;
    return (
      <div className={classnames(styles.search, styles.buttonToggle, styles.espacioEntreButton)}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_perfert_for: false,
                show_options_relationships: false,
                show_options_category: !this.state.show_options_category,
                show_options_subcategory: false,
                focusedInput: null
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_category: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span className={styles.hint}>
              {category
                ? category.name
                : this.translate("home.home.select_the_category")}
            </span>
          </button>
          {this.state.show_options_category && (
            <div className={classnames(styles.show_options, styles.places)}>
              {categories.map((c) => {
                return (
                  <div key={c.id}>
                    <button
                      onClick={() => {
                        this.onCategoryChanged(c);
                      }}
                      key={c.rawId}
                      className={styles.destinationItem}
                    >
                      <span>{c.name}</span>
                    </button>
                  </div>
                );
              })}
              <div>
                <button
                  onClick={() => {
                    this.onCategoryChanged(undefined);
                  }}
                  key="category_0"
                  className={classnames(
                    styles.destinationItem,
                    styles.separator
                  )}
                >
                  <span>
                    <b>{this.translate("search.clear_filter_xs")}</b>
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderSubcategory = () => {
    const { subcategory, category } = this.state;
    let subcategories = [];
    if(category !== undefined){
      subcategories = category.subcategories;
    }
    return (
      <div className={classnames(styles.search, styles.buttonToggle, styles.espacioEntreButton)}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_perfert_for: false,
                show_options_relationships: false,
                show_options_category: false,
                show_options_subcategory: !this.state.show_options_subcategory,
                focusedInput: null
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_subcategory: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span className={styles.hint}>
              {subcategory
                ? subcategory.name
                : this.translate("home.home.select_the_subcategory")}
            </span>
          </button>
          {this.state.show_options_subcategory && (
            <div className={classnames(styles.show_options, styles.places)}>
              {subcategories.map((c) => {
                return (
                  <div key={c.id}>
                    <button
                      onClick={() => {
                        this.onSubcategoryChanged(c);
                      }}
                      key={c.rawId}
                      className={styles.destinationItem}
                    >
                      <span>{c.name}</span>
                    </button>
                  </div>
                );
              })}
              <div>
                <button
                  onClick={() => {
                    this.onSubcategoryChanged(undefined);
                  }}
                  key="category_0"
                  className={classnames(
                    styles.destinationItem,
                    styles.separator
                  )}
                >
                  <span>
                    <b>{this.translate("search.clear_filter_xs")}</b>
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  isTuritop = () => {
    const { action } = this.props;
    const turitopRegex = /^P[\d]+/;
    return turitopRegex.exec(action) !== null;
  };
  notTuritop = () => {
    return !this.isTuritop();
  };

  gotoCalendar = () => {
    const { destination, relationships, perfectFor, startDate, endDate, category, subcategory } = this.state;
    let relationshipsId = "";
    if (relationships) {
      relationshipsId = relationships.id;
    }
    let perfectForId = "";
    if (perfectFor) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (startDate !== undefined) {
      start = startDate.format("YYYYMMDD");
    }
    let end = "";
    if (endDate !== undefined) {
      end = endDate.format("YYYYMMDD");
    }
    if(subcategory !== undefined){
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/${category.rawId}/${subcategory.rawId}?start=${start}&end=${end}&perfectFor=${perfectForId}&relationships=${relationshipsId}&`
      );
    }else if (category !== undefined){
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/${category.rawId}?start=${start}&end=${end}&perfectFor=${perfectForId}&relationships=${relationshipsId}&`
      );
    }else{
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/calendar?start=${start}&end=${end}&perfectFor=${perfectForId}&relationships=${relationshipsId}&`
      );
    }

  };
  goto() {
    const {
      destination,
      firstTime,
      relationships,
      perfectFor,
      startDate,
      endDate,
      category,
      subcategory,
    } = this.state;
    let relationshipsId = "";
    if (relationships) {
      relationshipsId = relationships.id;
    }
    let firstTimeId = "";
    if (firstTime) {
      firstTimeId = firstTime.id;
    }
    let perfectForId = "";
    if (perfectFor) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (startDate !== undefined) {
      start = startDate.format("YYYYMMDD");
    }
    let end = "";
    if (endDate !== undefined) {
      end = endDate.format("YYYYMMDD");
    }
    if(subcategory !== undefined){
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/${category.rawId}/${subcategory.rawId}?start=${start}&end=${end}perfectFor=${perfectForId}&`
      );
    }else if (category !== undefined){
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/${category.rawId}?start=${start}&end=${end}perfectFor=${perfectForId}&`
      );
    }else{
      Router.push(
        `/${this.props.intl.locale}/${destination.rawId}/for-you?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`
      );
    }
  }

  shareApp() {

  }

  render() {
    const { user } = this.props.appState;
    return (
      <div className={classnames(styles.ajustesCaja)}>
        {
          user.is_logged &&
          <div className={styles.contentUser}>
            <div
              style={{
                background: `url(${user.avatar ?? '/static/icons/profile.svg'})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className={styles.avatar}
            ></div>
            <div className={styles.header}>
              <b>@{user.login}</b>
              <label>{this.translate("your.current.balance")}: <span>0€</span></label>
              <Link
                href={`/profile?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile#my_favorites`}
              >
                <a className={classnames(styles.icons, styles.favorites)}>
                  <span>{this.translate("header.menu.profile.favorites")}</span>
                </a>
              </Link>
            </div>
          </div>
        }
        <span className={classnames(styles.searchTextNow)}>
          {this.translate("your.current.search")}
        </span>
        {this.renderDestinations()}
        {this.renderRelationships()}
        {this.renderDates()}
        {this.renderPerfectFor()}
        <hr className={styles.separacionLinea} />
        {this.renderCategory()}
        {this.renderSubcategory()}
        <button className={styles.botonSearchNoAuth} onClick={this.goto}>
          <span>{this.translate("search.info.action_text")}</span>
        </button>
        <div className={styles.contentFooterLink}>
          <Link
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/about`}
            href={`/about?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            >
            <a className={styles.item}>
              {this.translate("footer.link.about")}
            </a>
          </Link>

          <Link
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/our-tools`}
            href={`/our-tools?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            >
            <a className={styles.item}>
              {this.translate("footer.link.out-tools")}
            </a>
          </Link>

          <Link
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/how-to-join`}
            href={`/how-to-join?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            >
            <a className={styles.item}>
              {this.translate("footer.link.how-to-join")}
            </a>
          </Link>

          <Link
            as={`/${this.props.intl.locale}/${this.props.destination.rawId}/term`}
            href={`/term?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
            >
            <a className={styles.item}>
              {this.translate("footer.link.term")}
            </a>
          </Link>

          <a
            className={styles.item}
            href="https://blog.discoolver.com/"
          >
            {this.translate("footer.link.blog")}
          </a>

        </div>
        {
          /*
          <button className={styles.botonSharedApp} onClick={this.shareApp}>
            <span>{this.translate("share.app")}</span>
          </button>
          */
        }
      </div>
    );
  }
}
HomeNoAuth.defaultProps = {
  destinations: [],
};

export default injectIntl(withAppState(HomeNoAuth));
