import React, {
  Fragment,
  Component,
} from "react";
import { injectIntl } from "react-intl";
import { Sidebar, ShopifyProduct } from "..";
import Seo from "../Seo/index";
import moment from "moment";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { WEB_ROOT, APP, __IS_RONDA__, WETRAVELHUB } = publicRuntimeConfig;
import styles from "./styles.css";
import ReactGA from "react-ga";
import Link from "next/link";
import TicketsThirdParty from "../TicketsThirdParty";
import { getProducts } from "../../api/index"
import Contact from "./components/Contact";

class ExperienceProducts extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.isTuritop = this.isTuritop.bind(this);
    this.notTuritop = this.notTuritop.bind(this);
    this.isContact = this.isContact.bind(this);
    this.state = {
      description: "",
      isShopify: this.props.isShopify,
      products: this.props.experience.data.products,
      ticketStartDate: moment(new Date()),
      ticketEndDate: moment(new Date())
    };
  }

  getLink = (href) => {
    if (href != undefined) {
      if (href !== "" && href !== "#") {
        if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
          return "https://" + href;
        }
        return href;
      }
    }
    return "#!";
  };

  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ description: this.props.experience.data.description });
  };

  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`;
  };
  
  onChangeDateTicket = (date) => {
    const { intl, experience } = this.props;
    getProducts(intl.locale, experience.data.id, date.format('YYYYMMDD'), '').then(data => {
      console.log('products', data);
      this.setState({ products: data, ticketStartDate: date, ticketEndDate: date });
    });
  }
  onChangeRangeDateTicket = (start, end) => {
    const { intl, experience } = this.props;
    const { ticketStartDate, ticketEndDate } = this.state;

    getProducts(intl.locale, experience.data.id, (start || ticketStartDate).format('YYYYMMDD'), (end || ticketEndDate).format('YYYYMMDD'), null).then(data => {
      this.setState({ products: data, ticketStartDate: (start || ticketStartDate), ticketEndDate: (end || ticketEndDate) });
    });
  }
  
  renderContent = () => {
    const experienceData = this.props.experience.data;
    if (!experienceData.rawId) return null;

    const {
      experience,
      company,
    } = this.props;

    const {
      products,
      ticketStartDate,
      ticketEndDate
    } = this.state;

    const contentShopping = !!experienceData.urlMembership || this.props.isShopify || experienceData.containsProducts;
    let textShopping = '';
    if(contentShopping){
      if(experienceData.urlMembership){
        if(this.isTuritop()){
          textShopping = experienceData.periodicSchedule
          ? this.translate("details.reserve_now")
          : this.translate("details.buy_now");
        }
        if(this.notTuritop() && this.isContact()){
          textShopping = "Ver";
        }
        if(this.notTuritop() && !this.isContact()){
          textShopping = experienceData.periodicSchedule
            ? this.translate("details.reserve_now")
            : this.translate("details.buy_now")
        }
      }
      if(this.props.isShopify){
        textShopping = this.translate("details.shopping")
      }
      if(experienceData.containsProducts){
        textShopping = experienceData.productType === 'PRODUCT'
            ? this.translate("details.buy_now")
            : this.translate("details.reserve_now")
      }
    }
    console.log('experienceData', experienceData)
    return (
      <Fragment>
        <Sidebar>
          <Sidebar.Content className={!contentShopping ? styles.notContentShopping : undefined}>
            {!!experienceData.urlMembership && (
              <>
                {this.isTuritop() && (
                  <div
                    class="load-turitop"
                    data-service={experienceData.urlMembership}
                    data-lang="es"
                    data-embed="button"
                    data-afftag="ttafid"
                    data-cssclass={styles.button}
                  >
                    {experienceData.periodicSchedule
                      ? this.translate("details.reserve_now")
                      : this.translate("details.buy_now")}
                  </div>
                )}
                {this.notTuritop() && this.isContact() && (
                  <Contact
                    whatsapp={experienceData.urlMembership.replace(/ /g,'')}
                    business={experienceData}
                    destination={experienceData.destinationRawId}
                    intl={this.props.intl}
                  />
                )}
                {this.notTuritop() && !this.isContact() && (
                  <a
                    className={styles.button}
                    href={this.getLink(experienceData.urlMembership)}
                    target="_blank"
                  >
                    {experienceData.periodicSchedule
                      ? this.translate("details.reserve_now")
                      : this.translate("details.buy_now")}
                  </a>
                )}
              </>
            )}
            {this.props.isShopify && (
              <Link
                href={`/ecommerce?locale=${this.props.intl.locale}&destination=${experienceData.destinationRawId}&categoryRawId=${experienceData.categoryRawId}&subCategoryRawId=${experienceData.categoryRawId}/${experienceData.subcategoryRawId}&experience=${experienceData.rawId}`}
                as={`/${this.props.intl.locale}/${experienceData.destinationRawId}/${experienceData.categoryRawId}/${experienceData.subcategoryRawId}/${experienceData.rawId}/ecommerce`}
              >
                <a className={styles.button}>
                  {this.translate("details.shopping")}
                </a>
              </Link>
            )}
            {experienceData.containsProducts && (
              <div id="shopping">
                <TicketsThirdParty
                  key={`tickets_party_${experienceData.id}`}
                  schedules={experienceData.schedules}
                  groups={products}
                  defaultStartDate={ticketStartDate}
                  defaultEndDate={ticketEndDate}
                  company={company}
                  onChangeDate={this.onChangeDateTicket}
                  onChangeRangeDate={this.onChangeRangeDateTicket}
                  id={experience.data.id}
                  addClassContainer={styles.contentVerticalTickets}
                />
              </div>
            )}
          </Sidebar.Content>
        </Sidebar>
      </Fragment>
    );
  };
  isTuritop = () => {
    const turitopRegex = /^P[\d]+/;
    return turitopRegex.exec(this.props.experience.data.urlMembership) !== null;
  };
  notTuritop = () => {
    return !this.isTuritop();
  };
  isContact = () => {
    const experienceData = this.props.experience.data;
    return experienceData.typeOfMembership === 4;
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  render() {
    const experienceData = this.props.experience.data;
    let { ogTitle, ogDescription, images } = experienceData;
    const ogImage = images["tab"]["ogimage"];
    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: "website",
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: "discoolver",
              images: [
                {
                  url: ogImage,
                  width: 559,
                  height: 413,
                },
              ],
            },
            twitter: {
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            },
            facebook: {
              appId: 783375525490958,
            },
          }}
        />
        {this.renderContent()}
      </div>
    );
  }
}

export default injectIntl(ExperienceProducts);
