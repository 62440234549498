import React, {
  Fragment,
  Component,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import { injectIntl } from "react-intl";
import { HeaderBar, Sidebar, Footer, Hero, StoreBar, StoreProducts } from "..";
import Seo from "../Seo/index";
import classnames from "classnames";
import SectionTitle from "../SectionTitle";
import Experiences from "../Experiences";
import styles from "./styles.css";
import classNames from "classnames";
import querystring from "querystring";
import Router from "next/router";
import GridContainer from "../GridContainer";
import ReactGA from "react-ga";
import Link from "next/link";
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import { collections, product, search, createcheckout, addProduct } from "../../api/shopify";

class ShopifyDetailsId extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.selectedCategory = this.selectedCategory.bind(this);
    this.galeriaMiniNext = this.galeriaMiniNext.bind(this);
    this.galeriaMiniPrevious = this.galeriaMiniPrevious.bind(this);
    //this._onHeartClick = this._onHeartClick.bind(this);
    this.showResultCheveron = this.showResultCheveron.bind(this);
    this.addItemCartId = this.addItemCartId.bind(this);
    this.state = {
      categories: [],
      products: [],
      categoryId: "",
      textSearch: "",
      product: undefined,
      selectedVariant: undefined,
      selectedImage: undefined,
      index: 0,
      modal: false,
      checkout: undefined
    };
  }
  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let checkout = JSON.parse(localStorage.getItem('checkout'))
    if (checkout === null){
      checkout = createcheckout(this.props.client, (checkout) => {
          localStorage.setItem('checkout', JSON.stringify(checkout));
          this.setState({
            checkout: checkout
          });
        });
    }else{
      this.setState({
        checkout: checkout
      });
    }
    const categories = collections(this.props.client, (categories) => {
      this.setState({
        categories: categories
      });
      this.selectedCategory(0);
    });
    product(this.props.client, this.props.productId, (product) => {
      this.setState({
        product: product,
        selectedImage:
          product.images.length > 0 ? product.images[0] : undefined,
        selectedVariant:
          product.variants.length > 0 ? product.variants[0] : undefined,
      });
    });
  };
  componentWillReceiveProps = (nextProps) => {};
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  showProductLink = () => {
    const { intl, experience } = this.props;
    return {
      href: `?lang=${intl.locale}&destination=${experience.data.destinationRawId}&categoryRawId=${experience.data.categoryRawId}&subCategoryRawId=${experience.data.subcategoryRawId}&experience=${experience.data.rawId}`, //${props.intl.locale}
      as: `/${intl.locale}/${experience.data.destinationRawId}/${experience.data.categoryRawId}/${experience.data.subcategoryRawId}/${experience.data.rawId}/shopify`,
    };
  };
  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`;
  };
  selectedCategory = (index) => {
    const { categories } = this.state;
    const category = categories[index];
    if (category !== undefined && category.id !== this.state.categoryId) {
      this.setState({
        categoryId: category.id,
        products: category.products,
        textSearch: "",
      });
    }
  };
  _changeVariant = (e, _variant) => {
    this.setState({ selectedVariant: _variant });
  };
  _changeImage = (e, _image) => {
    this.setState({ selectedImage: _image });
  };

  selectedGuiaTallas = (e) => {
    e.stopPropagation();
  };
  openBox = (e) => {
    e.stopPropagation();
  };
  galeriaMiniNext = () => {
    const { product } = this.state;
    const index = this.state.index + 1;
    if (index === product.images.length) {
      this.setState({
        index: 0,
        selectedImage: product.images[0],
      });
    } else {
      this.setState({
        index,
        selectedImage: product.images[index],
      });
    }
  };

  galeriaMiniPrevious = () => {
    const { product } = this.state;
    const index = this.state.index - 1;
    if (index === -1) {
      this.setState({
        index: product.images.length - 1,
        selectedImage: product.images[product.images.length - 1],
      });
    } else {
      this.setState({
        selectedImage: product.images[index],
        index,
      });
    }
  };
  _onHeartClick = (e) => {
    const [liked, setLiked] = useState(!!props.liked);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!cancelAutomaticLiked) {
      setLiked(!liked);
    }
    props.onHeartClick();
  };
  showResultCheveron = () => {
    this.setState({ showResult: true });

  };
  addItemCartId = () => {
    const { selectedVariant, checkout } = this.state;
    const { client } = this.props;
    addProduct(client, checkout, selectedVariant, 1, (checkout) => {
      localStorage.setItem('checkout', JSON.stringify(checkout));
      this.setState({
        checkout: checkout
      });
    });
  }
  renderContent = () => {
    const { product, selectedImage, selectedVariant } = this.state;
    if (product === undefined) {
      return undefined;
    }

    const images = product.images.map((image) => {
      return (
        <img
          className={styles.galeriaMiniShopify}
          src={image.src}
          alt=""
          title=""
          onClick={(e) => {
            this._changeImage(e, image);
          }}
        />
      );
    });
    const colorsItems = [];
    const variantItems = product.variants.map((variant) => {
      return (
        <button
          className={classnames({
            [styles.arrayTallas]: true,
            [styles.selected]:
              selectedVariant !== undefined &&
              variant.id === selectedVariant.id,
          })}
          onClick={(e) => {
            this._changeVariant(e, variant);
          }}
        >
          {variant.title}
        </button>
      );
    });

    return (
      <>
        <div className={styles.shopifyPage}>
          <GridContainer className={styles.contentProductDetail}>
            <div className={styles.contenedoGaleria}>
              {/* //images demo */}
              {selectedImage && (
                <div>
                  <div className={styles.contenedoImagenPrincipal}>
                    <div>
                      <span className={styles.arrowLeft}>
                        <img
                          onClick={this.galeriaMiniPrevious}
                          src="/static/images/chevron-left.svg"
                        />
                      </span>
                    </div>
                    <div className={styles.imagePrincipalShopify}>
                      <img
                        src={selectedImage.src}
                        alt={product.title}
                        title={product.title}
                      />
                    </div>
                    <div >
                      <span className={styles.arrowRigth}>
                        <img
                        onClick={this.galeriaMiniNext}
                         src="/static/images/chevron-right.svg" />
                      </span>
                    </div>

                    <span className={styles.heartButtonGalery}></span>
                  </div>
                  {/* //añadir flechas por si hubiera mas miniaturas */}
                  <div className={styles.contenedoImagenes}>{images}</div>
                </div>
              )}
            </div>

            <div className={styles.contenerDatosProducto}>
              <h1 className={styles.nombreProductoShopify}>{product.title}</h1>
              {colorsItems.length > 0 && (
                <div>
                  <span className={styles.colors}>Color</span>
                  <div>
                    <button className={styles.coloresShopify}>Azul</button>
                  </div>
                </div>
              )}
              {variantItems.length > 0 && (
                <div>
                  <span className={styles.talla}>Tallas</span>
                  <div className={styles.tallasShopify}>{variantItems}</div>
                  <span className={styles.guiaDeTallas}>
                    <a onClick={this.showResultCheveron}>Guia de tallas</a>
                  </span>
                </div>
              )}

              <div>
                {/* Description */}
                <span className={styles.cheveronDownDescription}>
                  <img
                    onClick={() => {}}
                    src="/static/icons/chevron-down.svg"
                  />{" "}
                </span>
                <span className={styles.description}>Description</span>
                <span className={styles.descriptionShopify}>
                  {product.description}
                </span>
              </div>
            </div>

            {selectedVariant && (
              <div className={styles.contenedorCarrito}>
                <span className={styles.precioOriginalShopify}>
                  {selectedVariant.compareAtPrice}€
                </span>
                <span className={styles.precioRebajaShopify}>
                  {selectedVariant.price}€
                </span>

                <button onClick={this.addItemCartId} className={styles.buttonAddCarrito}>
                  añadir al carrito
                </button>
              </div>
            )}
          </GridContainer>
        </div>
      </>
    );
  };
  onSearchChanged = (query) => {
    search(this.props.client, query, (products) => {
      this.setState({ products: products, textSearch: query, categoryId: "" });
    });
  };
  render() {
    const { categories, categoryId } = this.state;
    let { ogTitle, ogDescription, images } = this.props.experience.data;
    const ogImage = images["tab"]["ogimage"];
    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: "website",
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: "discoolver",
              images: [
                {
                  url: ogImage,
                  width: 559,
                  height: 413,
                },
              ],
            },
            twitter: {
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            },
            facebook: {
              appId: 783375525490958,
            },
          }}
        />

        <HeaderBar
          onSearchChanged={this.onSearchChanged}
          destination={this.props.destination}
          locale={this.props.locale}
        />

        <StoreBar
          categories={categories}
          selected={categoryId}
          change={this.selectedCategory}
          onSearchChanged={this.onSearchChanged}
        />

        {this.renderContent()}

        <Footer destination={this.props.destination} />
      </div>
    );
  }
}

export default injectIntl(ShopifyDetailsId);
