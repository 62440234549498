import React, { forwardRef, useContext, useState, useImperativeHandle, useRef, useEffect } from "react"
import styles from './styles.css'
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { APP, WEB_ROOT } = publicRuntimeConfig
import { injectIntl } from "react-intl"
import classnames from "classnames";
import { registerContacts } from "../../../api"
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

const ContactModal = injectIntl(function (props) {
  const [form, setForm] = useState({
    email: '',
    userType: 'particular'
  })
  
  const [captchaValue, setCaptchaValue] = useState('');

  const _setForm = (name, value) => {
    setForm(f => ({ ...f, [name]: value }))
  }

  useEffect(() => {
    if (!props.visible) return
    const body = document.getElementsByTagName('body')[0]
    body.classList.add("modal-open");
    return () => body.classList.remove("modal-open")
  }, [props.visible])

  const _translate = (id) => props.intl.formatMessage({ id })

  const _register = () => {
    if( captchaValue !== '') {
      registerContacts(props.intl.locale, props.type, form.email, form.userType, () => {
        toast.success(_translate(`contact.${props.type.toLowerCase()}.toast`), {
          position: "top-center",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false
        });
      })
    }
  }

  const _onChangeCaptcha = (value) => {
    setCaptchaValue(value);
    console.log('captchaValue', value)
  }

  if (!props.visible) return null

  return (
    <div id={styles.modal_container}>
      <div className={styles.content}>
        <button onClick={props.onClose} className={styles.close}>close</button>
        <div className={classnames(styles.brand, styles[APP])}>
          <span></span>
        </div>
        <div className={styles.container}>
          <div>
            <input
              className={styles.input}
              onChange={e => {
                _setForm('email', e.target.value)
              }}
              type="text"
              value={form.email}
              placeholder={_translate("contact.mail")} />
          </div>
          <div>
            <select
              className={styles.input}
              onChange={e => {
                  _setForm('userType', e.target.value)
              }}
              value={form.userType}
              placeholder={_translate("contact.type")}
            >
              <option value="particular">{_translate("contact.particular")}</option>
              <option value="agency">{_translate("contact.agency")}</option>
            </select>
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6LfMAkUdAAAAAL0PfFUfMAjGPv2LDukGCoJQgPkL"
              onChange={_onChangeCaptcha}
            />
          </div>
          <button
            onClick={_register}
            className={styles.button}>{_translate("contact.row-title").toUpperCase()}</button>

          <div className={styles.separator}></div>
          <label>{_translate(`contact.${props.type.toLowerCase()}.description`)}</label>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
})


export default forwardRef(function (props, ref) {

  const [visible, setVisible] = useState(false)

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true)
    },
    hide: () => {
      setVisible(false)
    }
  }))

  if (!visible) return null

  return (
    <ContactModal
      onClose={() => {
        setVisible(false)
      }}
      {...props} visible={visible} />
  )
})
