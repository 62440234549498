import React, { Component } from 'react'
import styles from './styles.css'

class GridLayout extends Component {
    _getStyle = () => {
        const { columns, overflow } = this.props
        if (overflow) {
            return {
                display: 'grid',
                flex: 1,
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                overflow: overflow
            }
        }
        return {
            display: 'grid',
            flex: 1,
            gridTemplateColumns: `repeat(${columns}, 1fr)`
        }
    }
    render() {
        let custom_styles = this._getStyle()
        return (
            <div
                className={styles.grid}
                {...this.props}
                style={custom_styles}>
                {this.props.children}
            </div>
        )
    }
}

export default GridLayout