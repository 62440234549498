import React, {
  Component,
  useState,
} from "react";
import { injectIntl } from "react-intl";
import Router from 'next/router';
import { Footer, StoreBar } from "..";
import Seo from "../Seo/index";
import classnames from "classnames";
import styles from "./styles.css";
import GridContainer from "../GridContainer";
import HeaderStoreBar from '../HeaderStoreBar'
import ReactGA from "react-ga";
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
//import { addProduct } from "../../api/shopify";
import Order from "../../api/ecommerce/lib/order";

class EcommerceDetailsId extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.selectedCategory = this.selectedCategory.bind(this);
    this.galeriaMiniNext = this.galeriaMiniNext.bind(this);
    this.galeriaMiniPrevious = this.galeriaMiniPrevious.bind(this);
    //this._onHeartClick = this._onHeartClick.bind(this);
    this.showResultCheveron = this.showResultCheveron.bind(this);
    this.addItemCartId = this.addItemCartId.bind(this);
    this.state = {
      categories: [],
      products: [],
      categoryId: "",
      textSearch: "",
      product: undefined,
      selectedVariant: undefined,
      selectedImage: undefined,
      index: 0,
      modal: false,
      order: undefined
    };
  }
  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const client = this.props.client;

    let order = JSON.parse(localStorage.getItem('order'))
    console.log('order', order);
    if(order === null){
      client.createOrder().then((order) => {
        localStorage.setItem('order', JSON.stringify(order));
        this.setState({ order: order })
      });
    }else{
      client.order = Order.fromJson(order);
      this.setState({ order: client.order })
    }

    client.getCategories().then((categories) => {
      if(categories != undefined & categories.length > 0){
        var index = 0;
        if(this.props.categoryEcommerceId){
          index = categories.findIndex(category => {
            return category.id === this.props.categoryEcommerceId;
          })
        }
        var category = categories[index];
        this.setState({
          categories: categories,
          categoryId: category.id,
        })
      }
    });

    const product = client.product(this.props.productId).then((product) => {
      this.setState({
        product: product,
        selectedImage:
          product.images.length > 0 ? product.images[0] : undefined,
        selectedVariant:
          product.variants.length > 0 ? product.variants[0] : undefined,
      });
    });

  };
  componentWillReceiveProps = (nextProps) => {};
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`;
  };
  selectedCategory = (rawId) => {
    const { intl, experience } = this.props;
    if(rawId !== undefined && rawId !== this.state.categoryId){
        Router.push(`/${intl.locale}/${experience.data.destinationRawId}/${experience.data.categoryRawId}/${experience.data.subcategoryRawId}/${experience.data.rawId}/ecommerce/${rawId}`)
    }
  }
  _changeVariant = (e, _variant) => {
    this.setState({ selectedVariant: _variant });
  };
  _changeImage = (e, _image) => {
    this.setState({ selectedImage: _image });
  };

  selectedGuiaTallas = (e) => {
    e.stopPropagation();
  };
  openBox = (e) => {
    e.stopPropagation();
  };
  galeriaMiniNext = () => {
    const { product } = this.state;
    const index = this.state.index + 1;
    if (index === product.images.length) {
      this.setState({
        index: 0,
        selectedImage: product.images[0],
      });
    } else {
      this.setState({
        index,
        selectedImage: product.images[index],
      });
    }
  };

  galeriaMiniPrevious = () => {
    const { product } = this.state;
    const index = this.state.index - 1;
    if (index === -1) {
      this.setState({
        index: product.images.length - 1,
        selectedImage: product.images[product.images.length - 1],
      });
    } else {
      this.setState({
        selectedImage: product.images[index],
        index,
      });
    }
  };
  _onHeartClick = (e) => {
    const [liked, setLiked] = useState(!!props.liked);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!cancelAutomaticLiked) {
      setLiked(!liked);
    }
    props.onHeartClick();
  };
  showResultCheveron = () => {
    this.setState({ showResult: true });

  };
  addItemCartId = () => {
    const { selectedVariant } = this.state;
    const { client } = this.props;
    client.addLineOrder(undefined, selectedVariant, 1).then((order) => {
      localStorage.setItem('order', JSON.stringify(order));
      this.setState({ order })
    });;
  }
  renderContent = () => {
    const { product, selectedImage, selectedVariant } = this.state;
    if (product === undefined) {
      return undefined;
    }
    const images = product.images.map((image) => {
      return (
        <img
          className={styles.galeriaMiniShopify}
          src={image.src}
          alt=""
          title=""
          onClick={(e) => {
            this._changeImage(e, image);
          }}
        />
      );
    });
    const colorsItems = [];
    const variantItems = product.variants.map((variant) => {
      return (
        <button
          className={classnames({
            [styles.arrayTallas]: true,
            [styles.selected]:
              selectedVariant !== undefined &&
              variant.id === selectedVariant.id,
          })}
          onClick={(e) => {
            this._changeVariant(e, variant);
          }}
        >
          {variant.name}
        </button>
      );
    });

    return (
      <>
        <div className={styles.shopifyPage}>
          <GridContainer className={styles.contentProductDetail}>
            <div className={styles.contenedoGaleria}>
              {/* //images demo */}
              {selectedImage && (
                <div>
                  <div className={styles.contenedoImagenPrincipal}>
                    <div>
                      <span className={styles.arrowLeft}>
                        <img
                          onClick={this.galeriaMiniPrevious}
                          src="/static/images/chevron-left.svg"
                        />
                      </span>
                    </div>
                    <div className={styles.imagePrincipalShopify}>
                      <img
                        src={selectedImage}
                        alt={product.name}
                        name={product.name}
                      />
                    </div>
                    <div >
                      <span className={styles.arrowRigth}>
                        <img
                        onClick={this.galeriaMiniNext}
                         src="/static/images/chevron-right.svg" />
                      </span>
                    </div>

                    <span className={styles.heartButtonGalery}></span>
                  </div>
                  {/* //añadir flechas por si hubiera mas miniaturas */}
                  <div className={styles.contenedoImagenes}>{images}</div>
                </div>
              )}
            </div>

            <div className={styles.contenerDatosProducto}>
              <h1 className={styles.nombreProductoShopify}>{product.name}</h1>
              {colorsItems.length > 0 && (
                <div>
                  <span className={styles.colors}>Color</span>
                  <div>
                    <button className={styles.coloresShopify}>Azul</button>
                  </div>
                </div>
              )}
              {variantItems.length > 0 && (
                <div>
                  <span className={styles.talla}>Opciones</span>
                  <div className={styles.tallasShopify}>{variantItems}</div>
                  {/*
                  <span className={styles.guiaDeTallas}>
                    <a onClick={this.showResultCheveron}>Guia de tallas</a>
                  </span>
                  */}
                </div>
              )}

              <div>
                {/* Description
                <span className={styles.cheveronDownDescription}>
                  <img
                    onClick={() => {}}
                    src="/static/icons/chevron-down.svg"
                  />{" "}
                </span>
                */}
                <span className={styles.description}>Description</span>
                <span className={styles.descriptionShopify}>
                  {product.description}
                </span>
              </div>
            </div>

            {selectedVariant && (
              <div className={styles.contenedorCarrito}>
                <span className={styles.precioOriginalShopify}>
                  {selectedVariant.compareAtAmount}€
                </span>
                <span className={styles.precioRebajaShopify}>
                  {selectedVariant.amount}€
                </span>
                {
                  selectedVariant.percent > 0 &&
                  <span className={styles.discount}>
                    Ahoras {selectedVariant.saving}€ ({selectedVariant.percent}% de descuento)
                  </span>
                }

                <button onClick={this.addItemCartId} className={styles.buttonAddCarrito}>
                  añadir al carrito
                </button>
              </div>
            )}
          </GridContainer>
        </div>
      </>
    );
  };
  onSearchChanged = (query) => {
    if(query !== ''){
      this.props.client.search(query).then((products) => {
        this.setState({
          products: products,
          textSearch: query,
        });
      })
    }else{
      const { categories, categoryId } = this.state;
      var index = categories.findIndex(category => {
        return category.id === categoryId;
      })
      var category = categories[index];
      this.setState({
        products: category.products,
        textSearch: ''
      });
    }
  };
  render() {
    const { categories, categoryId } = this.state;
    let { ogTitle, ogDescription, images } = this.props.experience.data;
    const ogImage = images["tab"]["ogimage"];

    let background = this.props.experience.data.pictures.filter((p) => p.main)[0]

    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: "website",
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: "discoolver",
              images: [
                {
                  url: ogImage,
                  width: 559,
                  height: 413,
                },
              ],
            },
            twitter: {
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            },
            facebook: {
              appId: 783375525490958,
            },
          }}
        />

        <HeaderStoreBar
          ref={this.heroContent}
          locale={this.props.lang}
          destination={this.props.destination}
          destinations={this.props.destinations}
          background={background ? background.url : undefined}
          experience={this.props.experience.data}
          selected={categoryId}
          categories={categories}
          onSearchChanged={this.onSearchChanged.bind(this)}
          onCategoryChange={this.selectedCategory}
          user={this.props.user}
          showSearch={false}
          order={this.state.order}
          />

        {this.renderContent()}

        <Footer destination={this.props.destination} />
      </div>
    );
  }
}

export default injectIntl(EcommerceDetailsId);
