import React from "react";
import { injectIntl } from "react-intl";
import styles from "./styles.scss";

const Social = (props) => {
  return (
    <div className={styles.social}>
      {props.instagram && (
        <a
          href={props.instagram}
          rel="noreferrer"
          target="_blank"
          className={styles.instagram}
        />
      )}
      {props.facebook && (
        <a
          href={props.facebook}
          rel="noreferrer"
          target="_blank"
          className={styles.facebook}
        />
      )}
      {props.twitter && (
        <a
          href={props.twitter}
          rel="noreferrer"
          target="_blank"
          className={styles.twitter}
        />
      )}
      {props.linkedin && (
        <a
          href={props.linkedin}
          rel="noreferrer"
          target="_blank"
          className={styles.linkedin}
        />
      )}
      {props.youtube && (
        <a
          href={props.youtube}
          rel="noreferrer"
          target="_blank"
          className={styles.youtube}
        />
      )}
    </div>
  );
};

export default injectIntl(Social);
