import React from "react";
import { injectIntl } from "react-intl";
import Link from "next/link";
import styles from "./styles.scss";

const Item = (props) => {
  let { label, ...rest } = props;
  return (
    <div className={styles.column}>
      {props.href && (
        <Link {...rest}>
          <a className={styles.item} {...rest}>
            {label}
          </a>
        </Link>
      )}
      {!props.href && <span onClick={props.onClick} className={styles.item}>{label}</span>}
    </div>
  );
};

export default injectIntl(Item);
