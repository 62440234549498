import React, { Component } from 'react'
import styles from './styles.css'
import classnames from 'classnames'

class Filters extends Component {
    render() {
        let search_btn_class = [styles.search]
        let filter_btn_class = [styles.filtersAction]
        let map_btn_class = [styles.map]
        if (this.props.is_search_visible) {
            search_btn_class.push(styles.selected)
        }
        if (this.props.is_filter_visible) {
            filter_btn_class.push(styles.selected)
        }
        if (this.props.showMap) {
            map_btn_class.push(styles.selected)
        }
        return (
            <div className={styles.filtersCommands}>
                {this.props.commands && (
                    <React.Fragment>
                        {this.props.show_results && (
                            <div className={styles.results}>
                                <b>{this.props.results}</b> {this.props.resultsText}
                            </div>
                        )}
                        {this.props.show_filters && (
                            <div className={classnames(filter_btn_class)}>
                                <button
                                    onClick={this.props.onChangeFiltersVisibility}
                                    className={classnames(styles.action, this.props.is_filter_visible ? styles.selected : '')}>
                                    <span className={classnames([styles.icon, styles.filtersIcon])}></span>
                                </button>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {this.props.onChangeMap &&
                    <div className={classnames(map_btn_class)}>
                        <button
                            onClick={this.props.onChangeMap}
                            className={styles.action}>
                            <span className={classnames([styles.icon, styles.iconmap], {[styles.active]:this.props.showMap})}></span>
                        </button>
                    </div>
                }
                <div className={classnames(search_btn_class)}>
                    <button
                        onClick={this.props.onChangeSearchVisibility}
                        className={styles.action}>
                        <span className={classnames([styles.icon, styles.magnify])}></span>
                    </button>
                </div>
            </div>
        )
    }
}

Filters.defaultProps = {
    filterText: '',
    resultsText: '',
    results: 0,
    onChangeFiltersVisibility() { },
    onChangeSearchVisibility() { }
}

export default Filters
