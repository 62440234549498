import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import styles from './styles.css'

class Person extends Component {
    render() {
        return (
            <div className={styles.person}>
                <div className={styles.container}>
                    <div className={styles.image} style={{ backgroundImage: 'url(' + this.props.image + ')' }}>
                    </div>
                    <h3>{this.props.name}</h3>
                    <p>{this.props.description}</p>
                </div>
            </div>
        )
    }
}

export default injectIntl(Person)