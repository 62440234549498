import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { injectIntl } from "react-intl";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, DESTINATION } = publicRuntimeConfig;
import styles from "./HomeNavBar.styles.css";
import ModalWindow from "../ModalWindow/ModalWindow";
import moment from "moment";
import { logout } from '../../utils/auth'
import Categories from "../NavigationBar/Categories";
import Search from "../NavigationBar/Search";

const languageItems = [
  { id: 'es', name: 'ES' },
  { id: 'en', name: 'EN' },
  { id: 'fr', name: 'FR' },
  { id: 'de', name: 'DE' },
  { id: 'pt', name: 'PT' },
  { id: 'it', name: 'IT' }
]

const HomeNavBarExperience = (props) => {
  const [totem, setTotem] = useState('');
  const [isLogged, setIsLogged] = useState(props.user ? props.user.is_logged : false)
  const [isScrolling, setIsScrolling] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState( false );
  const [shopping, setShopping] = useState(props.shopping)
  const [textShopping, setTextShopping] = useState(props.textShopping)

  const [language, getLanguage] = useState({});
  const router = useRouter();

  const desktopCityRef = useRef();
  const desktopLanguageRef = useRef();
  const mobileLanguageRef = useRef();
  const desktopProfileRef = useRef();

  const cityModalRef = useRef();
  const languageModalRef = useRef();
  const profileModalRef = useRef();

  const modalsRefs = [
    cityModalRef,
    languageModalRef,
    profileModalRef
  ];

  const getElementBounds = (ref) => ref.getBoundingClientRect();

  const isScrollingEvent = (ev) => {
    setIsScrolling(
      document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0
    );
  };

  useEffect(() => {
    setTotem(localStorage.getItem('totem'));
  });

  useEffect(() => {
    document.addEventListener("scroll", isScrollingEvent);
    return () => {
      document.removeEventListener("scroll", isScrollingEvent);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("modal-open");
    return () => body.classList.remove("modal-open");
  }, [isVisible]);

  const getBackgroundStyle = () => {
    if (props.background) {
      return {
        backgroundImage: `url(${
          props.background.normal ? props.background.normal : props.background
        })`,
      };
    }
    return {};
  };
  const backgroundStyle = getBackgroundStyle();
  const mainStyles = isScrolling ? styles.isScrolling : undefined;
  const expandedStyle = isExpanded ? styles.isExpanded : undefined;

  const hideAll = () => {
    modalsRefs.forEach((d) => {
      if (d.current && d.current.hide) {
        d.current.hide();
      }
    });
  };

  const onChangeCity = (city) => {
    if(APP === 'discoolver' && city=== 'ronda'){
      router.push(`https://turismoderonda.es/${props.locale}/${city}`)
    }else{
      let url_path = router.asPath.split('/')
      if(url_path[1] === ''){
        url_path[1] = props.locale;
      }
      url_path[2] = city
      router.push(url_path.join('/'))
    }
  }

  const onChangeLang = (lang) => {
    let url_path = router.asPath.split('/')
    url_path[1] = lang
    router.push(url_path.join('/'))
  }

  const onSearchInputVisibilityChanged = () => {
    setShowSearchInput( !showSearchInput )
  }
  console.log('isLogged', 'isLogged:' + isLogged)
  var goHome = {
    'href': `/?locale=${props.intl.locale}&destination=${props.destination.rawId}`,
    'as': `/${props.intl.locale}/${props.destination.rawId}`
  }
  if(totem) {
    goHome = {
      'href': `/?locale=${props.intl.locale}&destination=${props.destination.rawId}&totem=${totem}`,
      'as': `/${props.intl.locale}/${props.destination.rawId}/totem/${totem}`
    }
  }
  return (
    <>
      <div
        id={styles.homeBar}
        className={classnames(mainStyles, expandedStyle)}
      >
        {props.background && (
          <div class={classnames(styles.image, styles.small)} style={backgroundStyle}></div>
        )}
        <div className={classnames(styles.infosContainer)}>
          <div className={classnames(styles.infos, styles.experienceDetail)}>
            <h2>{props.title}</h2>
            <span>{props.description}</span>
            {
              shopping &&
              <a className={styles.buybutton} href={'#shopping'}>{textShopping}</a>
            }
          </div>
        </div>
      </div>
      <div
        className={classnames(
          mainStyles,
          expandedStyle,
          styles.navbarContainer
        )}
        style={{padding: '8px 0 0'}}
      >
        <div className={classnames(styles.navbar, styles.gridContainer)}>
          <div className={styles.brandContainer}>
            <Link
              href={goHome.href}
              as={goHome.as}
            >
              <a className={classnames(styles.brand, styles[APP])}>
                <span>Discoolver.com</span>
              </a>
            </Link>
            {APP === 'discoolver' &&
              <a
                ref={desktopCityRef}
                onClick={() => {
                  hideAll();
                  if (!cityModalRef.current.isVisible()) {
                    cityModalRef.current.show({
                      position: getElementBounds(desktopCityRef.current),
                    });
                    return;
                  }
                  cityModalRef.current.hide();
                }}
                className={styles.citySelector}
              >
                <span>
                  {props.destination.name}
                </span>
              </a>
            }
          </div>
          {
            totem &&
            <div className={styles.menubar}>
              <Link
                href={`/totem?locale=${props.intl.locale}&destination=${props.destination.rawId}&totem=${totem}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/totem/${totem}`}
              >
                <a className={styles.buttonBackTotem}>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.back.totem" })}
                  </span>
                </a>
              </Link>
              <a
                ref={desktopLanguageRef}
                onClick={() => {
                  hideAll();
                  if (!languageModalRef.current.isVisible()) {
                    languageModalRef.current.show({
                      position: getElementBounds(desktopLanguageRef.current),
                    });
                    return;
                  }
                  languageModalRef.current.hide();
                }}
                className={styles.languageSelector}
              >
                <span>
                  {props.intl.locale}
                </span>
              </a>
            </div>
          }
          {
            !totem &&
            <div className={styles.menubar}>
              <Link
                href={`/plan-my-trip?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/plan-my-trip`}
              >
                <a>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.plan-my-trip" })}
                  </span>
                </a>
              </Link>
              <Link
                href={`/calendar?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/calendar`}
              >
                <a>
                  <span>
                    {props.intl.formatMessage({ id: "header.menu.calendar" })}
                  </span>
                </a>
              </Link>
              {!isLogged && (
                <Link
                  href={`/signin?locale=${props.intl.locale}&destination=${props.destination.rawId}`}
                  as={`/${props.intl.locale}/${props.destination.rawId}/signin`}>
                  <a>
                    <span>
                      {props.intl.formatMessage({ id: "header.menu.join" })}
                    </span>
                  </a>
                </Link>
              )}
              {isLogged && (
                <div
                  ref={desktopProfileRef}
                  onClick={() => {
                    hideAll();
                    if (!profileModalRef.current.isVisible()) {
                      profileModalRef.current.show({
                        position: getElementBounds(desktopProfileRef.current),
                      });
                      return;
                    }
                    profileModalRef.current.hide();
                  }}
                  style={
                    {
                      background: `url(${props.user.avatar ?? '/static/icons/profile.svg'})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }
                  }
                  className={styles.avatar}>
                </div>
              )}
              <a
                ref={desktopLanguageRef}
                onClick={() => {
                  hideAll();
                  if (!languageModalRef.current.isVisible()) {
                    languageModalRef.current.show({
                      position: getElementBounds(desktopLanguageRef.current),
                    });
                    return;
                  }
                  languageModalRef.current.hide();
                }}
                className={styles.languageSelector}
              >
                <span>
                  {props.intl.locale}
                </span>
              </a>
            </div>
          }
        </div>
        <div className={classnames(styles.mobileBar, styles.gridContainer)}>
          <Link
            href={goHome.href}
            as={goHome.as}
          >
            <a className={classnames(styles.brand, styles[APP])}>
              <span>Discoolver.com</span>
            </a>
          </Link>
          <div className={styles.contentButton}>
            <a
              ref={mobileLanguageRef}
              onClick={() => {
                hideAll();
                if (!languageModalRef.current.isVisible()) {
                  languageModalRef.current.show();
                  return;
                }
                languageModalRef.current.hide();
              }}
              className={classnames(styles.languageSelector, styles.mobileselector, styles.mobileLanguage)}
            >
              <span>
                {props.intl.locale}
              </span>
            </a>
            <a
              className={styles.buttonBack}
              onClick={() => {
                hideAll();
                history.back()
              }}
            >
              <span>&#10094;</span>
            </a>
          </div>
        </div>
      </div>

      {
        !totem && props.categories && props.categories.length > 0 &&
        <div className={classnames(styles.gridContainer, styles.categories)}>
          <Categories
            selected={props.selected}
            items={props.categories}
            onCategoryChange={props.onCategoryChange}
          />
          <div className={styles.navButtons}>
            <div className={classnames([styles.iconButton], {[styles.selected]: showSearchInput})}>
              <button
                onClick={onSearchInputVisibilityChanged}
                className={styles.action}>
                <span className={classnames([styles.icon, styles.iconsearch])}></span>
              </button>
            </div>
          </div>
        </div>
      }

      {showSearchInput &&
        <div className={classnames({[styles.gridContainer]: true, [styles.searchbar]:true, [styles.showKeyboard]: !!totem})}>
          <Search
            query={props.query}
            totem={totem}
            query={props.query}
            placeholder={props.intl.formatMessage({ id: "search.info.search" })}
            onSearchChanged={props.onSearchChanged}
            onChangeSearchVisibility={onSearchInputVisibilityChanged} />
        </div>
      }

      <ModalWindow
        title={''}
        ref={cityModalRef}
      >
        {props.destinations.map((c) => {
          return (
              c.destinations.map((d) => {
                return (
                  <button
                    onClick={() => {
                      onChangeCity(d.rawId)
                      cityModalRef.current.hide();
                    }}
                    key={d.rawId}
                    className={classnames([
                      styles.modalItem,
                      props.destination.rawId === d.rawId ? styles.selected : undefined,
                    ])}
                  >
                    <span>
                      {d.name}
                    </span>
                  </button>
                );
              })
          );
        })}
      </ModalWindow>
      <ModalWindow
        title={''}
        ref={languageModalRef}
      >
        {languageItems.map((d) => {
          return (
            <button
              onClick={() => {
                onChangeLang(d.id)
                languageModalRef.current.hide();
              }}
              key={d.id}
              className={classnames([
                styles.modalItem,
                props.intl.locale === d.id ? styles.selected : undefined,
              ])}
            >
              <span>
                {props.intl.formatMessage({
                  id: d.name,
                })}
              </span>
            </button>
          );
        })}
      </ModalWindow>
      <ModalWindow
        title={''}
        ref={profileModalRef}
        className={styles.modalProfile}
      >
        <button
          onClick={() => {
            onChangeProfile('')
            profileModalRef.current.hide();
          }}
          key={'profile_general'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.general',
            })}
          </span>
        </button>
        <button
          onClick={() => {
            onChangeProfile('#my_categories')
            profileModalRef.current.hide();
          }}
          key={'profile_categories'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.categories',
            })}
          </span>
        </button>
        <button
          onClick={() => {
            onChangeProfile('#my_favorites')
            profileModalRef.current.hide();
          }}
          key={'profile_favorites'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.favorites',
            })}
          </span>
        </button>
        <button
          onClick={(event) => {
            event.preventDefault()
            logout(props.intl.locale)
            profileModalRef.current.hide();
          }}
          key={'profile_logout'}
          className={styles.modalItem}
        >
          <span>
            {props.intl.formatMessage({
              id: 'header.menu.profile.logout',
            })}
          </span>
        </button>
      </ModalWindow>
    </>
  );
};

HomeNavBarExperience.defaultProps = {
  locale: "en",
  selected: "para_ti",
  background: undefined,
  destination: undefined,
  destinations: [],
};

export default injectIntl(HomeNavBarExperience);
