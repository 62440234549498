import React, { Component } from 'react'
import classnames from 'classnames'
import moment from 'moment'
import Link from "next/link";
import Router from 'next/router'
import styles from './styles.css'
import { getPlan, getPlanCallback, changePlan, deleteRecommendedPlan, finishPlan, finishUserPlan } from '../../api'
import { injectIntl } from 'react-intl'
import { GridContainer, ToggleSwitch, Map } from '../'
import stylesMap from '../Search/styles.css'

const DEFAULT_MAP_CENTER = {
  lat: 40.4165,
  lng: -3.70256
}

class PlanMyTripResults extends Component {
    constructor(props) {
        super(props)
        moment.locale(this.props.intl.locale)
        this.onChangeDay = this.onChangeDay.bind(this)
        this.onChangePlan = this.onChangePlan.bind(this)
        this.onDeleteRecommended = this.onDeleteRecommended.bind(this)
        this.onFinishPlan = this.onFinishPlan.bind(this)
        this.state = {
          showMap: false,
          defaultZoom: 11,
          selectedDay: moment(this.props.plan.days[0].day),
          plan: this.props.plan,
          selected: undefined,
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    onChangeVisibilityMap = () => {
      const { showMap } = this.state;
      this.setState({ showMap: !showMap });
    }
    onChangeDay = (day) => {
      this.setState({ selectedDay: day });
    }

    onChangePlan = (recommended) =>{
      changePlan(recommended.token, recommended.id, this.props.intl.locale, () => {
        getPlanCallback(recommended.token, this.props.intl.locale, this.onReloadPlan)
      });
    }

    onReloadPlan = (plan) => {
      this.setState({ plan })
    }

    onDeleteRecommended = (recommended) => {
      const { plan } = this.state;
      deleteRecommendedPlan(recommended.token, recommended.id, () => {
        plan.days.map(d => {
          let index = d.recommendeds.findIndex(r => {
            return r.id === recommended.id
          })
          d.recommendeds.splice(index, 1)
        })
        this.setState({ plan })
      });
    }

    onFinishPlan = () => {
      const { planToken, intl, user } = this.props;
      console.log(user)
      if(user.is_logged){
        finishUserPlan(planToken, user.token, () => {
          Router.push(`/${intl.locale}/${this.props.destination.rawId}/plan-my-trip/${planToken}/confirm`)
        })
      }else{
        finishPlan(planToken, () => {
          Router.push(`/${intl.locale}/${this.props.destination.rawId}/plan-my-trip/${planToken}/confirm`)
        })
      }
    }
    selectItem(item) {
      if (item.rawId == this.state.selected) {
          Router.push(`/${this.props.intl.locale}/${this.props.destination.rawId}/${item.categoryRawId}/${item.subcategoryRawId}/${item.rawId}`)
          return
      }
      this.scrollTo(item.rawId)
      this.setState({
          selected: item.rawId,
          center: {
              lat: item.latitude,
              lng: item.longitude
          },
          defaultZoom: 16
      })
    }
    scrollTo = (i) => {
        const index = `pin-${i}`
        if (this.refs[index]) {
            this.refs[index].scrollIntoView({ inline: 'center', block: 'start', behavior: 'auto' })
        }
    }
    onGoBack = () => {
      const { destination, intl } = this.props;
      Router.push(`/${intl.locale}/${destination.rawId}/plan-my-trip/`)
    }

    render() {
      const { plan, showMap, defaultZoom, selectedDay, selected } = this.state;
      const { intl } = this.props;
      const items = []
      const lang = intl.locale;
      const center = DEFAULT_MAP_CENTER;

      const days = plan.days.map(d => {
        const day = moment(d.day);
        let item_styles = [styles.day];
        if (day.format('YYYYMMDD') === selectedDay.format('YYYYMMDD')) {
          item_styles.push(styles.accent)
        }
        return (
          <a
            className={classnames(item_styles)}
            onClick={() => {
              this.onChangeDay(day)
            }}>
            <span>{day.format('ddd')}</span>
            <span>{day.format('D')}</span>
          </a>
        )
      });

      const day = plan.days.find(d => {
        const day = moment(d.day);
        return (day.format('YYYYMMDD') === selectedDay.format('YYYYMMDD'));
      });


      const recommendeds = day.recommendeds.map(r => {
        const day = moment(r.startDate);
        items.push(r)
        return (
          <div ref={`pin-${r.rawId}`} className={styles.recommended}>
            <div className={styles.image} style={{backgroundImage:`url(${r.image})`}} />
            <div className={styles.data}>
              <label className={styles.name}>
                {r.name}
                <button
                  onClick={() => {
                    this.onDeleteRecommended(r)
                  }}
                ></button>
              </label>
              <div>
                <label className={styles.date}>{day.format('LLLL')}</label>
                {
                  r.address &&
                  <label className={styles.address}>{r.address}</label>
                }
              </div>
              <div className={styles.contentAction}>
                <button
                  className={classnames([styles.button, styles.buttonWhite])}
                  onClick={() => {
                    this.onChangePlan(r)
                  }}
                  >{this.translate('plan.my.trip.suggest.another.plan')}
                </button>
                <Link
                  href={`/experience-details?lang=${intl.locale}&destination=${r.destinationRawId}&categoryRawId=${r.categoryRawId}&subCategoryRawId=${r.subcategoryRawId}&experience=${r.rawId}`}
                  as={`/${intl.locale}/${r.destinationRawId}/${r.categoryRawId}/${r.subcategoryRawId}/${r.rawId}`}
                >
                  <a>
                    {this.translate('plan.my.trip.more.information')}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        )
      });

      return (
        <div className={styles.contentPlan}>

          <GridContainer className={styles.contentDays}>
            <nav className={styles.days}>
              {days}
            </nav>
          </GridContainer>

          <GridContainer className={styles.contentDetail}>
            <label className={styles.crumbRoad}>Calendario / <span>{selectedDay.format('LL')}</span></label>
            <div className={styles.contentShowMap}>
              <ToggleSwitch text={'plan.my.trip.show.map'} onChange={this.onChangeVisibilityMap} />
            </div>

            <div className={styles.contentRecommendedInfo}>

              <div className={classnames({[styles.contentRecommended]: true, [styles.showMap]: showMap})}>

                <div className={styles.listRecommended}>
                  {recommendeds}
                </div>

              </div>

              {showMap &&
                <div className={styles.contentMap}>
                  <div id={stylesMap.mapViewer} className={classnames([stylesMap.fixedView, styles.mapViewer])}>
                    <div className={stylesMap.content}>
                      <div className={stylesMap.mapContainer}>
                        <Map
                          language={lang}
                          region={lang}
                          items={items}
                          zoom={defaultZoom}
                          renderItem={({ item, index }) => {
                            return (
                              <Map.Pin
                                onClick={() => {
                                    this.selectItem(item)
                                }}
                                selected={item.rawId == selected}
                                key={index}
                                pin={item}
                                lat={item.latitude}
                                lng={item.longitude} />
                            )
                        }}
                        center={center} />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className={styles.contentFooter}>
              <button
                onClick={this.onGoBack}>
                  {this.translate('plan.my.trip.back')}
              </button>
              <button
                onClick={this.onFinishPlan}
              >{this.translate('plan.my.trip.generate.plan')}</button>
            </div>
          </GridContainer>
        </div>
      )
    }
}

PlanMyTripResults.defaultProps = {

}

export default injectIntl(PlanMyTripResults)
