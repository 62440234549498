import React, { useState, useRef, useEffect } from "react";
import { withRouter } from "next/router";
import { injectIntl } from "react-intl";
import styles from "./styles.css";

function AdvertisingItem(props) {
  const [liked, setLiked] = useState(!!props.liked);
  const parentComponent = useRef(null);

  const _load = () => {
    if (!parentComponent.current) return;
    const dataSource = parentComponent.current.getAttribute("data-src");
    if (!dataSource) return;
    parentComponent.current.style.backgroundImage = `url(${dataSource})`;
    parentComponent.current.removeAttribute("data-src");
  };

  const _getStyle = () => {
    if (props.background) {
      //return { backgroundImage: `url(${props.background.miniature})` }
      return { backgroundImage: `url(${props.background.normal})`, width: '100%' };
    }
    return {};
  };

  const _onHeartClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setLiked(!liked);
    props.onHeartClick();
  };

  const _onDownloadICS = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    window.open(`https://api.discoolver.com/v1/business/${props.rawId}/ics/es`);
  };

  //useEffect(() => {
  //    window.addEventListener('load', _load, false);
  //    return () => window.removeEventListener('load', _load)
  //}, [])

  const _onClick = () =>
    props.router.push(props.href, props.as).then(() => window.scrollTo(0, 0));

  const style = _getStyle();

  const _translate = (id) => props.intl.formatMessage({ id });

  return (
    <>
      <div
        onClick={_onClick}
        ref={parentComponent}
        style={style}
        data-src={props.background.normal}
        className={styles.ads}
      >
        <div className={styles.mobileImage} style={style}></div>
        <div className={styles.infos}>
          <div>
            <span>{props.title}</span>
            <p className={styles.description}>{props.description}</p>
            {!!props.address && (
              <p className={styles.address}>{props.address}</p>
            )}
            {!!props.time && (
              <p className={styles.time}>{props.time.split(" ")[0]}</p>
            )}
          </div>
          <button onClick={_onHeartClick} className={styles.heart}></button>
          <div className={styles.commands}>
            {!!props.time && (
              <p className={styles.mobileTime}>{props.time.split(" ")[0]}</p>
            )}
            {props.showInCalendar && (
              <a
                href="#!download"
                onClick={_onDownloadICS}
                className={styles.goto}
              >
                {_translate("calendarResult.event.add_to_my_calendar")}
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

AdvertisingItem.defaultProps = {
  onHeartClick() {},
};

export default injectIntl(withRouter(AdvertisingItem));
