import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import NavBar from '../NavBar'
import Router from 'next/router'
import { logout } from '../../utils/auth'
import { withAppState } from '../../contexts/app-state'
import { PopoverMenu } from '..'
import styles from './styles.css'
import { url } from 'koa-router'

//import { HashLink as Link } from 'react-router-hash-link';

class HeaderBar extends Component {
    constructor(props){
        super(props);
    }
    state = {
        categoryId: '',
        subcategoryId: '',
        showPopoverMenu: false,
        totem: null
    }
    componentDidMount() {
        let url_path = Router.asPath.split('/')
        this.setState({
            categoryId: url_path[3],
            subcategoryId: url_path[4],
            totem: localStorage.getItem('totem')
        })
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    onChangeLang = (lang) => {
        let url_path = Router.asPath.split('/')
        url_path[1] = lang.value
        Router.push(url_path.join('/'))
    }
    render() {
        const { user: { is_logged } } = this.props.appState
        const { totem } = this.state;
        let search_query_href = `/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`
        let search_query = `/${this.props.intl.locale}/${this.props.destination.rawId}/`
        if(totem){
            return (
                <NavBar
                    filters={this.props.filters}
                    filtersActive={this.props.filtersActive}
                    onSearchChanged={this.props.onSearchChanged}
                    onCategoryChange={this.props.onCategoryChange}
                    onSubcategoryChange={this.props.onSubcategoryChange}
                    onChangeFilter={this.props.onChangeFilter}
                    query={this.props.query}
                    destinations={this.props.destinations}
                    destination={this.props.destination}
                    categoryRawId={this.props.categoryRawId}
                    subCategoryRawId={this.props.subCategoryRawId}
                    relationships={this.props.relationships}
                    perfectFor={this.props.perfectFor}
                    startDate={this.props.startDate}
                    endDate={this.props.endDate}
                    href={`/totem?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&totem=${totem}`}
                    as={`/${this.props.intl.locale}/${this.props.destination.rawId}/totem/${totem}`}>

                    <NavBar.Item
                        className={styles.buttonTotem}
                        label={this.translate("header.menu.back.totem")}
                        href={`/totem?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}&totem=${totem}`}
                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/totem/${totem}`}
                        />

                    <NavBar.Dropdown
                        onChange={this.onChangeLang}
                        values={[
                            { value: 'es', label: 'ES' },
                            { value: 'en', label: 'EN' },
                            { value: 'fr', label: 'FR' },
                            { value: 'de', label: 'DE' },
                            { value: 'pt', label: 'PT' },
                            { value: 'it', label: 'IT' }
                        ]}
                        value={this.props.intl.locale} />
                </NavBar>
            )
        }
        return (
            <NavBar
                filters={this.props.filters}
                filtersActive={this.props.filtersActive}
                onSearchChanged={this.props.onSearchChanged}
                onCategoryChange={this.props.onCategoryChange}
                onSubcategoryChange={this.props.onSubcategoryChange}
                onChangeFilter={this.props.onChangeFilter}
                query={this.props.query}
                destinations={this.props.destinations}
                destination={this.props.destination}
                categoryRawId={this.props.categoryRawId}
                subCategoryRawId={this.props.subCategoryRawId}
                relationships={this.props.relationships}
                perfectFor={this.props.perfectFor}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                href={`/?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}`}>
                <NavBar.Item
                    label={this.translate("header.menu.plan.search")}
                    href={search_query_href}
                    as={search_query}
                     />
                <NavBar.Item
                    label={this.translate("header.menu.calendar")}
                    href={`/calendar?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                    as={`/${this.props.intl.locale}/${this.props.destination.rawId}/calendar`}
                     />
                {!is_logged && (
                    <NavBar.Item
                        label={this.translate("header.menu.join")}
                        href={`/signin?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/signin`}
                        accent />
                )}
                {is_logged && (
                    <div
                        style={
                            {
                                background: `url(${this.props.avatar ?? '/static/icons/profile.svg'})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center'
                            }
                        }
                        className={styles.avatar}>

                    </div>
                )}
                {is_logged && (
                    <div className={styles.contentProfileMenu} style={{ position: 'relative' }}>

                        <NavBar.ItemClick
                            label={this.translate("header.menu.profile")}
                            onClick={() =>{
                                this.setState({ showPopoverMenu: !this.state.showPopoverMenu })
                            }}
                            accent />
                        { this.state.showPopoverMenu &&
                            <PopoverMenu destination={this.props.destination} intl={this.props.intl} />
                        }

                    </div>
                )}
                <NavBar.Dropdown
                    onChange={this.onChangeLang}
                    values={[
                        { value: 'es', label: 'ES' },
                        { value: 'en', label: 'EN' },
                        { value: 'fr', label: 'FR' },
                        { value: 'de', label: 'DE' },
                        { value: 'pt', label: 'PT' },
                        { value: 'it', label: 'IT' }
                    ]}
                    value={this.props.intl.locale} />
            </NavBar>
        )
    }
}

HeaderBar.defaultProps = {
    destination: {},
    city: {}
}

export default injectIntl(withAppState(HeaderBar))
