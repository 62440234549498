import React, { Component } from 'react'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { limit_string } from '../../utils'
import Link from 'next/link'

class EventItem extends Component {

    createMarkup = () => {
        return limit_string(this.props.description.replace(/<\/?[^>]+(>|$)/g, ""), 150)
    }

    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }

    getLink = () => {
        return `/${this.props.intl.locale}/${this.props.destinationRawId}/${this.props.categoryRawId}/${this.props.subcategoryRawId}/${this.props.rawId}`
    }
    getLinkRouter = () => {
        return `/${this.props.rawId}?locale=${this.props.intl.locale}&destination=${this.props.destinationRawId}&category=${this.props.categoryRawId}&subcategory=${this.props.subcategoryRawId}`
    }
    onDownloadICS = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if ( this.props.day === undefined ) {
            window.open(`https://api.discoolver.com/v2/ics/${this.props.rawId}/${this.props.intl.locale}`);
        } else {
            window.open(`https://api.discoolver.com/v2/ics/${this.props.rawId}/${this.props.day}/${this.props.intl.locale}`);
        }
    }
    onHeart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.props.heartAds()
    }
    render() {
        console.log('props', this.props)
        return (
            <Link
                href={this.getLinkRouter()}
                as={this.getLink()} >
                <a className={styles.link}>
                    <div className={styles.event}>
                        <div className={styles.container}>
                            <div className={styles.picture} style={{ backgroundImage: `url(${this.props.urlTabPicture})` }} />
                            <div className={styles.informations}>
                                <div className={styles.titleWithHeart}>
                                    <span className={styles.title}>{limit_string(this.props.name, 32)}</span>
                                    <button onClick={this.onHeart} className={styles.heart}></button>
                                </div>
                                <div className={styles.description}>
                                    <span>{this.createMarkup()}</span>
                                </div>
                                <div className={styles.commands}>
                                    <div className={styles.button_container}>
                                        <button 
                                            className={styles.goto}
                                            onClick={this.onDownloadICS}
                                        >{this.translate("calendarResult.event.add_to_my_calendar")}</button>
                                    </div>
                                    <a href={this.getLink()}>{this.translate("calendarResult.event.see_more")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </Link>
        )
    }
}

export default injectIntl(EventItem)
