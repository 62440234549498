import React, { Component } from 'react'
import styles from './styles.css'
import classNames from "classnames"
import Link from 'next/link'
import Router from 'next/router'

class CheckoutProductsForm extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  checkoutAddress = () => {
    const { categoryId, subcategoryId, lang, destination, experience} = this.props;
    Router.push(`/${lang}/${destination.rawId}/${categoryId}/${subcategoryId}/${experience.data.rawId}/ecommerce/checkout-address`)
  }
  delete = (id) =>  {
    this.props.deleteItem(id);
  }
  render() {
    const { order } = this.props;
    var products = undefined;
    console.log('order', order);
    if(order != undefined){
      products = order.lines.map(line => {
        return (
          <div className={styles.checkoutContentProductsDetail}>
            <div className={styles.checkoutContentProductsDetailImage} style={{
              backgroundImage: `url('${line.image}')`,
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
              minHeight: '175px'
            }}>
            </div>
            <div className={styles.checkoutContentProductsDetailData}>
              <label className={styles.checkoutContentProductsDetailDataTitle}>{line.name} ({line.variant.name})</label>
              <div>
                <div className={styles.checkoutContentProductsRow}>
                  <label className={styles.checkoutContentProductsDetailName}>Detalle</label>
                  <label className={styles.checkoutContentProductsDetailPrice}>{line.getAmount()} €</label>
                </div>
                <div className={styles.checkoutContentProductsRow}>
                  <label className={styles.checkoutContentProductsDetailKey}>Cantidad</label>
                  <label className={styles.checkoutContentProductsDetailValue}>
                    {line.quantity}
                  </label>
                </div>
                <div className={styles.contentDelete}>
                  <button onClick={this.delete.bind(this, `${line.id}`)}></button>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    return (
      <div className={styles.checkoutContent}>
        <div className={styles.checkoutContentProducts}>
          {products}
        </div>
        <div className={styles.checkoutContentProductsLine}></div>
        <div className={styles.checkoutContentButtons}>
          <button className={styles.button}
            onClick={this.checkoutAddress.bind(this)}>Proceder a la compra</button>
        </div>
      </div>
    )
  }
}

export default CheckoutProductsForm
