import React, { useRef, useEffect, useState } from "react";
import Link from "next/link";
import styles from "./styles.css";
import classnames from "classnames";
import { injectIntl } from "react-intl";
import { withRouter, useRouter, Router } from "next/router";



function StoreProductItem(props) {
  const parentComponent = useRef(null);
  const [liked, setLiked] = useState(!!props.liked);
  const [hovered, setHovered] = useState(!!props.hovered);
  const [variant, setVariant] = useState(!!props.variant);
  const router = useRouter()


  const _load = () => {
    if (!parentComponent.price) return;

    setPrice(props.item.variants[0].price);
    setCompareAtPrice(props.item.variants[0].compareAtPrice);
  };

  const _onMouseEnter = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setHovered(true);
  };

  const _onMouseOut = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setHovered(false);
  };

  const _changeVariant = (e, _variant) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setVariant(_variant);
  };

  const _onHeartClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!cancelAutomaticLiked) {
      setLiked(!liked);
    }
    props.onHeartClick();
  };

  const _getVariant = () => {
    if (variant) return variant;
    if (props.item.variants.length > 0) return props.item.variants[0];
    return undefined;
  };

  const selectedVariant = _getVariant();

  const translate = (id) => props.intl.formatMessage({ id });

  console.log(props.item);

  const image = props.item.images[0];
  let hover = image;
  if (props.item.images.length > 1) {
    hover = props.item.images[1];
  }

  const variantItems = props.item.variants.map((variant) => {
    return (
      <button
        className={classnames({
          [styles.variant]: true,
          [styles.selected]:
            selectedVariant !== undefined && variant.id === selectedVariant.id,
        })}
        onClick={(e) => {
          _changeVariant(e, variant);
        }}
      >
        {variant.name}
      </button>
    );
  });

  const link = {
    href: `/ecommerce/${props.item.id}?${props.defaultLink.href}`, //${props.intl.locale}
    as: `${props.defaultLink.as}/${props.item.id}`
  }

  console.log(props.item);

  return (
    <>
      <div className={styles.product}>
        <div
          className={styles.image}
          onMouseEnter={_onMouseEnter}
          onClick={() =>{
            router.push(link.as)
          }}
          style={{
            backgroundImage: `url(${image})`,
            display: hovered ? "none" : "block",
          }}
        >
          &nbsp;
        </div>
        <div
          className={styles.image}
          onMouseOut={_onMouseOut}
          onClick={() =>{
            router.push(link.as)
          }}
          style={{
            backgroundImage: `url(${hover})`,
            display: hovered ? "block" : "none",
          }}
        >
          &nbsp;
        </div>
        <label className={styles.title} title={props.item.name}>
          {props.item.name}
        </label>
        <label className={styles.description} title={props.item.description}>
          {props.item.description}
        </label>
        <div className={styles.contentVariant}>{variantItems}</div>
        {selectedVariant && (
          <div className={styles.contentPrice}>
            <label className={styles.compare}>
              {selectedVariant.compareAtAmount}€
            </label>
            <label className={styles.amount}>{selectedVariant.amount}€</label>
            <Link
              href={link.href}
              as={link.as}

            >
              <a className={styles.button}>
              Comprar
              </a>
            </Link>
          </div>
        )}
      </div>
    </>
  );
}


StoreProductItem.defaultProps = {
  onHeartClick() {},
  item: {},
};

export default injectIntl(withRouter(StoreProductItem));
