import React, { Component } from 'react'
import styles from './styles.css'
import classNames from "classnames"
import Link from 'next/link'
import { Check } from './../../components'
import { register } from './../../api/index';
import { ToastContainer, toast } from 'react-toastify';


class Input extends Component {
  render() {
      return (
        <input
          className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
          {...this.props} />
      )
  }
}

class CheckoutFinishForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      login: '',
      mail: '',
      password: '',
      typeRegister: 'USER',
      newsletter: true,
      errorLogin: '',
      errorMail: '',
      errorPassword: '',
      screenRegister :'user'
    }
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }

  _register = () => {

    if (this._isValidForm()) {

        const { login, mail, password, typeRegister, newsletter } = this.state

        const data = {
          login: login,
          mail: mail,
          password: password,
          typeRegister: typeRegister,
          newsletter: newsletter,
          locale: this.props.lang,
          city: this.props.destination.rawId
        };

        register(data, () => {
          toast.success(this.translate("register.register.success"), {
            position: "top-center",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false,
            onClick: this._gotoLogin
            });
          this.setState({
            login: '',
            mail: '',
            password: '',
            typeRegister: 'USER',
            recommended: 0,
            newsletter: true
          });

          var items = this.state.items;
          var auxItems = [];
          var max = items.length;
          for (var count = 0; count < max; count++) {
              var item = items[count];
              if (item.id != recommended) {
                auxItems[auxItems.length] = item;
              }
          }
        });
    }
  }
  _isValidForm = () => {
      this.setState({
        errorLogin: '',
        errorMail: '',
        errorPassword: ''
      });

      var isValid = true;

      const {login , mail, password} = this.state;

      if(login === ''){
          isValid = false;
          this.setState({errorLogin: 'Campo obligatorio'});
      }

      if(mail === ''){
          isValid = false;
          this.setState({errorMail: 'Campo obligatorio'});
      }else if(!(mail.indexOf('@') > 0 && mail.indexOf('@') < (mail.length - 1))){
          isValid = false;
          this.setState({errorMail: 'Formato mail incorrecto'});
      }

      if(password === ''){
          isValid = false;
          this.setState({errorPassword: 'Campo obligatorio'});
      }

      return isValid;
  }

  changeNewsletter = (value) => {
    this.setState({newsletter: value});
  }

  render() {
    const { login, mail, password, errorLogin, errorMail, errorPassword, } = this.state
    const { categoryId, subcategoryId, lang, destination, experience } = this.props;

    return (
      <div className={styles.checkoutContent}>
        <div className={styles.checkoutContentProducts}>

        </div>
        <div className={styles.checkoutContentForm}>
          <label className={styles.checkoutContentFormTitle}>Crea tu cuenta Discoolver</label>
          <label className={styles.checkoutContentFormSubtitle}>Si deseas reservar con mayor comodidad, crea una cuenta con nosotros</label>
           <Input
              value={login}
              onChange={(event) => {
                  this.setState({ login: event.target.value })
              }}
              error={errorLogin !== '' ? true : false}
              placeholder={this.translate("register.register.login")} />
            <Input
              value={mail}
              onChange={(event) => {
                  this.setState({ mail: event.target.value })
              }}
              error={errorMail !== '' ? true : false}
              placeholder={this.translate("register.register.mail")} />
            <Input
              value={password}
              onChange={(event) => {
                  this.setState({ password: event.target.value })
              }}
              error={errorPassword !== '' ? true : false}
              type="password"
              placeholder={this.translate("register.register.password")} />

          <div className={styles.register__checkNewsletter}>
            <Check
              checked={this.state.newsletter ? 'checked' : undefined}
              click={this.changeNewsletter.bind(this)}
            >
              {this.translate("register.register.newsletter")}
            </Check>
          </div>

          <div className={styles.checkoutContentFormRow}>
            <button
              onClick={this._register}
              className={styles.button}>{this.translate("register.register.row-title").toUpperCase()}</button>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
}

export default CheckoutFinishForm
