import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'
import ScrollMenu from 'react-horizontal-scrolling-menu'

const MenuItem = ({ rawId, text, selected }) => {
    let classname = [styles.item]
    if (selected) classname.push(styles.selected)
    return (
        <div className={classnames(classname)}>
            <span>{text}</span>
        </div>
    )
}

export const Menu = (list, selected) =>
    list.map(el => {
        const { rawId, name } = el
        return <MenuItem text={name} rawId={rawId} key={rawId} selected={selected} />
    })


const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    )
}


const ArrowLeft = Arrow({ text: '<', className: styles.arrowLeft })
const ArrowRight = Arrow({ text: '>', className: styles.arrowRight })


class Subcategories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.selected,
        }
        this.items = Menu(props.items, props.selected)
    }

    onSelect = key => {
        this.setState({ selected: key }, () => {
            this.props.onSubcategoryChange(key)
        })
    }
    render() {
        const { selected } = this.state
        let main_classnames = [styles.main, styles.subcategories]

        if (!this.props.opened) {
            main_classnames = [styles.main, styles.subcategoriesCollapsed]
        }

        return (
            <div className={classnames(main_classnames)}>
                <div className={styles.container}>
                    <div className={styles.subcategoriesContent}>


                        {this.props.opened && (
                            <ScrollMenu

                                alignCenter={false}
                                dragging={true}
                                clickWhenDrag={false}
                                scrollToSelected={true}
                                wheel={false}
                                inertiaScrolling={true}

                                itemClass={styles.menuItemWrapper}
                                arrowDisabledClass={styles.arrowDisabled}
                                innerWrapperClass={styles.innerWrapper}

                                data={this.items}
                                arrowLeft={ArrowLeft}
                                arrowRight={ArrowRight}
                                selected={selected}
                                onSelect={this.onSelect} />
                        )}

                    </div>
                </div>
            </div>
        )
    }
}

Subcategories.defaultProps = {
    opened: true,
    selected: '',
    items: []
}

export default Subcategories