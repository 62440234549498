import Variant from './variant';

class OrderLine {

  id: string;
  name: string;
  image: string;
  variant: Variant;
  quantity: number;

  static fromJson(json){
    return new this(
      {
        id: json.id,
        name: json.name,
        images: [json.image]
      },
      json.variant,
      json.quantity
    )
  }

  static fromShopify(line){
    return new this(
      {
        id: line.id,
        name: line.title,
        images: [line.variant.image.src]
      },
      Variant.fromShopify(line.variant),
      line.quantity
    );
  }

  constructor(product, variant, quantity){
    this.id = product.id;
    this.name = product.name;
    if(product.images.length > 0){
      this.image = product.images[0]
    }
    this.variant = variant;
    this.quantity = quantity;
  }

  getAmount() : number {
    return this.quantity * this.variant.amount;
  }
}

export default OrderLine;
