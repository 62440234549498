import Product from './product';

class Variant {
  id: string;
  name: string;
  amount: number;
  compareAtAmount: number;
  saving: number;
  percent: number;
  product: Product;
  static fromDouble(id, name, amount){
    return new this(id, name, amount, amount + (amount*0.1));
  }
  static fromShopify(variant){
    return new this(variant.id, variant.title, variant.price, variant.compareAtPrice);
  }
  constructor(id, name, amount, compareAtAmount){
    this.id = id;
    this.name = name;
    this.amount = amount;
    this.compareAtAmount = compareAtAmount;
    if(compareAtAmount !== null){
      this.saving = Math.round((compareAtAmount - amount) * 100) / 100;
      this.percent = Math.round((100.0 - (amount * 100.0 / compareAtAmount)) * 100) / 100;
    }else{
      this.saving = 0;
      this.percent = 0;
    }
  }
}

export default Variant;
