import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Link from "next/link";
import styles from "./styles.css";
import { ToastContainer, toast } from 'react-toastify';
import { saveContactRecommended } from "../../../../api/index";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP } = publicRuntimeConfig;

const Input = (props) => {
    return (
        <input
            className={classnames({[styles.input]: true, [styles.input_error]: props.error})}
            {...props} />
    )
}

const Contact = ({ whatsapp, business, destination, intl, totem }) => {

    const [name, setName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [numPerson, setNumPerson] = useState('1');
    const [phone, setPhone] = useState(undefined);

    const [errorName, setErrorName] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [errorPhone, setErrorPhone] = useState('');

    const [validForm, setValidForm] = useState(false);

    const [isTotem, setIsTotem] = useState(!totem);
    
    const saveContact = () => {
        if(validForm){
            saveContactRecommended(business.id, destination.rawId, name, email, phone, numPerson, intl.locale, () => {
                toast.success('Success', {
                    position: "top-center",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    closeButton: false
                });
            });
        }
    }

    useEffect(() => {
        if(name === ''){
            setErrorName('Campo obligatorio');
            setValidForm(false);
        }else{
            setErrorName('');
            setValidForm(errorEmail === '' && errorPhone === '');
        }
    }, [name]);

    useEffect(() => {
        if(email === ''){
            setErrorEmail('Campo obligatorio');
            setValidForm(false);
        }else{
            setErrorEmail('');
            setValidForm(errorName === '' && errorPhone === '');
        }
    }, [email]);

    useEffect(() => {
        if(phone === ''){
            setErrorPhone('Campo obligatorio');
            setValidForm(false);
        }else{
            setErrorPhone('');
            setValidForm(errorName === '' && errorEmail === '');
        }
    }, [phone]);

    const translate = (id) => {
        return intl.formatMessage({ id: `business.contact.${id}` });
    };

  return (
    <div>
        {
            !isTotem &&
            <div className={classnames(styles.contentForm, styles.header)}>
                <div className={styles.row}>
                    <span className={styles.whatsapp}><a target="_blank" href={`https://wa.me/${whatsapp}?text=`}>{translate('whatsapp')}</a></span>
                    <span className={styles.callphone}><a href={`tel:+${whatsapp}`}>{translate('call.us')}</a></span>
                </div>
            </div>
        }
        <div className={styles.contentForm}>
            <div className={styles.row}>
                <Input
                    value={name}
                    onChange={(event) => {
                        setName(event.target.value)
                    }}
                    error={errorName !== '' ? true : false}
                    placeholder={translate('name')} />
            </div>
            <div className={styles.row}>
                <Input
                    value={email}
                    onChange={(event) => {
                        setEmail(event.target.value)
                    }}
                    error={errorEmail !== '' ? true : false}
                    placeholder={translate('email')}  />
            </div>
            <div className={styles.row}>
                <div className={styles.num}>
                    <select className={styles.select} onChange={(event) => setNumPerson(event.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">+5</option>
                    </select>
                </div>
                <div className={styles.phone}>
                    <Input
                        value={phone}
                        onChange={(event) => {
                            setPhone(event.target.value)
                        }}
                        error={errorPhone !== '' ? true : false}
                        placeholder={translate('phone')} />
                </div>
            </div>
        </div>

        <div className={classnames(styles.contentForm, styles.fotter)}>
            <div className={styles.row}>
                <button className={styles.button} onClick={() => {
                    saveContact()
                }}>{translate('sign.me.up')}</button>
            </div>
        </div>

        <div className={styles.contentTerms}>
            <span>
                {translate('link.one')}&nbsp;
                <Link
                  href={`/term?locale=${intl.locale}&destination=${destination.rawId}`}
                  as={`/${intl.locale}/${destination.rawId}/term`}
                >
                    <a
                        href={`/term?locale=${intl.locale}&destination=${destination.rawId}`}
                        as={`/${intl.locale}/${destination.rawId}/term`}
                        target="_blank"
                    >
                        {translate('link.two')}
                    </a>
                </Link>
                &nbsp;{translate('link.three')}&nbsp;
                {APP === 'discoolver' &&
                    'Discoolver'
                }
                {APP === 'ronda' &&
                    'Ronda'
                }
                .
            </span>
        </div>
        <ToastContainer />
    </div>
  );
};

Contact.defaultProps = {
    whatsapp: "",
};

export default Contact;
