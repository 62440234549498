import React, { Component } from 'react'
import Router from 'next/router'
import Link from "next/link";
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import FacebookLogin from 'react-facebook-login'
import AppleLogin from 'react-apple-login';
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import { singin, registerFacebook } from '../../api'
import { Check } from '..';
import { GridContainer } from '../../components'
import styles from './styles.css'
import { register } from './../../api/index';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

const responseFacebook = (response) => {
  var userId = response.id;
  registerFacebook(
      userId,
      response.name,
      response.email,
      response.picture.data.url, () => {
          singin(userId, '').then(data => {
              const { token } = data
              login({
                  lang: 'es',
                  token,
                  type: 'facebook'
              })
          }).catch(err => {
          })
      });
}

class Input extends Component {
  render() {
      return (
          <div>
              <input
                className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
                {...this.props} />
          </div>
      )
  }
}

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasFetched: false,
            items: [],
            category: undefined,
            login: '',
            mail: '',
            password: '',
            typeRegister: 'USER',
            newsletter: true,
            errorLogin: '',
            errorMail: '',
            errorPassword: '',
            screenRegister :'user',
            esProfesional: this.props.esProfesional,
            documento: '',
            errorDocumento: '',
            name: '',
            company: '',
            address: '',
            errorName: '',
            errorCompany: '',
            errorAddress: '',
            captchaValue: ''
        };
    }
    translate = (id) => {
      return this.props.intl.formatMessage({ id })
    }
    componentDidMount(){

    }
    _register = () => {

      if (this._isValidForm()) {

          const { login, mail, password, typeRegister, newsletter, documento, name, company, address, esProfesional, captchaValue } = this.state

          const data = {
            login: login,
            mail: mail,
            password: password,
            typeRegister: typeRegister,
            newsletter: newsletter,
            locale: this.props.lang,
            city: this.props.destination.rawId, 
            documento: esProfesional ? documento : undefined,
            name: esProfesional ? name : undefined,
            company: esProfesional ? company : undefined,
            address: esProfesional ? address : undefined,
            domain: esProfesional ? window.location.host : undefined,
          };

          if( captchaValue === '' ){
            return
          }

          register(data, () => {
            toast.success(this.translate(`register.register.success.${esProfesional ? 'professional' : 'normal'}`), {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false,
              onClick: this._gotoLogin
              });
            this.setState({
              login: '',
              mail: '',
              password: '',
              typeRegister: 'USER',
              recommended: 0,
              newsletter: true,
              documento: '',
              name: '',
              company: '',
              address: ''
            });

            var items = this.state.items;
            var auxItems = [];
            var max = items.length;
            for (var count = 0; count < max; count++) {
                var item = items[count];
                if (item.id != recommended) {
                  auxItems[auxItems.length] = item;
                }
            }
          }).catch((error) => {
            console.log('error', error);
            toast.error(this.translate("register.register.error"), {
              position: "top-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              closeButton: false
              });
          });
      }
    }
    _isValidForm = () => {
        this.setState({
          errorLogin: '',
          errorMail: '',
          errorPassword: '',
          errorDocumento: '',
          errorName: '',
          errorCompany: '',
          errorAddress: ''
        });

        var isValid = true;

        const {login , mail, password, documento, name, company, address, esProfesional} = this.state;

        if(login === ''){
            isValid = false;
            this.setState({errorLogin: 'Campo obligatorio'});
        }

        if(mail === ''){
            isValid = false;
            this.setState({errorMail: 'Campo obligatorio'});
        }else if(!(mail.indexOf('@') > 0 && mail.indexOf('@') < (mail.length - 1))){
            isValid = false;
            this.setState({errorMail: 'Formato mail incorrecto'});
        }

        if(password === ''){
            isValid = false;
            this.setState({errorPassword: 'Campo obligatorio'});
        }

        if(esProfesional){
            if(documento === '') {
              isValid = false;
              this.setState({errorDocumento: 'Campo obligatorio'});
            }
            if(name === '') {
              isValid = false;
              this.setState({errorName: 'Campo obligatorio'});
            }
            if(company === '') {
              isValid = false;
              this.setState({errorCompany: 'Campo obligatorio'});
            }
            if(address === '') {
              isValid = false;
              this.setState({errorAddress: 'Campo obligatorio'});
            }
        }

        return isValid;
    }

    atras(){
      history.back();
    }
    goTerm(){
      return document.location.origin + '/' + getLowerNameCityActual() + '/term/' + this.props.locale;
    }
    changeNewsletter = (value) => {
      this.setState({newsletter: value});
    }
    _gotoLogin = () => {
      Router.push(`/${this.props.lang}/${this.props.destination.rawId}/signin`)
    }
    _terms = () => {
      Router.push(`/${this.props.lang}/${this.props.destination.rawId}/term`)
    }
    _policy = () => {
      Router.push(`/${this.props.lang}/${this.props.destination.rawId}/policy`)
    }
    onChangeCaptcha = (value) => {
      this.setState({ captchaValue: value });
    }
    render() {
      const { 
        login, 
        mail, 
        password, 
        errorLogin, 
        errorMail, 
        errorPassword, 
        esProfesional,
        documento, 
        errorDocumento,
        name,
        errorName,
        company,
        errorCompany,
        address,
        errorAddress
      } = this.state
	    return (
        <div>
          <GridContainer>
            <div className={styles.container}>
              <h1 className={styles.title}>{this.translate(`register.register.row-title.${esProfesional ? 'professional' : 'normal'}`)}</h1>
              {
                !esProfesional &&
                <div>
                  <div className={styles.rrss}>
                    <FacebookLogin
                      appId="173530683203278"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile,user_birthday,email"
                      callback={responseFacebook}
                      cssClass={styles.facebook}
                      textButton={this.translate("login.login.login.with.facebook")}
                      />

                    <div className={styles.contentAppleLogin}>
                      <AppleLogin
                        clientId={"com.discoolver"}
                        redirectURI={`${WEB_ROOT}/apple/login`}
                        responseType={"code id_token"}
                        responseMode={"form_post"}
                        scope={"name email"}
                        designProp={
                          {
                            height: 30,
                            width: 140,
                            color: "black",
                            border: false,
                            type: "sign-in",
                            border_radius: 0,
                            scale: 1,
                            locale: "en_US",
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.separator}></div>
                </div>
              }
              

              <div className={styles.contentType}>
                <button 
                  className={esProfesional ? undefined : styles.activeType} 
                  onClick={() => {
                    this.setState({esProfesional: false, typeRegister: "USER"})
                  }}>
                  {this.translate("register.register.user")}
                </button>
                <button 
                  className={esProfesional ? styles.activeType : undefined} 
                  onClick={() => {
                    this.setState({esProfesional: true, typeRegister: "PROFESSIONAL"})
                  }}>
                  {this.translate("register.register.professional")}
                </button>
              </div>

              <Input
                value={mail}
                onChange={(event) => {
                    this.setState({ mail: event.target.value })
                }}
                error={errorMail !== '' ? true : false}
                placeholder={this.translate("register.register.mail")} />
              <Input
                value={login}
                onChange={(event) => {
                    this.setState({ login: event.target.value })
                }}
                error={errorLogin !== '' ? true : false}
                placeholder={this.translate("register.register.login")} />
              <Input
                value={password}
                onChange={(event) => {
                    this.setState({ password: event.target.value })
                }}
                error={errorPassword !== '' ? true : false}
                type="password"
                placeholder={this.translate("register.register.password")} />

              {
                esProfesional &&
                <>
                  <Input
                    value={name}
                    onChange={(event) => {
                        this.setState({ name: event.target.value })
                    }}
                    error={errorName !== '' ? true : false}
                    placeholder={this.translate("register.register.name")} />
                  <Input
                    value={company}
                    onChange={(event) => {
                        this.setState({ company: event.target.value })
                    }}
                    error={errorCompany !== '' ? true : false}
                    placeholder={this.translate("register.register.company")} />
                  <Input
                    value={documento}
                    onChange={(event) => {
                        this.setState({ documento: event.target.value })
                    }}
                    error={errorDocumento !== '' ? true : false}
                    placeholder={this.translate("register.register.documento")} />
                  <Input
                    value={address}
                    onChange={(event) => {
                        this.setState({ address: event.target.value })
                    }}
                    error={errorAddress !== '' ? true : false}
                    placeholder={this.translate("register.register.address")} />
                </>
              }
              
              <div>
                <ReCAPTCHA
                  sitekey="6LfMAkUdAAAAAL0PfFUfMAjGPv2LDukGCoJQgPkL"
                  onChange={this.onChangeCaptcha}
                />
              </div>

              <button
                onClick={this._register}
                className={styles.button}>{this.translate("register.register.button").toUpperCase()}</button>

              <div className={styles.contentMessage}>
                <p>
                  {this.translate("register.register.terms.one")}
                  <Link
                    href={`term?locale=${this.props.lang}&destination=${this.props.destination.rawId}`}
                    as={`/${this.props.lang}/${this.props.destination.rawId}/term`}
                    passHref>
                    <a target="_blank" className={styles.link}>
                      {this.translate("register.register.terms.link")}
                    </a>
                  </Link>
                  {this.translate("register.register.terms.two")}
                  <Link
                    href={`policy?locale=${this.props.lang}&destination=${this.props.destination.rawId}`}
                    as={`/${this.props.lang}/${this.props.destination.rawId}/policy`}
                    passHref>
                    <a target="_blank" className={styles.link}>
                      {this.translate("register.register.terms.policy")}
                    </a>
                  </Link>
                </p>
              </div>

              <div className={styles.register__checkNewsletter}>
                <Check
                  checked={this.state.newsletter ? 'checked' : undefined}
                  click={this.changeNewsletter.bind(this)}
                >
                  {this.translate("register.register.newsletter")}
                </Check>
              </div>
            </div>

          </GridContainer>
          <ToastContainer />
        </div>
	    );
    }
}

export default injectIntl(RegisterForm)
