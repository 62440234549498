import React from "react";
import { injectIntl } from "react-intl";
import Link from "next/link";
import styles from "./Job.styles.css";

const Job = (props) => {
  let { label, ...rest } = props;
  return (
    <div className={styles.column}>
        <Link {...rest}>
            <a className={styles.item} {...rest}>
                {label}
            </a>
        </Link>
    </div>
  );
};

export default injectIntl(Job);
