import React, { useRef, useEffect, useState } from 'react'
import styles from './styles.css'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import { withRouter } from 'next/router'
import { skipKeywords } from 'json-schema-traverse'
import Router from 'next/router'

function TitlePlan(props) {

    const parentComponent = useRef(null)
    const translate = (id) => props.intl.formatMessage({ id })

    return (
        <>
          <div className={styles.title}>
            <h1 className={styles.h1}>{translate(props.text)}</h1>
          </div>
        </>
    )
}

TitlePlan.defaultProps = {
    text: ''
}

export default injectIntl(withRouter(TitlePlan))
