import React, { Fragment, Component } from 'react'
import styles from './styles.css'
import classNames from 'classnames'
import { Container, Arrow, CustomSlider  } from '..'
import { injectIntl } from 'react-intl'
import Link from 'next/link'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import Slider from "react-slick"
import Select from 'react-select'

class MapNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: props.query || '',
            show_subcategories: false,
            show_filters: false,
            selected: props.selected,
            subCategorySelected: props.subCategorySelected,
            filters: []
        }
    }
    getOptions = () => {
        return [
            { label: this.translate('sidebar.menu.for_you'), icon: styles.forYou, value: '' },
            { label: this.translate('categories.accommodations'), icon: styles.accommodations, value: 'alojamiento' },
            { label: this.translate('categories.restaurants'), icon: styles.restaurants, value: 'restaurantes' },
            { label: this.translate('categories.to_see'), icon: styles.to_see, value: 'que_ver' },
            { label: this.translate('categories.party'), icon: styles.party, value: 'fiesta' },
            { label: this.translate('categories.leisure'), icon: styles.leisure, value: 'ocio' },
            { label: this.translate('categories.shop'), icon: styles.shop, value: 'compras' }
        ]
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    renderFilters = (filters = []) => {
        return (
            <React.Fragment>
                {filters.map((f) => {
                    return (
                        <MapNavigation.Filter
                            name={f.name}
                            key={f.idFilter}>
                            <Select
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: 'var(--primary-color)'
                                    }
                                })}
                                styles={{
                                    menu: (provided, state) => ({
                                        ...provided,
                                        zIndex: 100
                                    }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        borderColor: (state.isSelected || state.isFocused) ? 'var(--primary-color)' : provided.borderColor
                                    }),
                                    option: (provided, state) => {
                                        let backgroundColor = provided.backgroundColor
                                        if (state.isFocused) backgroundColor = 'rgba(196, 50, 190, .20)'
                                        if (state.isSelected) backgroundColor = 'var(--primary-color)'
                                        return {
                                            ...provided,
                                            backgroundColor,
                                            color: state.isSelected ? '#FFF' : provided.color,
                                        }
                                    }
                                }}
                                value={this.state.filters[f.idFilter]}
                                onChange={(e) => {
                                    let { filters } = this.state
                                    filters[f.idFilter] = e
                                    this.setState({ filters })
                                }}
                                options={f.items.map(i => ({ value: i.idCategory, label: i.name }))} />
                        </MapNavigation.Filter>
                    )
                })}
            </React.Fragment>
        )
    }
    render() {
        let subcategory = {}
        let category = {}
        let subcategories = []
        if (this.state.selected) {
            category = this.props.categories.filter(c => c.idCategory == this.state.selected)[0]
            if (category && category.subcategories)
                subcategories = category.subcategories
        }
        if (this.state.subCategorySelected) {
            let canditate_subcategory = subcategories.filter(s => s.idCategory == this.state.subCategorySelected)[0]
            if (canditate_subcategory) {
                subcategory = canditate_subcategory
            }
        }
        return (
            <div className={styles.navigation}>
                {this.props.show_filters_button && (
                    <div className={styles.filters}>
                        <div className={styles.formInput}>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                this.props.onFiltersChanged({
                                    query: this.state.query,
                                    filters: this.state.filters,
                                    subcategory: this.state.subCategorySelected,
                                    category: this.state.selected
                                })
                            }}>
                                <div>
                                    <input
                                        type="text" onChange={(e) => {
                                            this.setState({ query: e.target.value })
                                        }}
                                        value={this.state.query}
                                        placeholder={this.translate("calendarResult.calendar.new-search")} />
                                    <button type="submit">Search</button>
                                </div>

                            </form>
                        </div>


                        <div className={classNames(styles.categories, styles.main)}>
                            <CustomSlider >
                                {this.props.categories.map((o, index) => {
                                    let item_styles = [styles.item];
                                    if (o.idCategory == this.state.selected) {
                                        item_styles.push(styles.accent)
                                    }
                                    return (
                                        <div key={o.idCategory} className={classNames(item_styles)}>
                                            <span className={classNames(styles.icon, styles[o.rawId])}></span>
                                            <span>{o.name}</span>
                                        </div>
                                    )
                                })}
                            </CustomSlider>
                            {/*
                                <ScrollMenu
                                    scrollBy={0}
                                    alignCenter={true}
                                    dragging={true}
                                    scrollToSelected={true}
                                    wheel={true}
                                    hideArrows={true}
                                    hideSingleArrow={true}
                                    arrowLeft={(<Arrow type="left" />)}
                                    arrowRight={(<Arrow type="right" />)}
                                    data={this.props.categories.map((o, index) => {
                                        let item_styles = [styles.item];
                                        if (o.idCategory == this.state.selected) {
                                            item_styles.push(styles.accent)
                                        }
                                        return (
                                            <div key={o.idCategory} className={classNames(item_styles)}>
                                                <span className={classNames(styles.icon, styles[o.rawId])}></span>
                                                <span>{o.name}</span>
                                            </div>
                                        )
                                    })}
                                    selected={this.state.selected}
                                    onSelect={(e) => {
                                        this.setState({ selected: e }, () => this.props.onUpdate({ category: e }))
                                    }} />
                            */}

                        </div>

                        {(subcategories.length > 0) && (
                            <div className={classNames(styles.categories, styles.subcategories)}>
                                <CustomSlider>
                                    {subcategories.map((o, index) => {
                                        let item_styles = [styles.item];
                                        if (o.idCategory == this.state.subCategorySelected) {
                                            item_styles.push(styles.accent)
                                        }
                                        return (
                                            <div key={o.idCategory} className={classNames(item_styles)}>
                                                <span>{o.name}</span>
                                            </div>
                                        )
                                    })}
                                </CustomSlider>
                                {/*
                                    <ScrollMenu
                                        scrollBy={0}
                                        alignCenter={true}
                                        dragging={true}
                                        scrollToSelected={true}
                                        wheel={true}
                                        hideArrows={true}
                                        hideSingleArrow={true}
                                        arrowLeft={(<Arrow type="left" />)}
                                        arrowRight={(<Arrow type="right" />)}
                                        data={subcategories.map((o, index) => {
                                            let item_styles = [styles.item];
                                            if (o.idCategory == this.state.subCategorySelected) {
                                                item_styles.push(styles.accent)
                                            }
                                            return (
                                                <div key={o.idCategory} className={classNames(item_styles)}>
                                                    <span>{o.name}</span>
                                                </div>
                                            )
                                        })}
                                        selected={this.state.subCategorySelected}
                                        onSelect={(e) => {
                                            this.setState({ subCategorySelected: e }, () => this.props.onUpdate({ category: this.state.selected, subcategory: e }))
                                        }} />
                                */}

                            </div>
                        )}


                        {subcategory.rawId && subcategory.filters.length > 0 && (
                            <Container>
                                <div style={{ flex: 1 }}>
                                    {this.state.show_filters && (
                                        <div className={styles.content}>
                                            <div className={styles.left}>
                                                {this.renderFilters(subcategory.filters)}
                                            </div>
                                            <button onClick={() => this.props.onFiltersChanged({
                                                query: this.state.query,
                                                filters: this.state.filters,
                                                subcategory: this.state.subCategorySelected,
                                                category: this.state.selected
                                            })} className={styles.button}>{this.translate("search.info.action_text")}</button>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.filtersOptions}>
                                        <button
                                            onClick={() => {
                                                this.setState({ show_filters: !this.state.show_filters })
                                            }}
                                            className={classNames(styles.filtersOptions, styles.action_button)}>
                                            <div className={styles.adjustments} />
                                            <span>{this.state.show_filters ? this.translate("search.hide_filter") : this.translate("search.show_filter")}</span>
                                        </button>
                                    </div>
                                </div>
                            </Container>
                        )}
                    </div>
                )}

            </div>
        )
    }
}

MapNavigation.defaultProps = {
    city: {},
    show_results: true,
    show_filters_button: true,
    subcategories: [],
    count: 0
}

class Filter extends Component {
    render() {
        return (
            <div className={styles.filter} {...this.props}>
                <b>{this.props.name}</b>
                {this.props.children}
            </div>
        )
    }
}

MapNavigation.Filter = Filter

export default injectIntl(MapNavigation)
