import React, { Component } from 'react'
import Link from 'next/link'
import classnames from 'classnames'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { logout } from '../../utils/auth'

class PopoverMenu extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    render() {
        return (
            <div className={styles.popover}>
              <ul>
                <li>
                    <Link
                        href={`/profile?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile`}>
                        <a className={styles.config}>{this.translate("header.menu.profile.general")}</a>
                    </Link>
                </li>
                <li>
                    <Link
                        href={`/profile?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile#my_categories`}>
                        <a className={styles.interest}>{this.translate("header.menu.profile.categories")}</a>
                    </Link>
                </li>
                <li>
                    <Link
                        href={`/profile?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                        as={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile#my_favorites`}>
                        <a className={styles.heart}>{this.translate("header.menu.profile.favorites")}</a>
                    </Link>
                </li>
                <li>
                    <a onClick={(event) => {
                        event.preventDefault()
                        logout(this.props.intl.locale)
                    }} className={styles.logout}>{this.translate("header.menu.profile.logout")}</a>
                </li>
              </ul>
            </div>
        )
    }
}

export default injectIntl(PopoverMenu)
