import React from "react";
import { injectIntl } from "react-intl";
import classnames from "classnames";
import styles from "./styles.scss";

const Column = (props) => {
  return (
    <div className={classnames(styles.column, props.className)}>
      <span className={styles.title}>{props.title}</span>
      {props.children}
    </div>
  );
};

export default injectIntl(Column);
