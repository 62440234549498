import React, { Component } from 'react'
import Router from 'next/router'
import { injectIntl } from 'react-intl'
import classNames from 'classnames';
import { GridContainer } from '../../components'
import styles from './styles.css'
import { recover } from '../../api'
import { ToastContainer, toast } from 'react-toastify';

class Input extends Component {
  render() {
      return (
          <div>
              <input
                className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
                {...this.props} />
          </div>
      )
  }
}

class RecoverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: '',
      errorMail: '',
      hasError: false
    }
  }

  translate = (id) => {
    return this.props.intl.formatMessage({ id })
  }

  _recover = (event) => {
    if (this._isValidForm()) {
      const { mail } = this.state;
      const recoverCallback = (r) => {
        toast.success(this.translate("recover.recover.success"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
          onClick: this._gotoLogin
          });
        this.setState({ mail: '' });
      }
      recover(mail, this.props.destination.rawId, this.props.lang, recoverCallback).catch(err => {
        toast.error(this.translate("recover.recover.error"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false
          });
      })
    }
  }

  _isValidForm = () => {
    const { mail } = this.state;
    var isValid = true;
    this.setState({ errorMail: '' });
    if (mail === '') {
      this.setState({ errorMail: 'Campo obligatorio' });
      isValid = false;
    //} else if (!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(mail)) {
    }else if(!(mail.indexOf('@') > 0 && mail.indexOf('@') < (mail.length - 1))){
      this.setState({ errorMail: 'Formato incorrecto' });
      isValid = false;
    }
    return isValid;
  }

  _gotoLogin = () => {
    Router.push(`/${this.props.lang}/${this.props.destination.rawId}/signin`)
  }

  render() {
    const { mail, errorMail } = this.state;

    return (
      <div>
        <GridContainer>
          <div className={styles.container}>
            <h1 className={styles.title}>{this.translate("recover.recover.row-title")}</h1>
            <div className={styles.contentMessage}>
              {this.translate("recover.recover.message")}
            </div>
            <div className={styles.separator}></div>

            <Input
              value={mail}
              onChange={(event) => {
                  this.setState({ mail: event.target.value })
              }}
              error={errorMail !== '' ? true : false}
              placeholder={this.translate("recover.recover.mail")} />

            <button
              onClick={this._recover}
              className={styles.button}>{this.translate("recover.recover.row-title").toUpperCase()}</button>
          </div>
        </GridContainer>
        <ToastContainer />
      </div>
    )
  }
}

export default injectIntl(RecoverForm)
