import React, { Component } from "react";
import styles from "./styles.css";
import {
  ExperienceItem,
  Container,
  SectionTitle,
  Arrow,
  CustomSlider,
  GridContainer,
} from "../";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import LoginModal from "../LoginModal";
import BorderButton from "../BorderButton";
import { withAppState } from "../../contexts/app-state";
import { addFavorite, removeFavorite } from "../../api";

class Experiences extends Component {
  loginModal = React.createRef();
  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };
  renderContent() {
    const { user } = this.props.appState;
    return (
      <div className={styles.categoriesContainer}>
        <GridContainer >
          <SectionTitle
            title={this.props.title || this.translate("experiences.featured")}
            intl={this.props.intl}
            see_more={this.props.see_more}
            key={this.props.key}
            href={this.props.href}
            description={this.props.description}
          />
          {this.props.highlights && (
            <div className={styles.highlights}>
              {this.props.highlights.map((item, index) => {
                item.favorite = (user.favourites || []).includes(item.id);
                return (
                  <ExperienceItem
                    onHeartClick={() => this.onHeartClick(item, index)}
                    type="highlight"
                    destination={this.props.destination}
                    categoryRawId={this.props.categoryRawId}
                    key={`highlight-${item.rawId}`}
                    item={item}
                    liked={item.favorite}
                    cancelAutomaticLiked={false}
                    isReccomended={this.props.isReccomended}
                    forYou={this.props.forYou}
                  />
                );
              })}
            </div>
          )}
          <div className={styles.experiences}>
            {this.props.data.map((item, index) => {
              item.favorite = (user.favourites || []).includes(item.id);
              return (
                <ExperienceItem
                  onHeartClick={() => this.onHeartClick(item, index)}
                  destination={this.props.destination}
                  categoryRawId={this.props.categoryRawId}
                  key={`normal-${item.rawId}`}
                  item={item}
                  liked={item.favorite}
                  cancelAutomaticLiked={false}
                  isReccomended={this.props.isReccomended}
                  onClick={this.props.onClick}
                  forYou={this.props.forYou}
                />
              );
            })}
          </div>
          <LoginModal
            destination={this.props.destination}
            ref={this.loginModal}
          />
        </GridContainer>
      </div>
    );
  }

  onHeartClick = (item, index) => {
    const {
      user: { is_logged },
      token,
    } = this.props.appState;
    if (is_logged) {
      if (item.favorite) {
        removeFavorite(token, item.rawId, () => {
          const index = this.props.appState.user.favourites.indexOf(item.id);
          if (index !== -1) {
            this.props.appState.user.favourites.splice(index, 1);
          }
          item.favorite = false;
          if(this.props.onRemoveFavorite){
            this.props.onRemoveFavorite(item);
          }
        });
        return;
      } else {
        addFavorite(token, item.rawId, () => {
          this.props.appState.user.favourites.push(item.id);
          item.favorite = true;
        });
        return;
      }
    }
    if (this.loginModal.current) this.loginModal.current.show();
  };

  render() {
    const { user } = this.props.appState;
    if (this.props.swipeable) {
      let experiences_data = this.props.highlights.concat([...this.props.data]);
      return (
        <div className={styles.categoriesContainer}>
          <GridContainer>
            <div className={styles.swiper_experiences}>
              {
                this.props.title && this.props.description &&
                <SectionTitle
                  title={this.props.title}
                  intl={this.props.intl}
                  see_more={this.props.see_more}
                  href={this.props.href}
                  description={this.props.description}
                  key={this.props.key}
                />
              }
              <div className={classNames(styles.swiper)}>
                <CustomSlider>
                  {experiences_data.map((item, index) => {
                    let style_type = undefined;
                    if (index === 0) {
                      style_type = "first_item";
                    }
                    item.favorite = (user.favourites || []).includes(item.id);
                    return (
                      <ExperienceItem
                        onHeartClick={() => this.onHeartClick(item, index)}
                        block_action={true}
                        type={style_type}
                        destination={this.props.destination}
                        categoryRawId={this.props.categoryRawId}
                        key={`swiper-${item.rawId}`}
                        item={item}
                        liked={item.favorite}
                        cancelAutomaticLiked={false}
                        isReccomended={this.props.isReccomended}
                        onClick={this.props.onClick}
                        forYou={this.props.forYou}
                      />
                    );
                  })}
                </CustomSlider>
                <LoginModal
                  destination={this.props.destination}
                  ref={this.loginModal}
                />
              </div>
              {this.props.see_more && (
                <BorderButton
                  href={this.props.href}
                  label={this.translate("section.title.see-all-results")}
                />
              )}
            </div>
          </GridContainer>
        </div>
      );
    }
    return this.renderContent();
  }
}

Experiences.defaultProps = {
  swipeable: false,
  data: [],
  highlights: [],
};

export default injectIntl(withAppState(Experiences));
