import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'
import Search from '../NavigationBar/Search'
import { injectIntl } from 'react-intl'

class StoreBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: props.categories,
            show_search_input: false
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    onChangeSearchVisibility = () => {
        this.setState({ show_search_input: !this.state.show_search_input })
    }
    render() {
        const catagoriesItems = this.props.categories.map((category, index) => {
          let classname = [styles.item]
          if (category.id === this.props.selected)
            classname.push(styles.selected)
          return (
              <div className={classnames(classname)} onClick={() => {
                this.props.change(index)
              }}>
                  <span>{category.name}</span>
              </div>
          )
        });

        let search_buttton_classname = [styles.button]
        if (this.props.show_search) {
            search_buttton_classname.push(styles.show_search_button_only)
        }
        if (this.state.show_search_input) {
            search_buttton_classname.push(styles.active)
        }

        let search_btn_class = [styles.search]
        if (this.state.show_search_input) {
            search_btn_class.push(styles.selected)
        }
        return (
            <div className={styles.navigation}>
                <div className={styles.main}>
                    <div className={styles.container}>
                        <div className={styles.categories}>
                          {catagoriesItems}
                        </div>
                        <div className={styles.filtersCommands}>
                            <div className={classnames(search_btn_class)}>
                                <button
                                    onClick={this.onChangeSearchVisibility}
                                    className={styles.action}>
                                    <span className={classnames([styles.icon, styles.magnify])}></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                { this.props.show_search && (
                    <div className={classnames(styles.main, styles.mobileCommands)}>
                        <div className={styles.container}>
                            {this.props.show_search && (
                                <a href="#" className={classnames(search_buttton_classname)} onClick={(event) => {
                                    event.preventDefault()
                                    this.onChangeSearchVisibility()
                                }}>
                                    <span className="icon icon-search"></span>
                                    <span>{this.props.searchText}</span>
                                </a>
                            )}
                        </div>
                    </div>
                )}

                {this.state.show_search_input && (
                    <Search
                        query={this.props.query}
                        placeholder={this.translate(this.props.searchPlaceholder)}
                        onSearchChanged={this.props.onSearchChanged}
                        onChangeSearchVisibility={this.onChangeSearchVisibility} />
                )}

            </div>
        )
    }
}

StoreBar.defaultProps = {
  selected: '',
  query: '',
  categories: [],
  show_results: true,
  show_search: true,
  commands: true,
  results: 0,
  is_loading: false,
  searchText: 'Search',
  resultsText: 'search.info.results',
  searchPlaceholder: "store.search.info.search",
  onSearchChanged(query) { },
  onCategoryChange(rawId) { }
}

export default injectIntl(StoreBar)
