import React, { Fragment, Component, useRef, forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { HeaderBar, Sidebar, Footer, Hero, StoreBar, StoreProducts } from '..'
import Seo from "../Seo/index";
import SectionTitle from '../SectionTitle'
import Experiences from '../Experiences'
import styles from './styles.css'
import classNames from 'classnames'
import querystring from 'querystring'
import Router from 'next/router'
import GridContainer from '../GridContainer'
import ReactGA from 'react-ga'
import Link from 'next/link'
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import { collections, products, search, fetchNextPage } from '../../api/shopify'

class ShopifyDetails extends Component {
    constructor(props) {
        super(props);
        this._internalRef = React.createRef();
        this.selectedCategory = this.selectedCategory.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.state = {
            categories: [],
            products: [],
            categoryId: '',
            textSearch: ''
        }
    }
    componentDidMount = () => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        const categories = collections(this.props.client, (categories) => {
            this.setState({
                categories: categories
            });
            this.selectedCategory(0);
        });
    }
    componentWillReceiveProps = (nextProps) => {

    }
    translate = (id, params = {}) => {
        return this.props.intl.formatMessage({ id }, { ...params })
    }
    showProductLink = () => {
        const { intl, experience } = this.props;
        return {
            href: `?lang=${intl.locale}&destination=${experience.data.destinationRawId}&categoryRawId=${experience.data.categoryRawId}&subCategoryRawId=${experience.data.subcategoryRawId}&experience=${experience.data.rawId}`, //${props.intl.locale}
            as: `/${intl.locale}/${experience.data.destinationRawId}/${experience.data.categoryRawId}/${experience.data.subcategoryRawId}/${experience.data.rawId}/shopify`
        }
    }
    getDetailLink = () => {
        return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`
    }
    selectedCategory = (index) => {
        const { categories } = this.state;
        const category = categories[index];
        if(category !== undefined && category.id !== this.state.categoryId){
            this.setState({ categoryId: category.id, products: category.products, textSearch: '' });
        }
    }
    renderContent = () => {
        if (!this.props.experience.data.rawId) return null

        const { products } = this.state
        let images = this.props.experience.data.pictures.filter((p) => p.main)[0]

        return (
            <Fragment>
                <Hero
                    title={this.props.experience.data.name}
                    description={this.props.experience.data.descriptionShort}
                    background={images ? images.url : undefined}
                    type="details"
                    locale={this.props.intl.locale} />
                {
                    (this.state.categories.length > 0 && products.length == 0) &&
                    <div className={styles.contentNotProducts}>
                        <label>Ups.. no tenemos nada que mostrarte</label>
                    </div>
                }
                {
                    (this.state.categories.length > 0 && products.length > 0) &&
                    <StoreProducts products={products} nextPage={this.nextPage} defaultLink={this.showProductLink()} />
                }
            </Fragment>
        )
    }
    nextPage = () => {
        const { products } = this.state;
        fetchNextPage(this.props.client, products, (products) => {
            this.setState({ products: [...this.state.products, ...products.model]});
        })
    }
    previousPage = () => {

    }
    onSearchChanged = (query) => {
        search(this.props.client, query, (products) => {
            this.setState({ products: products, textSearch: query, categoryId: ''});
        });
    }
    render() {
        const { categories, categoryId } = this.state;
        let { ogTitle, ogDescription, images } = this.props.experience.data
        const ogImage = images['tab']['ogimage'];
        return (
            <div>
                <Seo
                    config={{
                        title: ogTitle,
                        description: ogDescription,
                        canonical: `${WEB_ROOT}/`,
                        openGraph: {
                            type: 'website',
                            url: this.getDetailLink(),
                            title: ogTitle,
                            description: ogDescription,
                            site_name: 'discoolver',
                            images: [
                                {
                                    url: ogImage,
                                    width: 559,
                                    height: 413
                                },
                            ],
                        },
                        twitter: {
                            handle: '@handle',
                            site: '@site',
                            cardType: 'summary_large_image',
                        },
                        facebook: {
                            appId: 783375525490958,
                        }
                    }}
                />

                <HeaderBar
                    onSearchChanged={this.onSearchChanged}
                    destination={this.props.destination}
                    locale={this.props.locale} />

                <StoreBar
                    categories={categories}
                    selected={categoryId}
                    change={this.selectedCategory}
                    onSearchChanged={this.onSearchChanged}
                />

                {this.renderContent()}

                <Footer destination={this.props.destination} />
            </div>

        )
    }
}

export default injectIntl(ShopifyDetails)
