import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import cookies from 'js-cookie';
import Link from "next/link";
import { useRouter } from "next/router";
import { injectIntl } from "react-intl";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, DESTINATION } = publicRuntimeConfig;
import styles from "./styles.css";
import CookieConsent from "react-cookie-consent";

const CookieContent = (props) => {

  const [isCookieMinimal, setIsCookieMinimal] = useState(true)
  const [isCookieFunctioning, setIsCookieFunctioning] = useState(true)
  const [isCookiePerformance, setIsCookiePerformance] = useState(true)
  const [isCookieMarketing, setIsCookieMarketing] = useState(true)

  const router = useRouter();

  const translate = (id) => {
    return props.intl.formatMessage({ id });
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={translate("footer.cookie.btn")}
        cookieName="myAwesomeCookieName2"
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        containerClasses={styles.cookieContainer}
        contentClasses={styles.cookieContent}
        buttonClasses={styles.cookieButton}
        onAccept={() => {
          cookies.set("IsCookieMarketing", isCookieMarketing);
        }}
      >
        <div className={styles.divCookieContainer}>
          <div className={styles.imageCookieContainer}>
            <img src="/static/images/monstercookies.png" />
          </div>
          <div className={styles.textCookieContainer}>
            <h1>{translate("footer.cookie.title")}{" "}</h1>
            <p>
              {translate("footer.cookie.message")}{" "}
              &nbsp;
              <Link
                href={`/term?lang=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/term`}
              >
                <a>{translate("footer.cookie.legal.warning")}</a>
              </Link>
              &nbsp;
              <Link
                href={`/term?lang=${props.intl.locale}&destination=${props.destination.rawId}`}
                as={`/${props.intl.locale}/${props.destination.rawId}/term`}
              >
                <a>{translate("footer.cookie.privacy.policy")}</a>
              </Link>
            </p>

            <div className={styles.contentChecks}>
              <button
                className={classnames(styles.nocheck, {[styles.active]: isCookieMinimal})}>
                  Estrictamente necesarias
              </button>
              <button
                onClick={() => {
                  setIsCookieFunctioning(!isCookieFunctioning)
                }}
                className={classnames(styles.check, {[styles.active]: isCookieFunctioning})}>
                  Funcionamiento
              </button>
              <button
                onClick={() => {
                  setIsCookiePerformance(!isCookiePerformance)
                }}
                className={classnames(styles.check, {[styles.active]: isCookiePerformance})}>
                  Rendimiento
              </button>
              <button
                onClick={() => {
                  setIsCookieMarketing(!isCookieMarketing)
                }}
                className={classnames(styles.check, {[styles.active]: isCookieMarketing})}>
                  Marketing
              </button>
            </div>
          </div>
        </div>
      </CookieConsent>
    </>
  );
};

CookieContent.defaultProps = {

};

export default injectIntl(CookieContent);
