import React, { useRef, useEffect, useState } from 'react'
import styles from './styles.css'
import classnames from 'classnames'
import { injectIntl } from 'react-intl'
import { withRouter } from 'next/router'
import { skipKeywords } from 'json-schema-traverse'
import Router from 'next/router'

function PlanMyTripBar(props) {

    const parentComponent = useRef(null)
    const translate = (id) => props.intl.formatMessage({ id })
    const { index } = props;
    return (
        <>
            <div className={styles.content}>
                <div className={styles.contentTexts}>
                    <label data-value={'2'} className={classnames({[styles.label]: true, [styles.selected]: index === 0})}>
                        {translate('plan.my.trip.bar.two')}
                    </label>
                    <label data-value={'3'} className={classnames({[styles.label]: true, [styles.selected]: index === 2})}>
                        {translate('plan.my.trip.bar.four')}
                    </label>
                </div>
                <div className={styles.contentMarks}>
                    <span className={classnames({[styles.span]: true, [styles.selected]: index === 0})}>&nbsp;</span>
                    <span className={classnames({[styles.span]: true, [styles.selected]: index === 2})}>&nbsp;</span>
                </div>
            </div>
        </>
    )
}

PlanMyTripBar.defaultProps = {
    label: ''
}

export default injectIntl(withRouter(PlanMyTripBar))
