import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import classnames from 'classnames'
import mapStyles from './fancyMapStyles'
import styles from './styles.css'

class DiscoolverMap extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        items: [],
        renderItem() { return null },
        zoom: 11,
        language: 'en',
        region: 'en'
    }
    map = null
    maps = null
    createMapOptions = (maps) => {
        return {
            streetViewControl: false,
            fullscreenControl: false,
            rotateControl: false,
            panControl: false,
            mapTypeControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: this.props.zoomPosition || maps.ControlPosition.RIGHT_BOTTOM,
                style: maps.ZoomControlStyle.SMALL
            },
            scrollwheel: false,
            styles: mapStyles
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.items.length !== prevProps.items.length) {
            if (this.maps && this.map) {
                const bounds = this.getMapBounds(this.map, this.maps, this.props.items);
                this.map.fitBounds(bounds)
                this.bindResizeListener(this.map, this.maps, bounds)
            }
        }
    }
    hasLocation = i => !!i.latitude && !!i.longitude

    getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds();

        places.forEach((place) => {
            bounds.extend(new maps.LatLng(
                place.latitude,
                place.longitude
            ));
        });
        return bounds;
    }

    bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    }

    apiIsLoaded = (map, maps, places) => {
        this.maps = maps
        this.map = map
        const bounds = this.getMapBounds(map, maps, places);
        map.fitBounds(bounds);
        this.bindResizeListener(map, maps, bounds);
    };

    translate = (id) => this.props.intl.formatMessage({ id })

    render() {
        const items = this.props.items.filter(this.hasLocation)
        return (
            <div className={styles.contentMap}>
                {
                    this.props.onChangeMap &&
                    <div className={styles.contentButton}>
                        <button
                            onClick={this.props.onChangeMap}
                            className={classnames(styles.buttonConfig, styles.iconFilter)}>
                                {this.translate('map.see.results')}<span></span>
                        </button>
                    </div>
                }
                <GoogleMapReact
                    bootstrapURLKeys={{
                        language: this.props.language,
                        region: this.props.region,
                        key: 'AIzaSyAuNLmdVndnXfvCdpzBjei-DAuqq-q5wxQ'
                    }}
                    options={this.createMapOptions}
                    defaultCenter={this.props.center}
                    center={this.props.center}
                    onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps, items)}
                    defaultZoom={this.props.zoom}
                    zoom={this.props.zoom}
                    >
                    {items.map((item, index) => this.props.renderItem({ item, index }))}
                </GoogleMapReact>
            </div>

        )
    }
}

DiscoolverMap.Pin = (props) => {
    let pinStyles = [styles.pin]
    if (props.selected) {
        pinStyles.push(styles.selected)
    }
    return <div onClick={props.onClick} className={classnames(pinStyles)}></div>
}

DiscoolverMap.Pin.defaultProps = {
    onClick() { },
    selected: false
}

export default DiscoolverMap
