import React, { Component } from "react";
import styles from "./styles.css";
import {
  OrderItem,
  GridContainer,
} from "../";
import { injectIntl } from "react-intl";
import { withAppState } from "../../contexts/app-state";

class Orders extends Component {

  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };

  render() {
    return (
      <GridContainer>
        <div className={styles.orders}>
          {
            this.props.data.map((item, index) => {
              return (
                <OrderItem
                  destination={this.props.destination}
                  key={`normal-${index}`}
                  item={item}
                  onClick={this.props.onClick}
                />
              );
            })
          }
        </div>
      </GridContainer>
    );
  }
}

Orders.defaultProps = {
  data: [],
};

export default injectIntl(withAppState(Orders));
