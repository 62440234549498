import React, { Component } from "react";
import styles from "./styles.scss";
import classnames from "classnames";
import Link from "next/link";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, WEB_ROOT } = publicRuntimeConfig;
import { injectIntl } from "react-intl";
import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
import { logout, login } from "../../utils/auth";
import { singin, registerFacebook } from "../../api";
import { withAppState } from "../../contexts/app-state";
import CookieContent from "../CookieContent";
import RondaFooter from './RondaFooter'
import DiscoolverFooter from './DiscoolverFooter';

const responseFacebook = (response) => {
  var userId = response.id;
  registerFacebook(
    userId,
    response.name,
    response.email,
    response.picture.data.url,
    () => {
      singin(userId, "")
        .then((data) => {
          const { token } = data;
          login({
            lang: "es",
            token,
            type: "facebook",
          });
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  );
};

class Footer extends Component {
  state = {
    user: "",
    password: "",
    show_user_sidebar: false,
    tabs: {},
    totem: ''
  };
  componentDidMount() {
    this.setState({
      tabs: this.getSelectedTab(),
      totem: localStorage.getItem('totem')
    });
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  _login = () => {
    singin(this.state.user, this.state.password)
      .then((data) => {
        const { token } = data;
        login({
          lang: this.props.intl.locale,
          token,
          type: "web",
        });
      })
      .catch((err) => {});
  };
  getUserSidebar = () => {
    const { user } = this.props.appState;

    if (user.is_logged) {
      return (
        <div className={styles.sidebarContainer}>
          <div className={classnames(styles.login, styles.userLogged)}>
            <div className={styles.header}>
              <span>Mi Perfil</span>
              <b>@{user.login}</b>
            </div>
            <div className={styles.menuContent}>
              <div
                style={{
                  background: `url(${user.avatar ?? '/static/icons/profile.svg'})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className={styles.avatar}
              ></div>
              <Link
                href={`/profile?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/profile#my_favorites`}
              >
                <a className={classnames(styles.icons, styles.favorites)}>
                  <span>{this.translate("header.menu.profile.favorites")}</span>
                </a>
              </Link>
              <Link
                href={`/orders?locale=${this.props.intl.locale}&destination=${this.props.destination.rawId}`}
                as={`/${this.props.intl.locale}/${this.props.destination.rawId}/orders`}
              >
                <a className={classnames(styles.icons, styles.tools)}>
                  <span>{this.translate("footer.link.orders")}</span>
                </a>
              </Link>
              <a
                className={classnames(styles.icons, styles.contact)}
                href="mailto:info@discoolver.com"
              >
                <span>Contacto</span>
              </a>
            </div>
            <div className={classnames(styles.commands)}>
              <a
                href="#!"
                className={classnames(styles.icons, styles.logout)}
                onClick={(event) => {
                  event.preventDefault();
                  logout(this.props.intl.locale);
                }}
              >
                <span>Logout</span>
              </a>
            </div>
            {this.props.showCommands && (
              <div className={classnames(styles.commands)}>
                <button className={styles.action}>English (US)</button>
                <button className={styles.action}>Get Help</button>
              </div>
            )}
            <button
              onClick={() => {
                this.setState({ show_user_sidebar: false });
              }}
              className={styles.close}
            >
              close
            </button>
          </div>
        </div>
      );
    }
    return (
      <div
        className={styles.sidebarContainer}
        onClick={() => {
          this.setState({ show_user_sidebar: false });
        }}
      >
        <div
          className={styles.login}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classnames(styles.brand, styles[APP])}>
            <span>Discoolver</span>
          </div>
          <div className={styles.formContent}>
            <input
              onChange={(event) => {
                this.setState({ user: event.target.value });
              }}
              type="text"
              className={styles.input}
              placeholder="Nombre de usuario"
            />
            <input
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
              type="password"
              className={styles.input}
              placeholder="Contraseña"
            />
            <button onClick={this._login} className={styles.loginButton}>
              LOGIN
            </button>
            <a
              className={styles.span}
              href={`/${this.props.intl.locale}/${this.props.destination.rawId}/recover`}
            >
              ¿Olvidaste tu contraseña? Recuperar
            </a>

            <FacebookLogin
              appId="173530683203278"
              autoLoad={false}
              fields="name,email,picture"
              scope="public_profile,user_birthday,email"
              callback={responseFacebook}
              cssClass={styles.facebookButton}
              textButton={this.translate("login.login.login.with.facebook")}
            />

            <div className={styles.contentAppleLogin}>
              <AppleLogin
                clientId={"com.discoolver"}
                redirectURI={`${WEB_ROOT}/apple/login`}
                responseType={"code id_token"}
                responseMode={"form_post"}
                scope={"name email"}
                callback={(c) => {
                  c;
                }}
                designProp={{
                  height: 30,
                  width: 140,
                  color: "black",
                  border: false,
                  type: "sign-in",
                  border_radius: 0,
                  scale: 1,
                  locale: "en_US",
                }}
              />
            </div>

            <a
              className={styles.span}
              href={`/${this.props.intl.locale}/${this.props.destination.rawId}/register`}
              className={styles.register}
            >
              regístrate ahora
            </a>
          </div>
          <button
            onClick={() => {
              this.setState({ show_user_sidebar: false });
            }}
            className={styles.close}
          >
            close
          </button>
        </div>
      </div>
    );
  };
  isSelected = (path) => {
    if (typeof window !== "undefined") {
      return window.location.pathname.indexOf(path) == 0;
    }
    return false;
  };
  getSelectedTab = () => {
    let favorites = false;
    let mytrips = false;
    let orders = false;
    let profile = false;
    let explorer = false;
    if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/profile`
      )
    ) {
      profile = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/favorites`
      )
    ) {
      favorites = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/my-trips`
      )
    ) {
      mytrips = true;
    } else if (
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}/orders`
      )
    ) {
      orders = true;
    } else if (
      this.isSelected("/") ||
      this.isSelected(`/${this.props.intl.locale}`) ||
      this.isSelected(
        `/${this.props.intl.locale}/${this.props.destination.rawId}`
      )
    ) {
      explorer = true;
    }
    return {
      favorites,
      mytrips,
      orders,
      profile,
      explorer,
    };
  };
  changeLoginPopup = () => {
    const { show_user_sidebar } = this.state;
    this.setState({ show_user_sidebar: !show_user_sidebar });
  }
  render() {
    let search_query = `/${this.props.intl.locale}/${this.props.destination.rawId}/map`;
    let search_query_router = `/map?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`;
    if (this.props.categoryRawId) {
      search_query = `${search_query}?c=${this.props.categoryRawId}`;
      search_query_router = `${search_query_router}&c=${this.props.categoryRawId}`;
      if (this.props.subcategoryRawId) {
        search_query = `${search_query}&subc=${this.props.subcategoryRawId}`;
        search_query_router = `${search_query_router}&subc=${this.props.subcategoryRawId}`;
      }
    }
    const { tabs, show_user_sidebar, totem } = this.state;
    if(totem){
      return ('');
    }
    return (
      <footer
        className={classnames(
          styles.footer,
          this.props.footerContainerClassName
        )}
      >
        {APP === "discoolver" && (
          <DiscoolverFooter
            categoryRawId={this.props.categoryRawId}
            subcategoryRawId={this.props.subcategoryRawId}
            destination={this.props.destination}
            changeLoginPopup={this.changeLoginPopup}
            show_user_sidebar={show_user_sidebar}
            {...this.props.footerProps}
          />
        )}
        {APP === "ronda" && (
          <RondaFooter
            categoryRawId={this.props.categoryRawId}
            subcategoryRawId={this.props.subcategoryRawId}
            destination={this.props.destination}
            changeLoginPopup={this.changeLoginPopup}
            show_user_sidebar={show_user_sidebar}
            {...this.props.footerProps}
          />
        )}
        {this.state.show_user_sidebar && this.getUserSidebar()}
        {
          !totem &&
          <CookieContent destination={this.props.destination} />
        }
      </footer>
    );
  }
}

Footer.defaultProps = {
  city: {},
  user: {},
};

export default injectIntl(withAppState(Footer));
