import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'

import MultiSelect from "@khanacademy/react-multi-select"

class FilterDialog extends Component {
    render() {
        let subcategorySelected = [];
        if(this.props.subcategories !== undefined){
            const index = this.props.subcategories.findIndex((subcategory) => {
                return subcategory.rawId === this.props.subcategorySelected;
            });
            if(index > -1){
                subcategorySelected = [this.props.subcategories[index].rawId];
            }
        }
        return (
            <div className={classnames(styles.main, styles.filters)}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <span>{this.props.filtersText}</span>
                        <a onClick={e => {
                            e.preventDefault()
                            this.props.onChangeFiltersVisibility()
                        }} href="#">{this.props.closeFiltersText}</a>
                    </div>
                    <div className={classnames(styles.container, styles.filtersContainer)}>
                        {this.props.subcategories.length > 0 &&
                            <MultiSelect
                                className={styles.multiSelect}
                                overrideStrings={{
                                    selectSomeItems: this.props.subcategoryFilterText,
                                    allItemsAreSelected: this.props.allItemsAreSelectedText,
                                    selectAll: this.props.selectAllText,
                                    search: this.props.searchText,
                                }}
                                options={this.props.subcategories.map((i) => {
                                    return (
                                        {
                                            label: i.name,
                                            value: i.rawId,
                                            disabled: false
                                        }
                                    )
                                })}
                                selected={subcategorySelected}
                                key={`filters_subcategories`}
                                onSelectedChanged={(value) => {
                                    this.props.onSubcategoryChange(value)
                                }} />
                        }
                        {this.props.filters.map((filter, index) => {
                            let filtersActive = undefined;
                            if(this.props.filtersActive !== undefined){
                                this.props.filtersActive.map((actives) => {
                                    if(filter.idFilter === actives.idFilter){
                                        filtersActive = actives
                                    }
                                })
                            }
                            return (
                                <MultiSelect
                                    overrideStrings={{
                                        selectSomeItems: filter.name,
                                        allItemsAreSelected: this.props.allItemsAreSelectedText,
                                        selectAll: this.props.selectAllText,
                                        search: this.props.searchText,
                                    }}
                                    options={filter.items.map((i) => {
                                        let disabled = false
                                        if(this.props.filtersActive !== undefined){
                                            disabled = true
                                            if(filtersActive !== undefined){
                                                filtersActive.items.map((f) => {
                                                    if(i.idCategory === f.idCategory){
                                                        disabled = false
                                                    }
                                                })
                                            }
                                        }
                                        return (
                                            {
                                                label: i.name,
                                                value: i.idCategory,
                                                disabled: disabled
                                            }
                                        )
                                    })}
                                    selected={this.props.selected[filter.idFilter] || []}
                                    filter={filter}
                                    filtersActive={filtersActive}
                                    enables={this.props.filtersActive !== undefined}
                                    key={index}
                                    onSelectedChanged={(value) => {
                                        this.props.onFilterChanged(value, filter)
                                    }} />
                            )
                        })}
                    </div>
                    <div className={styles.containerButton}>
                        <button className={styles.button}
                            onClick={e => {
                                e.preventDefault()
                                this.props.onChangeFiltersVisibility()
                            }}>{this.props.buttonAcceptText}</button>
                    </div>
                </div>
            </div>
        )
    }
}

FilterDialog.defaultProps = {
    filters: [],
    filtersActive: undefined,
    selected: {},
    show_filters: true,
    filtersText: 'Filters',
    filterText: 'Filter',
    closeFiltersText: 'Close filters',
    buttonAcceptText: 'To Accept',
    allItemsAreSelectedText: "All Items are Selected",
    selectAllText: "Select All",
    searchText: "Search",
    subcategoryFilterText: "Subcategories",
    onFilterChanged() { },
    onChangeFiltersVisibility() { }
}

export default FilterDialog
