import React, { Component } from 'react'
import Router from 'next/router'
import { injectIntl } from 'react-intl'
import classNames from 'classnames';
import { GridContainer } from '../../components'
import styles from './styles.css'
import { finishRecover } from '../../api'
import { ToastContainer, toast } from 'react-toastify';

class Input extends Component {
  render() {
      return (
          <div>
              <input
                className={classNames({[styles.input]: true, [styles.input_error]: this.props.error})}
                {...this.props} />
          </div>
      )
  }
}

class FinishRecoverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      errorPassword: '',
      errorConfirmPassword: '',
      hasError: false
    }
  }

  translate = (id) => {
    return this.props.intl.formatMessage({ id })
  }

  _save = (event) => {
    if (this._isValidForm()) {
      const { password } = this.state;
      const recoverCallback = (r) => {
        toast.success(this.translate("finish.recover.recover.success"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
          onClick: this._gotoLogin
          });
        this.setState({ mail: '' });
      }
      finishRecover(password, this.props.token, recoverCallback).catch(err => {
       toast.error(this.translate("finish.recover.recover.error"), {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false
          });
      })
    }
  }

  _isValidForm = () => {
    const { password, confirmPassword } = this.state;
    var isValid = true;
    this.setState({ errorPassword: '', errorConfirmPassword: '' });

    if(password !== ''){
      if(password !== confirmPassword){
        isValid = false;
        this.setState({ errorConfirmPassword: 'No coinciden las contraseñas.' });
      }
    }else{
      isValid = false;
      this.setState({ errorPassword: 'La contraseña no puede ir vacia' });
    }

    return isValid;
  }

  _gotoLogin = () => {
    Router.push(`/${this.props.lang}/${this.props.destination.rawId}/signin`)
  }

  render() {
    const { password, errorPassword, confirmPassword, errorConfirmPassword } = this.state;

    return (
      <div>
        <GridContainer>
          <div className={styles.container}>
            <Input
              value={password}
              onChange={(event) => {
                  this.setState({ password: event.target.value })
              }}
              error={errorPassword !== '' ? true : false}
              type="password"
              placeholder={this.translate("finish.recover.recover.new.password")} />
            <Input
              value={confirmPassword}
              onChange={(event) => {
                  this.setState({ confirmPassword: event.target.value })
              }}
              error={errorConfirmPassword !== '' ? true : false}
              type="password"
              placeholder={this.translate("finish.recover.recover.confirm.password")} />
            <button
              onClick={this._save}
              className={styles.button}>{this.translate("finish.recover.recover.save").toUpperCase()}</button>
          </div>
        </GridContainer>
        <ToastContainer />
      </div>
    )
  }
}

export default injectIntl(FinishRecoverForm)
