import React, { Component } from 'react'
import styles from './styles.css'
import { ProfileTitle, GridContainer } from '../'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import LoginModal from '../LoginModal'
import { withAppState } from '../../contexts/app-state'
import { addCategoryOfInterest, deleteCategoryOfInterest } from '../../api'

class MyCategories extends Component {
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    add = (id) => {
      const { user } = this.props;
      this.setState({ finish: false });
      addCategoryOfInterest(user.token, id, () => {
        user.categories.forEach(c => {
          if(c.id === id){
            c.selected = true;
          }
        });
        this.setState({ finish: true });
      });
    }
    delete = (id) => {
      const { user } = this.props;
      this.setState({ finish: false });
      deleteCategoryOfInterest(user.token, id, () => {
        user.categories.forEach(c => {
          if(c.id === id){
            c.selected = false;
          }
        });
        this.setState({ finish: true });
      });
    }
    render() {
      const { user } = this.props;
      var catoriesRender = undefined;
      if(user.categories !== undefined){
        catoriesRender = user.categories.map(c => {
          return <label
            className={c.selected ? styles.selected : undefined}
            onClick={c.selected ? this.delete.bind(this, c.id) : this.add.bind(this, c.id)}>
              {c.name}
            </label>
        });
      }
      return (
        <GridContainer>
            <ProfileTitle
                title={'Tus intereses'}
                description={'Te mostramos los intereses que tienes actualmente activados en tu perfil. Para ofrecerte la mejor experiencia, marca aquellos que se adapten en cada momento.'}
                key={'my_categories'}
                anchor={'my_categories'} />
            <div className={styles.categories}>
              {catoriesRender}
            </div>
        </GridContainer>
      )
    }
}

MyCategories.defaultProps = {

}

export default injectIntl(withAppState(MyCategories))
