import React, { Component } from "react";
import styles from "./styles.css";
import {
  TripItem,
  GridContainer,
} from "../";
import { injectIntl } from "react-intl";
import { withRouter } from "next/router";
import { withAppState } from "../../contexts/app-state";

class Trips extends Component {

  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };

  gotoNewPlan = () => {
    this.props.router.push(
      `/plan-my-trip?lang=${this.props.intl.locale}&destination=${this.props.destination.rawId}`,
      `/${this.props.intl.locale}/${this.props.destination.rawId}/plan-my-trip`).then(() => window.scrollTo(0, 0));
  }

  render() {
    return (
      <GridContainer>
        <div className={styles.trips}>
          {
            this.props.data.map((item, index) => {
              return (
                <TripItem
                  destination={this.props.destination}
                  key={`normal-${index}`}
                  item={item}
                  onClick={this.props.onClick}
                />
              );
            })
          }
        </div>
        <button
          className={styles.button}
          onClick={this.gotoNewPlan}>
          {this.translate('my.trips.new.button')}
        </button>
      </GridContainer>
    );
  }
}

Trips.defaultProps = {
  data: [],
};

export default injectIntl(withAppState(withRouter(Trips)));
