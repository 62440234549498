import React, { Component } from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import styles from './styles.css'
import classnames from 'classnames'
import { isMobile } from "react-device-detect"

const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { WETRAVELHUB } = publicRuntimeConfig;

const MenuItem = ({ rawId, text, selected }) => {
    let classname = [styles.item]
    if (selected) classname.push(styles.selected)
    if(rawId == 'wetravelhum'){
        return (
            <div class="hth-button" hth-type="custom" hth-option="go" hth-location={WETRAVELHUB.location} hth-atk={WETRAVELHUB.atk}>
                <div className={classnames(classname)}>
                    {
                        styles[rawId] &&
                        <span className={classnames(styles.icon, styles[rawId])}></span>
                    }
                    <span>{text}</span>
                </div>
            </div>
        )
    }
    return (
        <div className={classnames(classname)}>
            {
                styles[rawId] &&
                <span className={classnames(styles.icon, styles[rawId])}></span>
            }
            <span>{text}</span>
        </div>
    )
}

export const Menu = (list, selected) =>
    list.map(el => {
        const { rawId, name } = el
        return <MenuItem text={name} rawId={rawId} key={rawId} selected={selected} />
    })


const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    )
}

const ArrowLeft = Arrow({ text: '<', className: styles.arrowLeft })
const ArrowRight = Arrow({ text: '>', className: styles.arrowRight })

class Categories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.selected,
            is_mobile: isMobile
        }
        this.items = Menu(props.items, props.selected)
    }

    componentDidMount() {
        this.setState({ is_mobile: isMobile })
    }

    onSelect = key => {
        this.setState({ selected: key }, () => {
            this.props.onCategoryChange(key)
        })
    }
    render() {
        const { selected, is_mobile } = this.state

        return (
            <ScrollMenu
                alignCenter={is_mobile}
                dragging={true}
                clickWhenDrag={false}
                scrollToSelected={true}
                wheel={false}
                inertiaScrolling={true}
                itemClass={styles.menuItemWrapper}
                innerWrapperClass={styles.innerWrapper}
                data={this.items}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected}
                onSelect={this.onSelect} />
        )
    }
}

export default Categories
