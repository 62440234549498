import React, { Fragment, Component } from 'react'
import { Footer, Hero, NavigationBar, CheckoutBar, CheckoutAddressForm } from '..'
import Link from 'next/link'
import { withRouter, useRouter, Router } from "next/router";
import ReactGA from 'react-ga'
import classNames from "classnames"
const getConfig = require('next/config').default
const { publicRuntimeConfig } = getConfig()
const { WEB_ROOT } = publicRuntimeConfig
import Experiences from '../Experiences'
import GridContainer from '../GridContainer'
import HeaderStoreBar from '../HeaderStoreBar'
import styles from './styles.css'
//import { addAddress } from "../../api/shopify";
import Seo from "../Seo/index";
import Order from "../../api/ecommerce/lib/order";
import Address from '../../api/ecommerce/lib/address'

class EcommerceCheckoutAddress extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.addUserData = this.addUserData.bind(this);
    this.state = {
      order: undefined
    }
  }
  componentDidMount = () => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      const client = this.props.client;
      let order = JSON.parse(localStorage.getItem('order'))
      if(order === null){
        client.createOrder().then((order) => {
          localStorage.setItem('order', JSON.stringify(order));
          this.setState({ order: order})
        });
      }else{
        client.order = Order.fromJson(order);
        this.setState({ order: client.order})
      }
  }
  componentWillReceiveProps = (nextProps) => {

  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`
  }
  addUserData = (data) => {
    const address = Address.fromFrom(data);
    this.props.client.addAddress(data.email, address).then((order) => {
      localStorage.setItem('order', JSON.stringify(order));
      this.setState({ order: order})
      this.props.client.finish().then(url => {
        console.log(`${url}`);
        window.location = `${url}`;
      });
    })
  }
  renderContent = () => {
    if (!this.props.experience.data.rawId) return null

    const { experience, categoryId, subcategoryId, destination, lang } = this.props

    let search_query = `/${lang}/${destination.rawId}/map`
    let search_query_router = `/map?lang=${lang}&destination=${destination.rawId}`
    if (categoryId) {
        search_query = `${search_query}?c=${categoryId}`
        search_query_router = `${search_query_router}&c=${categoryId}`
        if (subcategoryId) {
            search_query = `${search_query}&subc=${subcategoryId}`
            search_query_router = `${search_query_router}&subc=${subcategoryId}`
        }
    }

    search_query = `${search_query}&recommendationId=${experience.data.rawId}`
    search_query_router = `${search_query_router}&recommendationId=${experience.data.rawId}`

    let images = this.props.experience.data.pictures.filter((p) => p.main)[0]

    return (
      <Fragment>

        <CheckoutBar intl={this.props.intl} selected={[false, true, false]}/>

        <CheckoutAddressForm
          {...this.props}
          order={this.state.order}
          addUserData={this.addUserData}  />

        <Experiences
          swipeable={true}
          destination={this.props.destination}
          title={this.translate("recommended.recommended.near.title")}
          description={this.translate("recommended.recommended.near.subtitle", { name: this.props.experience.data.name })}
          data={this.props.experience.near}
          isReccomended={true} />
        <GridContainer>
          <div className={styles.seemap}>
            <Link
              href={search_query_router}
              as={search_query}
              >
              <a className={styles.button}>
                {this.translate("recommended.recommended.see_on_map")}
              </a>
            </Link>
          </div>
        </GridContainer>
      </Fragment>
    )
}
  getCategories = () => {
    return [{ rawId: 'para_ti', name: this.translate("sidebar.menu.for_you") }].concat(this.props.destination.categories)
  }
  render() {
    let { ogTitle, ogDescription, images } = this.props.experience.data
    const ogImage = images['tab']['ogimage'];

    let background = this.props.experience.data.pictures.filter((p) => p.main)[0]

    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: 'website',
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: 'discoolver',
              images: [
                  {
                      url: ogImage,
                      width: 559,
                      height: 413
                  },
              ],
            },
            twitter: {
              handle: '@handle',
              site: '@site',
              cardType: 'summary_large_image',
            },
            facebook: {
              appId: 783375525490958,
            }
          }}
        />

        <HeaderStoreBar
          ref={this.heroContent}
          locale={this.props.lang}
          destination={this.props.destination}
          destinations={this.props.destinations}
          background={background ? background.url : undefined}
          experience={this.props.experience.data}
          user={this.props.user}
          showSearch={false}
          order={this.props.client.order}
          />

        {this.renderContent()}

        <Footer destination={this.props.destination} />
      </div>
    )
  }
}

export default EcommerceCheckoutAddress
