import React, {
  Fragment,
  Component,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback,
} from "react";
import { injectIntl } from "react-intl";
import { HeaderBar, Sidebar, Footer, NavigationBar, ShopifyProduct } from "..";
import Seo from "../Seo/index";
import moment from "moment";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { WEB_ROOT, APP, __IS_RONDA__, WETRAVELHUB } = publicRuntimeConfig;
import SectionTitle from "../SectionTitle";
import Experiences from "../Experiences";
import styles from "./styles.css";
import classNames from "classnames";
import querystring from "querystring";
import Router from "next/router";
import GridContainer from "../GridContainer";
import ReactGA from "react-ga";
import Link from "next/link";
import Tickets from "../Tickets";
import { getProducts } from "../../api/index"
import Contact from "./components/Contact";
import HomeNavBarExperience from "../HomeNavBar/HomeNavBarExperience";

class ExperienceDetails extends Component {
  constructor(props) {
    super(props);
    this._internalRef = React.createRef();
    this.isTuritop = this.isTuritop.bind(this);
    this.notTuritop = this.notTuritop.bind(this);
    this.isContact = this.isContact.bind(this);
    this.state = {
      description: "",
      toggle_products: false,
      toggle_schedule: false,
      toogle_how_to_get_there: false,
      toogle_stayingTime: false,
      toggle_location: false,
      toggle_information: false,
      more_18_years: this.props.experience.data.subcategory
        .split(",")
        .includes("124"),
      isShopify: this.props.isShopify,
      products: this.props.experience.data.products,
      ticketStartDate: moment(new Date()),
      ticketEndDate: moment(new Date()),
      totem: undefined
    };
  }
  gallery = React.createRef();
  notLoose = React.createRef();
  getLink = (href) => {
    if (href != undefined) {
      if (href !== "" && href !== "#") {
        if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
          return "https://" + href;
        }
        return href;
      }
    }
    return "#!";
  };
  componentDidMount = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ description: this.props.experience.data.description, totem: localStorage.getItem('totem') });
    const body = document.getElementsByTagName("body")[0];
    body.classList.remove("map-viewer-search");
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.experience.data.rawId !== nextProps.experience.data.rawId) {
      this.setState({ 
        description: nextProps.experience.data.description,
        products: nextProps.experience.data.products
      });
    }
  };
  getDetailLink = () => {
    return `${WEB_ROOT}/${this.props.intl.locale}/${this.props.experience.data.destinationRawId}/${this.props.experience.data.categoryRawId}/${this.props.experience.data.subcategoryRawId}/${this.props.experience.data.rawId}`;
  };
  getShareMessage = () => {
    return `${this.getTitle()} \n${this.getDetailLink()}`;
  };
  getTitle = () => {
    return `${this.props.experience.data.name} ${APP}`;
  };
  shareOnFB = () => {
    var url = `https://www.facebook.com/sharer/sharer.php?u=${this.getDetailLink()}&t=${this.getTitle()}`;
    window.open(
      url,
      "Share on Facebook",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
    );
    return false;
  };
  shareOntwitter = () => {
    var url = `https://twitter.com/intent/tweet?url=${this.getDetailLink()}&text=${this.getTitle()}`;
    window.open(url, "Share on Twitter", "width=600,height=300");
    return false;
  };
  onChangeDateTicket = (date) => {
    const { intl, experience } = this.props;
    getProducts(intl.locale, experience.data.id, date.format('YYYYMMDD'), '').then(data => {
      console.log('products', data);
      this.setState({ products: data, ticketStartDate: date, ticketEndDate: date });
    });
  }
  onChangeRangeDateTicket = (start, end) => {
    const { intl, experience } = this.props;
    const { ticketStartDate, ticketEndDate } = this.state;

    //const searchStartDate = start;
    getProducts(intl.locale, experience.data.id, (start || ticketStartDate).format('YYYYMMDD'), (end || ticketEndDate).format('YYYYMMDD'), null).then(data => {
      this.setState({ products: data, ticketStartDate: (start || ticketStartDate), ticketEndDate: (end || ticketEndDate) });
    });
  }
  renderDay = (day) => {
    if (isNaN(day)) {
      return day;
    }
    return this.translate("date.days_week")[day];
  };
  renderNotLoose = () => {
    return (
      <Experiences
        swipeable={true}
        destination={this.props.destination}
        title={this.translate("recommended.recommended.not-loose.title")}
        description={' '}
        see_more={false}
        data={this.props.experience.data.notLooses.map((item) => {
          return {
            ...item,
            name: item.title,
            urlTabPicture: item.normal,
            images: {
              tab: {
                normal: item.normal,
                miniature: item.miniature,
              },
            },
            tab: {
              normal: item.normal,
              miniature: item.miniature,
            },
            descriptionShort: item.description,
          };
        })}
        isReccomended={false}
        onClick={(id) => {
          this.notLoose.current.show(id);
        }}
      />
    );
  };
  renderContent = () => {
    const experienceData = this.props.experience.data;
    if (!experienceData.rawId) return null;

    const {
      experience,
      categoryId,
      subcategoryId,
      destination,
      lang,
    } = this.props;

    const {
      products,
      ticketStartDate,
      ticketEndDate,
      totem
    } = this.state;

    let search_query = `/${lang}/${destination.rawId}/map`;
    let search_query_router = `/map?lang=${lang}&destination=${destination.rawId}`;
    if (categoryId) {
      search_query = `${search_query}?c=${categoryId}`;
      search_query_router = `${search_query_router}&c=${categoryId}`;
      if (subcategoryId) {
        search_query = `${search_query}&subc=${subcategoryId}`;
        search_query_router = `${search_query_router}&subc=${subcategoryId}`;
      }
    }

    search_query = `${search_query}&recommendationId=${experience.data.rawId}`;
    search_query_router = `${search_query_router}&recommendationId=${experience.data.rawId}`;

    let images = experienceData.pictures.filter((p) => p.main)[0];

    const locationsItems = experienceData.locations.map((location) => {
      return (
        <div className={styles.tab}>
          <span className={styles.tabName}>
            {this.translate("details.address")}:
          </span>
          <span>{location.address}</span>
        </div>
      );
    });

    let socialNetworksItems = undefined;
    if (experienceData.socialNetworks.length > 0) {
      socialNetworksItems = experienceData.socialNetworks.map((social) => {
        return (
          <a
            className={classNames({
              [styles.socialIcon]: true,
              [styles.facebook]: social.type === "FACEBOOK",
              [styles.twitter]: social.type === "TWITTER",
              [styles.whatsapp]: social.type === "WHATSAPP",
              [styles.instagram]: social.type === "INSTAGRAM",
              [styles.youtube]: social.type === "YOUTUBE",
            })}
            target="_blank"
            rel="noreferrer"
            href={`${social.value}`}
          ></a>
        );
      });
    }

    let infoTabsItems = undefined;
    if (experienceData.infoTabs.length > 0) {
      infoTabsItems = experienceData.infoTabs.map((tab) => {
        return (
          <div className={styles.row}>
            <div>{this.translate(`${tab.id}`) || tab.id}</div>
            <div>{tab.value}</div>
          </div>
        );
      });
    }

    const tags = experienceData.subcategoryName.split(", ");

    const contentShopping = !!experienceData.urlMembership || this.props.isShopify || experienceData.containsProducts;
    let textShopping = '';
    if(contentShopping){
      if(experienceData.urlMembership){
        if(this.isTuritop()){
          textShopping = experienceData.periodicSchedule
          ? this.translate("details.reserve_now")
          : this.translate("details.buy_now");
        }
        if(this.notTuritop() && this.isContact()){
          textShopping = "Ver";
        }
        if(this.notTuritop() && !this.isContact()){
          textShopping = experienceData.periodicSchedule
            ? this.translate("details.reserve_now")
            : this.translate("details.buy_now")
        }
      }
      if(this.props.isShopify){
        textShopping = this.translate("details.shopping")
      }
      if(experienceData.containsProducts){
        textShopping = experienceData.productType === 'PRODUCT'
            ? this.translate("details.buy_now")
            : this.translate("details.reserve_now")
      }
    }
    console.log('experienceData', experienceData)
    return (
      <Fragment>

        <HomeNavBarExperience
          ref={this.heroContent}
          locale={this.props.lang}
          destination={this.props.destination}
          destinations={this.props.destinations}
          title={experienceData.name}
          description={experienceData.descriptionShort}
          background={images ? images.url : undefined}
          user={this.props.user}
          selected={this.props.categoryId}
          categories={this.getCategories()}
          onSearchChanged={this.onSearchChanged}
          onCategoryChange={this.onCategoryChange}
          shopping={contentShopping}
          textShopping={textShopping}
        />

        <Sidebar>
          <Sidebar.Content className={!contentShopping ? styles.notContentShopping : undefined}>
            <h2 className={styles.pageTitle}>{experienceData.name}</h2>

            <div
              dangerouslySetInnerHTML={{ __html: this.state.description }}
            ></div>

            <div className={styles.galleryContainer}>
              {experienceData.pictures[0] && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.gallery.current.show(
                      experienceData.pictures[0].idPicture
                    );
                    this.setState({
                      show_gallery: true,
                      selected_gallery_item:
                        experienceData.pictures[0].idPicture,
                    });
                  }}
                  href="#"
                >
                  <div
                    className={styles.mainItem}
                    style={{
                      backgroundImage: `url(${experienceData.pictures[0].url})`,
                    }}
                  />
                </a>
              )}

              <div className={styles.gallery}>
                {experienceData.pictures.slice(1).map((i) => {
                  return (
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        this.gallery.current.show(i.idPicture);
                        this.setState({
                          show_gallery: true,
                          selected_gallery_item: i.idPicture,
                        });
                      }}
                      href="#"
                    >
                      <div
                        className={styles.galleryItem}
                        style={{ backgroundImage: `url(${i.url})` }}
                      />
                    </a>
                  );
                })}
              </div>
            </div>

            {tags.length > 0 && (
              <div className={styles.categories}>
                {tags.map((tag) => {
                  if(tag !== ''){
                    return <p className={styles.category}>{tag}</p>;
                  }
                })}
              </div>
            )}

            <SectionTitle title={"Detalles"} intl={this.props.intl} />

              <div className={styles.table}>
              {
                experienceData.latitude !== undefined && experienceData.longitude !== undefined &&
                  <div className={styles.row}>
                    <div>{this.translate("details.information")}</div>
                    <div>
                      <Link href={search_query_router} as={search_query}>
                        <a>
                          {this.translate(
                            "recommended.recommended.see_location_on_map"
                          )}
                        </a>
                      </Link>
                    </div>
                  </div>
              }

              <div className={styles.row}>
                <div>{this.translate("details.schedule")}</div>
                <div>
                  {experienceData.schedules.map((s) => {
                    return (
                      <div className={styles.tab}>
                        <span className={styles.tabName}>
                          {this.renderDay(s.day)}
                        </span>
                        {s.closed && (
                          <span>
                            {this.translate("details.schedule.closed")}
                          </span>
                        )}
                        {!s.closed && <span>{s.schedule}</span>}
                      </div>
                    );
                  })}
                </div>
              </div>

              {
                experienceData.access && experienceData.access.length > 0 &&
                <div className={styles.row}>
                  <div>{this.translate("details.how_to_get_there")}</div>
                  <div>
                    {experienceData.access.map((a) => {
                      return (
                        <div className={styles.tabColumn}>
                          <span
                            className={classNames(
                              styles.tabName,
                              styles.access_title
                            )}
                          >
                            {a.name.toLowerCase()}:
                          </span>
                          <span>{a.value}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              }

              <div className={styles.row}>
                <div>{this.translate("details.location")}</div>
                <div>{locationsItems}</div>
              </div>

              { experienceData.stayingTime &&
                <div className={styles.row}>
                  <div>{this.translate("details.staying.time")}</div>
                  <div>{experienceData.stayingTime} min.</div>
                </div>
              }

              {infoTabsItems}

              { socialNetworksItems &&
                <div className={styles.row}>
                  <div>{this.translate("details.social_networks")}</div>
                  <div>{socialNetworksItems}</div>
                </div>
              }
            </div>

            {experienceData.containsProducts && (
              <div id="shopping">
                <Tickets
                  key={`tickets_${experienceData.id}`}
                  schedules={experienceData.schedules}
                  groups={products}
                  defaultStartDate={ticketStartDate}
                  defaultEndDate={ticketEndDate}
                  categoryId={categoryId}
                  onChangeDate={this.onChangeDateTicket}
                  onChangeRangeDate={this.onChangeRangeDateTicket}
                  id={experience.data.id}
                  addClassContainer={styles.contentVerticalTickets}
                />
              </div>
            )}
          </Sidebar.Content>
          {
            contentShopping &&
            <Sidebar.Bar>
              {!!experienceData.urlMembership && (
                <Sidebar.Section>
                  {this.isTuritop() && (
                    <div
                      class="load-turitop"
                      data-service={experienceData.urlMembership}
                      data-lang="es"
                      data-embed="button"
                      data-afftag="ttafid"
                      data-cssclass={styles.button}
                    >
                      {experienceData.periodicSchedule
                        ? this.translate("details.reserve_now")
                        : this.translate("details.buy_now")}
                    </div>
                  )}
                  {this.notTuritop() && this.isContact() && (
                    <Contact
                      whatsapp={experienceData.urlMembership.replace(/ /g,'')}
                      business={experienceData}
                      destination={this.props.destination}
                      intl={this.props.intl}
                      totem={totem}
                    />
                  )}
                  {this.notTuritop() && !this.isContact() && (
                    <a
                      className={styles.button}
                      href={this.getLink(experienceData.urlMembership)}
                      target="_blank"
                    >
                      {experienceData.periodicSchedule
                        ? this.translate("details.reserve_now")
                        : this.translate("details.buy_now")}
                    </a>
                  )}
                </Sidebar.Section>
              )}
              {this.props.isShopify && (
                <Sidebar.Section>
                  <br />
                  <Link
                    href={`/ecommerce?locale=${this.props.intl.locale}&destination=${experienceData.destinationRawId}&categoryRawId=${experienceData.categoryRawId}&subCategoryRawId=${experienceData.categoryRawId}/${experienceData.subcategoryRawId}&experience=${experienceData.rawId}`}
                    as={`/${this.props.intl.locale}/${experienceData.destinationRawId}/${experienceData.categoryRawId}/${experienceData.subcategoryRawId}/${experienceData.rawId}/ecommerce`}
                  >
                    <a className={styles.button}>
                      {this.translate("details.shopping")}
                    </a>
                  </Link>
                </Sidebar.Section>
              )}
              {experienceData.containsProducts && (
                <div id={styles.shoppingBar}>
                  <Tickets
                    key={`tickets_2_${experienceData.id}`}
                    schedules={experienceData.schedules}
                    groups={products}
                    defaultStartDate={ticketStartDate}
                    defaultEndDate={ticketEndDate}
                    categoryId={categoryId}
                    onChangeDate={this.onChangeDateTicket}
                    onChangeRangeDate={this.onChangeRangeDateTicket}
                    id={experience.data.id}
                  />
                </div>
              )}
            </Sidebar.Bar>
          }
        </Sidebar>
        {experienceData.notLooses.length > 0 && this.renderNotLoose()}
        {
          !totem &&
          <Experiences
            swipeable={true}
            destination={this.props.destination}
            title={this.translate("recommended.recommended.near.title")}
            description={this.translate("recommended.recommended.near.subtitle", {
              name: experienceData.name,
            })}
            data={this.props.experience.near}
            isReccomended={true}
          />
        }
      </Fragment>
    );
  };
  isTuritop = () => {
    const turitopRegex = /^P[\d]+/;
    return turitopRegex.exec(this.props.experience.data.urlMembership) !== null;
  };
  notTuritop = () => {
    return !this.isTuritop();
  };
  isContact = () => {
    const experienceData = this.props.experience.data;
    return experienceData.typeOfMembership === 4;
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };
  onSearchChanged = (text, categoryRawId) => {
    let qs = querystring.stringify({ query: text });
    Router.push(
      `/${this.props.intl.locale}/${this.props.destination.rawId}/search?${qs}`
    );
  };

  getCategories = () => {
    return [
      { rawId: "wetravelhum", name: ''},
      { rawId: "para_ti", name: this.translate("sidebar.menu.for_you") },
    ].concat(this.props.destination.categories);
  };
  onCategoryChange = (rawId) => {
    if (rawId == "wetravelhum") {
      return null
    }else if (rawId == "para_ti") {
      Router.push(`/${this.props.intl.locale}/${this.props.destination.rawId}`);
      return;
    }
    Router.push(
      `/${this.props.intl.locale}/${this.props.destination.rawId}/${rawId}`
    );
  };
  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  render() {
    const { more_18_years } = this.state;
    const experienceData = this.props.experience.data;
    let { ogTitle, ogDescription, images } = experienceData;
    const ogImage = images["tab"]["ogimage"];
    return (
      <div>
        <Seo
          config={{
            title: ogTitle,
            description: ogDescription,
            canonical: `${WEB_ROOT}/`,
            openGraph: {
              type: "website",
              url: this.getDetailLink(),
              title: ogTitle,
              description: ogDescription,
              site_name: "discoolver",
              images: [
                {
                  url: ogImage,
                  width: 559,
                  height: 413,
                },
              ],
            },
            twitter: {
              handle: "@handle",
              site: "@site",
              cardType: "summary_large_image",
            },
            facebook: {
              appId: 783375525490958,
            },
          }}
        />

        {this.renderContent()}

        <Footer destination={this.props.destination} />

        <Gallery images={experienceData.pictures} ref={this.gallery} />

        <NotLoose notLooses={experienceData.notLooses} ref={this.notLoose} />

        {more_18_years && (
          <div className={styles.adultsOnly}>
            <button
              className={styles.adultsOnlyBtnMore18}
              onClick={() => {
                this.setState({ more_18_years: false });
              }}
            >
              {this.translate("adults_only.im_a_adult")}
            </button>
            <button
              className={styles.adultsOnlyBtnExit}
              onClick={() => {
                if (history.length == 0) {
                  Router.push("/");
                } else {
                  Router.back();
                }
              }}
            >
              {this.translate("adults_only.exit")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

function DetailsGallery(props, ref) {
  const windowRef = useRef(null);
  const close = useRef(null);
  const [selected, setSelected] = useState(undefined);

  const [visible, setVisible] = useState(false);

  const show = (id) => {
    setSelected(id);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  const next = () => {
    const nextElement = props.images.reduce((acc, item, index) => {
      if (acc !== -1) return acc;
      if (item.idPicture == selected) {
        if (props.images[index + 1]) {
          return props.images[index + 1].idPicture;
        }
      }
      return -1;
    }, -1);
    if (nextElement != -1) {
      show(nextElement);
      return;
    }
    show(props.images[0].idPicture);
  };

  const previous = () => {
    const nextElement = props.images.reduce((acc, item, index) => {
      if (acc !== -1) return acc;
      if (item.idPicture == selected) {
        if (props.images[index - 1]) {
          return props.images[index - 1].idPicture;
        }
      }
      return -1;
    }, -1);
    if (nextElement != -1) {
      show(nextElement);
      return;
    }
    show(props.images[props.images.length - 1].idPicture);
  };

  const onKeyUp = useCallback(
    (e) => {
      if (e.code === "ArrowLeft") {
        previous();
        return;
      }
      if (e.code === "ArrowRight") {
        next();
      }
    },
    [selected]
  );

  useEventListener("keyup", onKeyUp);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (visible) {
      body.classList.add("gallery-viewer");
      if (close.current) {
        close.current.focus();
      }
    } else {
      body.classList.remove("gallery-viewer");
    }
    return () => {
      body.classList.remove("gallery-viewer");
    };
  }, [visible]);

  useImperativeHandle(ref, () => ({
    show: (id) => show(id),
    hide: () => hide(),
  }));

  if (!visible) return null;

  return (
    <div ref={windowRef} className={styles.modalGallery}>
      <div className={styles.navbar}>
        <button
          ref={close}
          onClick={hide}
          className={classNames(styles.icon, styles.close)}
        ></button>
      </div>
      <div className={styles.modalContent}>
        <GridContainer>
          {props.images
            .filter((img) => img.idPicture == selected)
            .map((image, index) => {
              const position = props.images.reduce((acc, item, position) => {
                if (acc != -1) return acc;
                if (item.idPicture == selected) return position + 1;
                return -1;
              }, -1);
              return (
                <div className={styles.imageContainer} key={index}>
                  <div className={styles.thumbContainer}>
                    <img onClick={next} src={image.urlGallery} />
                    <span className={styles.badge}>
                      <b>{position}</b>/{props.images.length}
                    </span>
                    <div className={styles.title}>
                      <span>{/*image.title*/}</span>
                    </div>
                    <div className={classNames(styles.arrow, styles.arrowLeft)}>
                      <button
                        onClick={previous}
                        className={classNames(styles.icon, styles.iconLeft)}
                      ></button>
                    </div>
                    <div
                      className={classNames(styles.arrow, styles.arrowRight)}
                    >
                      <button
                        onClick={next}
                        className={classNames(styles.icon, styles.iconRight)}
                      ></button>
                    </div>
                  </div>
                </div>
              );
            })}
        </GridContainer>
        <div className={styles.thumbnailsContainer}>
          <div className={styles.thumbnails}>
            {props.images.map((image, index) => {
              let thumbnailStyle = [styles.thumbnail];
              if (image.idPicture == selected)
                thumbnailStyle.push(styles.selected);
              return (
                <button
                  onClick={() => show(image.idPicture)}
                  className={classNames(thumbnailStyle)}
                  key={index}
                >
                  <img src={image.url} />
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

DetailsGallery.defaultProps = {
  images: [],
};

function useEventListener(eventName, handler, element) {
  if (typeof window === "undefined") return;
  if (!element) {
    element = document;
  }
  const savedHandler = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

const Gallery = forwardRef(DetailsGallery);

function DetailNotLoose(props, ref) {
  const windowRef = useRef(null);
  const close = useRef(null);
  const [selected, setSelected] = useState(undefined);

  const [visible, setVisible] = useState(false);

  const show = (id) => {
    setSelected(id);
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (visible) {
      body.classList.add("gallery-viewer");
      if (close.current) {
        close.current.focus();
      }
    } else {
      body.classList.remove("gallery-viewer");
    }
    return () => {
      body.classList.remove("gallery-viewer");
    };
  }, [visible]);

  useImperativeHandle(ref, () => ({
    show: (id) => show(id),
    hide: () => hide(),
  }));

  if (!visible) return null;

  return (
    <div ref={windowRef} className={styles.modalGallery}>
      <div className={styles.navbar}>
        <button
          ref={close}
          onClick={hide}
          className={classNames(styles.icon, styles.close)}
        ></button>
      </div>

      <div className={styles.modalContent}>
        <GridContainer>
          {props.notLooses
            .filter((notLoose) => notLoose.idNotLoose == selected)
            .map((notLoose, index) => {
              const position = props.notLooses.reduce((acc, item, position) => {
                if (acc != -1) return acc;
                if (item.idNotLoose == selected) return position + 1;
                return -1;
              }, -1);
              return (
                <div className={styles.notLooseContainer} key={index}>
                  <div className={styles.notLooseSticker}>
                    <div
                      style={{ backgroundImage: `url(${notLoose.path})` }}
                      className={styles.image}
                      data-src={notLoose.path}
                    ></div>
                    <div className={styles.infos}>
                      <div>
                        <h4>{notLoose.title}</h4>
                        <p
                          className={styles.description}
                          dangerouslySetInnerHTML={{
                            __html: notLoose.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </GridContainer>
      </div>
    </div>
  );
}

DetailNotLoose.defaultProps = {
  notLooses: [],
};

const NotLoose = forwardRef(DetailNotLoose);

export default injectIntl(ExperienceDetails);
