import React from "react";
import Job from "./Job"
import styles from "./JobsList.styles.css";

const JobsList = ({ list }) => {
    return (
        <div className={styles.jobs}>
            <div className={styles.container}>
                {
                    list.map(job => {
                        const files = job.files.map(file => {
                            return <Job 
                                href={file.url}
                                as={file.url}
                                target={'blank'}
                                label={file.name}
                            />
                        })
                        return (
                            <div className={styles.job}>
                                <span className={styles.title}>{job.name}</span>
                                <div className={styles.files}>
                                    {files}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

JobsList.defaultProps = {
    list: []
};

export default JobsList;