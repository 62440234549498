import React, { useRef } from "react";
import styles from "./styles.css";
import { injectIntl } from "react-intl";
import { withRouter } from "next/router";
import Resume from "../Checkout/components/Resume";
import moment from "moment"

function OrderItem(props) {

  const { item } = props;
  const recommended = item.recommended;
  const products = item.products;
  const finishDate = item.finishDate;
  return (
    <>
      <div className={styles.orderitem}>
        <Resume {...{ recommended, products, finishDate }} />
      </div>
    </>
  );
}

OrderItem.defaultProps = {
  item: {},
};

export default injectIntl(withRouter(OrderItem));
