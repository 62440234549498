import Variant from "./variant";

class Product {
  id: string;
  name: string;
  description: string;
  images: string[];
  variants: Variant[];
  static fromDouble(id, name){
    return new this(id, name, 'El gran maestro de ceremonias. Nuestros cactus pizzaiolo vienen directamente de Nápoles, haciendo de la más auténtica tradición nuestra manera de hacer las cosas.', [
      'https://cdn.shopify.com/s/files/1/0307/4716/4811/products/calzoncillo_cactus_01_180x.png?v=1620630729',
      'https://cdn.shopify.com/s/files/1/0307/4716/4811/products/calzoncillo_cactus_02_180x.jpg?v=1620630729'
    ],
    [
      Variant.fromDouble("1", "M", 25.0),
      Variant.fromDouble("2", "L", 35.0),
      Variant.fromDouble("3", "XL", 55.0),
    ])
  }
  static fromShopify(product){
    var images = new Array();
    product.images.forEach((image) => {
      images.push(image.src);
    });
    var variants = new Array();
    product.variants.forEach((variant) => {
      variants.push(Variant.fromShopify(variant));
    })
    return new this(product.id, product.title, product.description, images, variants);
  }
  constructor(id, name, description, images, variants){
    this.id = id;
    this.name = name;
    this.description = description;
    this.images = images;
    this.variants = variants;
  }
}

export default Product;
