import React, { Component } from 'react'
import styles from './styles.css'
import classNames from "classnames"

class CheckoutBar extends Component {
  constructor(props){
    super(props);
  }
  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params })
  }
  render() {
    const { selected } = this.props;
    return (
      <div className={styles.checkoutbar}>
        <div className={classNames({[styles.checkoutbarItem]: true, [styles.selected]: selected[0]})}>
          <label className={styles.checkoutbarItemNumber}><span>01</span></label>
          <label className={styles.checkoutbarItemText}>Revisa tu pedido</label>
        </div>
        <div className={classNames({[styles.checkoutbarItem]: true, [styles.selected]: selected[1]})}>
          <label className={styles.checkoutbarItemNumber}><span>02</span></label>
          <label className={styles.checkoutbarItemText}>Datos personales</label>
        </div>
        <div className={classNames({[styles.checkoutbarItem]: true, [styles.selected]: selected[2]})}>
          <label className={styles.checkoutbarItemNumber}><span>03</span></label>
          <label className={styles.checkoutbarItemText}>Métodos de pago</label>
        </div>
      </div>
    )
  }
}

export default CheckoutBar
