import React, { Component } from 'react'
import styles from './styles.css'
import { products } from './../../api/shopify'

class ShopifyProduct extends Component {
  constructor(props){
    super(props);
    this.state = {
      listProducts: products(props.client)
    }
  }
  render() {
    const { listProducts } = this.state;
    if(listProducts == undefined || listProducts.length == 0){
      return (
        ''
      )
    }
    return (
        <div className={styles.sectionContent}>
            productos
        </div>
    )
  }
}

export default ShopifyProduct
