import React, {
  Fragment,
  Component,
} from "react";
import { injectIntl } from "react-intl";
import Experiences from "../Experiences";

class NearDetails extends Component {
  constructor(props) {
    super(props);
  }
  renderContent = () => {
    const experienceData = this.props.experience.data;
    if (!experienceData.rawId) return null;

    return (
      <Fragment>
        <Experiences
          swipeable={true}
          destination={this.props.destination}
          title={undefined}
          description={undefined}
          data={this.props.experience.near}
          isReccomended={false}
        />
      </Fragment>
    );
  };

  translate = (id, params = {}) => {
    return this.props.intl.formatMessage({ id }, { ...params });
  };

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

export default injectIntl(NearDetails);
