
class Address {
  firstName: string;
  lastName: string;
  phone: string;
  adress: string;
  city: string;
  province: string;
  country: string;
  zip: string;
  static fromFrom(form){
    return new this(form.firstName,
      form.lastName,
      form.phone,
      form.address,
      form.city,
      form.province,
      form.country,
      form.zipCode);
  }
  static fromShopify(address){
    return new this(
      address.firstName,
      address.lastName,
      address.phone,
      address.address1,
      address.city,
      address.province,
      address.country,
      address.zip);
  }
  constructor(firstName, lastName, phone, adress, city, province, country, zip){
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.adress = adress;
    this.city = city;
    this.province = province;
    this.country = country;
    this.zip = zip;
  }
}

export default Address;
