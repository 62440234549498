import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { GridContainer } from '../'

class ToggleCategories extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    render() {
        return (
            <GridContainer className={styles.storeContainer}>

            </GridContainer>
        )
    }
}

ToggleCategories.defaultProps = {

}

export default injectIntl(ToggleCategories)
