import React, { Component } from "react";
import { injectIntl } from "react-intl";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { DESTINATION } = publicRuntimeConfig;
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import styles from "./styles.css";
import Router from "next/router";
import GridContainer from "../GridContainer";
import classnames from "classnames";

class Hero extends Component {
  constructor(props) {
    super(props);
    this.onDestinationChanged = this.onDestinationChanged.bind(this);
    this.onRelationshipsChanged = this.onRelationshipsChanged.bind(this);
    this.onPerfectForChanged = this.onPerfectForChanged.bind(this);
    this.onDatesChanged = this.onDatesChanged.bind(this);
    this.goto = this.goto.bind(this);
    this.isTuritop = this.isTuritop.bind(this);
    this.notTuritop = this.notTuritop.bind(this);
    moment.locale(this.props.locale);
    this.state = {
      show_options: false,
      show_options_relationships: false,
      show_options_perfert_for: false,
      destination: this.getDestination(this.props.destination),
      relationships: this.getSeletctedRelationships(),
      perfectFor: this.getSeletctedPerfectFor(),
      startDate: this.props.startDate
        ? moment(this.props.startDate)
        : undefined,
      endDate: this.props.endDate ? moment(this.props.endDate) : undefined,
      focusedInput: null,
      focusedStart: undefined,
      focusedEnd: undefined,
      orientation: "horizontal",
      numberOfMonths: 2,
    };
  }
  componentDidMount() {
    moment.locale(this.props.locale);
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 640) {
      this.setState({ orientation: "horizontal", numberOfMonths: 1 });
    }
  }
  _getBackgroundStyle = () => {
    if (this.props.background) {
      return {
        backgroundImage: `url(${
          this.props.background.normal
            ? this.props.background.normal
            : this.props.background
        })`,
      };
    }
    return {};
  };
  getSeletctedDestination = () => {
    let destination = undefined;
    this.props.destinations.map((region) => {
      if (!destination) {
        const filtered = region.destinations.filter(
          (c) => c.rawId === this.props.destination
        );
        if (filtered.length > 0) {
          destination = filtered[0];
        }
      }
    });
    return destination;
  };
  getSeletctedRelationships = () => {
    if (!this.props.relationships) {
      return undefined;
    }
    let name = "";
    if (this.props.relationships == 1) {
      name = "home.filter.relationships.one";
    } else if (this.props.relationships == 2) {
      name = "home.filter.relationships.two";
    } else if (this.props.relationships == 3) {
      name = "home.filter.relationships.three";
    }
    return { id: this.props.relationships, name: name };
  };
  getSeletctedPerfectFor = () => {
    if (!this.props.perfectFor) {
      return undefined;
    }
    let name = "";
    if (this.props.perfectFor == 1) {
      name = "home.filter.perfert.for.one";
    } else if (this.props.perfectFor == 2) {
      name = "home.filter.perfert.for.two";
    } else if (this.props.perfectFor == 3) {
      name = "home.filter.perfert.for.three";
    } else if (this.props.perfectFor == 4) {
      name = "home.filter.perfert.for.four";
    } else if (this.props.perfectFor == 5) {
      name = "home.filter.perfert.for.five";
    }
    return { id: this.props.perfectFor, name: name };
  };
  translate = (id) => {
    return this.props.intl.formatMessage({ id });
  };
  getLink = (href) => {
    if (href != undefined) {
      if (href !== "" && href !== "#") {
        if (href.indexOf("http://") === -1 && href.indexOf("https://") === -1) {
          return "https://" + href;
        }
        return href;
      }
    }
    return "#!";
  };
  getDateInformations = () => {
    moment.locale(this.props.lang);
    let date = moment(this.props.date, "YYYYMMDD");
    return {
      week_day: date.format("dddd").toUpperCase(),
      day: date.format("DD"),
      month: date.format("MMMM YYYY"),
    };
  };
  onDestinationChanged = (value) => {
    const { type } = this.props;
    if (value !== undefined) {
      const destination = this.getDestination(value.rawId);
      if (destination !== undefined) {
        this.setState({ show_options: false, destination: destination });
        if (type === "plan") {
          this.props.onDestinationChangedPlan(destination);
        }
      }
    } else {
      this.setState({ show_options: false, destination: undefined });
    }
  };
  getDestination = (rawId) => {
    var foundDestination = undefined;
    this.props.destinations.forEach((d) => {
      let destination = d.destinations.filter((c) => c.rawId === rawId)[0];
      if (destination !== undefined) {
        foundDestination = destination;
      }
    });
    return foundDestination;
  };
  onRelationshipsChanged = (value) => {
    this.setState({ show_options_relationships: false, relationships: value });
  };
  onPerfectForChanged = (value) => {
    this.setState({ show_options_perfert_for: false, perfectFor: value });
  };
  onDatesChanged = (startDate, endDate) => {
    const { type } = this.props;
    this.setState({ startDate, endDate });
    if (type === "plan") {
      this.props.onDatesChangedPlan(startDate, endDate);
    }
  };
  renderDestinations = () => {
    const { destination } = this.state;
    return (
      <div className={classnames(styles.search, styles.destinations)}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: !this.state.show_options,
                show_options_perfert_for: false,
                show_options_relationships: false,
                focusedInput: null,
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span>
              {destination
                ? destination.name
                : this.translate("home.home.select_the_city")}
            </span>
          </button>
          {DESTINATION === "" && this.state.show_options && (
            <div className={classnames(styles.show_options, styles.places)}>
              {this.props.destinations.map((c) => {
                return (
                  <div key={c.id}>
                    <span className={styles.destinationTitle}>{c.name}</span>
                    {c.destinations.map((d, index) => {
                      return (
                        <button
                          onClick={() => {
                            this.onDestinationChanged(d);
                          }}
                          key={d.rawId}
                          className={styles.destinationItem}
                        >
                          <span>{d.name}</span>
                        </button>
                      );
                    })}
                  </div>
                );
              })}
              <div>
                <button
                  onClick={() => {
                    this.onDestinationChanged(undefined);
                  }}
                  key="destination_0"
                  className={classnames(
                    styles.destinationItem,
                    styles.separator
                  )}
                >
                  <span>
                    <b>{this.translate("search.clear_filter_xs")}</b>
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderRelationships = () => {
    const { relationships } = this.state;
    return (
      <div className={styles.relationships}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_relationships: !this.state
                  .show_options_relationships,
                show_options_perfert_for: false,
                focusedInput: null,
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_relationships: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span>
              {relationships && this.translate(relationships.name)}
              {!relationships &&
                this.translate("home.filter.relationships.zero")}
            </span>
          </button>
          {this.state.show_options_relationships && (
            <div className={styles.show_options}>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 1,
                    name: "home.filter.relationships.one",
                  });
                }}
                key="relationships_1"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.one")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 2,
                    name: "home.filter.relationships.two",
                  });
                }}
                key="relationships_2"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.two")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged({
                    id: 3,
                    name: "home.filter.relationships.three",
                  });
                }}
                key="relationships_3"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.relationships.three")}</span>
              </button>
              <button
                onClick={() => {
                  this.onRelationshipsChanged(undefined);
                }}
                key="relationships_0"
                className={classnames(styles.destinationItem, styles.separator)}
              >
                <span>
                  <b>{this.translate("search.clear_filter_xs")}</b>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  renderDate = () => {
    const { orientation, numberOfMonths } = this.state;
    return (
      <div
        className={classnames(styles.rangeDates, {
          [styles.DateInputFocused]: this.state.focusedInput !== null,
        })}
      >
        <DateRangePicker
          readOnly={true}
          noBorder={true}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.onDatesChanged(startDate, endDate)
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => {
            this.setState({
              focusedInput,
              show_options: false,
              show_options_relationships: false,
              show_options_perfert_for: false,
            });
          }}
          hideKeyboardShortcutsPanel={true}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          showClearDates={true}
        />
      </div>
    );
  };

  renderDates = () => {
    const { orientation, numberOfMonths } = this.state;
    return (
      <div
        className={classnames(styles.rangeDates, {
          [styles.DateInputFocused]: this.state.focusedInput !== null,
        })}
      >
        <DateRangePicker
          readOnly={true}
          noBorder={true}
          startDate={this.state.startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={this.state.endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) =>
            this.onDatesChanged(startDate, endDate)
          } // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => {
            this.setState({
              focusedInput,
              show_options: false,
              show_options_relationships: false,
              show_options_perfert_for: false,
            });
            return undefined;
          }}
          hideKeyboardShortcutsPanel={true}
          orientation={orientation}
          numberOfMonths={numberOfMonths}
          showClearDates={true}
          startDatePlaceholderText={this.translate("home.filter.dates.start")}
          endDatePlaceholderText={this.translate("home.filter.dates.end")}
        />
      </div>
    );
  };
  renderPerfectFor = () => {
    const { perfectFor } = this.state;
    return (
      <div className={styles.perfectFor}>
        <div className={styles.toggleContainer}>
          <button
            onClick={() => {
              this.setState({
                show_options: false,
                show_options_relationships: false,
                show_options_perfert_for: !this.state.show_options_perfert_for,
                focusedInput: null,
              });
            }}
            onBlur={() => {
              setTimeout(() => {
                this.setState({ show_options_perfert_for: false });
              }, 500);
            }}
            className={styles.toggleButton}
          >
            <span>
              {perfectFor && this.translate(perfectFor.name)}
              {!perfectFor && this.translate("home.filter.perfert.for.zero")}
            </span>
          </button>
          {this.state.show_options_perfert_for && (
            <div className={styles.show_options}>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 1,
                    name: "home.filter.perfert.for.one",
                  });
                }}
                key="perfert_for_1"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.one")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 2,
                    name: "home.filter.perfert.for.two",
                  });
                }}
                key="perfert_for_2"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.two")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 3,
                    name: "home.filter.perfert.for.three",
                  });
                }}
                key="perfert_for_3"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.three")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 4,
                    name: "home.filter.perfert.for.four",
                  });
                }}
                key="perfert_for_4"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.four")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged({
                    id: 5,
                    name: "home.filter.perfert.for.five",
                  });
                }}
                key="perfert_for_5"
                className={styles.destinationItem}
              >
                <span>{this.translate("home.filter.perfert.for.five")}</span>
              </button>
              <button
                onClick={() => {
                  this.onPerfectForChanged(undefined);
                }}
                key="perfert_for_1"
                className={classnames(styles.destinationItem, styles.separator)}
              >
                <span>
                  <b>{this.translate("search.clear_filter_xs")}</b>
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  renderByType = () => {
    if (this.props.type === "plan") {
      return (
        <GridContainer className={styles.heroContainer}>
          <div className={styles.titles}>
            <h1>{this.props.title}</h1>
            <h2>{this.props.description}</h2>
          </div>
          {this.renderDestinations()}
          {this.renderDates()}
        </GridContainer>
      );
    } else if (this.props.type === "calendar") {
      let date_informations = this.getDateInformations();
      return (
        <GridContainer>
          <div className={styles.calendar}>
            <div className={styles.container}>
              <div className={styles.dates}>
                <span>{date_informations.week_day}</span>
                <span className={styles.day}>{date_informations.day}</span>
                <span>{date_informations.month}</span>
              </div>
              <span>
                <span className={styles.badge}>
                  {this.props.results >= 100 ? "+99" : this.props.results}
                </span>{" "}
                {this.translate("calendarResult.calendar.description")}
              </span>
              {this.renderDestinations()}
              {this.renderPerfectFor()}
              {this.renderDate()}
              <a className={styles.goto} onClick={this.gotoCalendar}>
                <span>{this.translate("search.info.action_text")}</span>
              </a>
            </div>
          </div>
        </GridContainer>
      );
    }
    if (this.props.type === "details") {
      return (
        <GridContainer className={styles.container}>
          <div className={styles.details}>
            <h1>{this.props.title}</h1>
            <h2>{this.props.description}</h2>
            {!!this.props.action && this.notTuritop() && (
              <a
                className={styles.button}
                href={this.getLink(this.props.action)}
                target="_blank"
              >
                {this.props.actionText}
              </a>
            )}
            {!!this.props.action && this.isTuritop() && (
              <div
                class="load-turitop"
                data-service={this.props.action}
                data-lang="es"
                data-embed="button"
                data-afftag="ttafid"
                data-cssclass={styles.button}
              >
                {this.props.actionText}
              </div>
            )}
          </div>
        </GridContainer>
      );
    }
    if (this.props.type === "category") {
      return (
        <GridContainer className={styles.categoryContainer}>
          <div className={styles.details}>
            <h1>{this.props.title}</h1>
            <h2>{this.props.description}</h2>
          </div>
        </GridContainer>
      );
    }
    return (
      <GridContainer className={styles.heroContainer}>
        <div className={styles.titles}>
          <h1>{this.props.title}</h1>
          <h2>{this.props.description}</h2>
        </div>
        {this.renderDestinations()}
        {this.renderDates()}
        {this.renderRelationships()}
        {this.renderPerfectFor()}
        <a className={styles.goto} onClick={this.goto}>
          <span>{this.translate("search.info.action_text")}</span>
        </a>
      </GridContainer>
    );
  };
  isTuritop = () => {
    const { action } = this.props;
    const turitopRegex = /^P[\d]+/;
    return turitopRegex.exec(action) !== null;
  };
  notTuritop = () => {
    return !this.isTuritop();
  };
  gotoCalendar = () => {
    const { destination, perfectFor, startDate, endDate } = this.state;
    let perfectForId = "";
    if (perfectFor) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (startDate !== undefined) {
      start = startDate.format("YYYYMMDD");
    }
    let end = "";
    if (endDate !== undefined) {
      end = endDate.format("YYYYMMDD");
    }
    Router.push(
      `/${this.props.intl.locale}/${destination.rawId}/calendar?start=${start}&end=${end}perfectFor=${perfectForId}&`
    );
  };
  goto() {
    const {
      destination,
      relationships,
      perfectFor,
      startDate,
      endDate,
    } = this.state;
    let relationshipsId = "";
    if (relationships) {
      relationshipsId = relationships.id;
    }
    let perfectForId = "";
    if (perfectFor) {
      perfectForId = perfectFor.id;
    }
    let start = "";
    if (startDate !== undefined) {
      start = startDate.format("YYYYMMDD");
    }
    let end = "";
    if (endDate !== undefined) {
      end = endDate.format("YYYYMMDD");
    }
    Router.push(
      `/${this.props.intl.locale}/${destination.rawId}/for-you?start=${start}&end=${end}&relationships=${relationshipsId}&perfectFor=${perfectForId}&`
    );
  }
  render() {
    const hero_style = this._getBackgroundStyle();
    let hero_classnames = [styles.hero];
    if (!!this.props.type) {
      hero_classnames.push(styles.toLeft);
      if (this.props.type == "category") {
        hero_classnames.push(styles.categoryHero);
      }
    }
    return (
      <div className={classnames(hero_classnames)}>
        <div className={classnames(styles.image)} style={hero_style}></div>
        {this.renderByType()}
      </div>
    );
  }
}

Hero.defaultProps = {
  destinations: [],
};

export default injectIntl(Hero);
