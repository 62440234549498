import React, { Component } from 'react'
import classnames from 'classnames'
import Router from 'next/router'
import styles from './styles.css'
import { injectIntl } from 'react-intl'
import { GridContainer,  ToggleCategories, ToggleSwitch, TitlePlan, SubtitlePlan, Radio } from '../'
import { searchPlan, finishPlan, finishUserPlan} from './../../api'

class ChooseCategoriesPlanMyTrip extends Component {
    constructor(props) {
        super(props)
        this.onChangeSwith = this.onChangeSwith.bind(this)
        this.onChangeAllSwith = this.onChangeAllSwith.bind(this)
        this.onSearch = this.onSearch.bind(this)
        this.state = {
          categories: [],
          all: false
        }
    }
    translate = (id) => {
        return this.props.intl.formatMessage({ id })
    }
    onChangeSwith = (id) =>{
      console.log(id);
      const { categories } = this.state;
      if(categories.includes(id)){
        categories.splice(categories.indexOf(id), 1);
      }else{
        categories.push(id);
      }
      this.setState({ categories: categories })
    }
    onChangeAllSwith = () => {
      const { categories } = this.props;

      if(categories.length === this.state.categories.length){
        this.setState({ categories: [], all: false })
      }else{
        var selecteds = []
        categories.forEach(c => {
          selecteds.push(c.id);
        });
        this.setState({ categories: selecteds, all: true })
      }
    }
    onSearch = () => {
      const { lang, destination, startDate, relationships, perfectFor, endDate, intl, user } = this.props;
      const { categories } = this.state;
      console.log('destination', destination);
      console.log('categories', categories);
      console.log('startDate', startDate.format('YYYY-MM-DD'));
      console.log('endDate', endDate.format('YYYY-MM-DD'));
      console.log('relationships', relationships);
      console.log('perfectFor', perfectFor);
      searchPlan(destination.rawId, categories, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), relationships, perfectFor, (data) => {
        //Router.push(`/${intl.locale}/${destination.rawId}/plan-my-trip/${data.token}`)
        console.log(user)
        if(user.is_logged){
          finishUserPlan(data.token, user.token, () => {
            Router.push(`/${intl.locale}/${this.props.destination.rawId}/plan-my-trip/${data.token}/confirm`)
          })
        }else{
          finishPlan(data.token, () => {
            Router.push(`/${intl.locale}/${this.props.destination.rawId}/plan-my-trip/${data.token}/confirm`)
          })
        }
      }, lang);
    }
    render() {
      const { categories } = this.props;
      const items = categories.map(c => {
        if(c.results === 0){
          return undefined
        }
        const index = this.state.categories.findIndex((category) => {
          return category === c.id;
        })

        return (
          <div
            className={classnames({[styles.categoriaItem]: true, [styles.selected]: index >= 0})}
            onClick={this.onChangeSwith.bind(this, c.id)}
            style={{backgroundImage:`url(${c.image})`}}>
              <label className={styles.name}>{c.name}</label>
          </div>
        )
      })
      return (
          <GridContainer className={styles.contentPlan}>
            <div className={styles.contentSubtitle}>
              <SubtitlePlan text={'plan.my.trip.subtitle'} />
              <div className={styles.contentFooter}>
                <button onClick={this.onChangeAllSwith}>{this.translate('plan.my.trip.all.check')}</button>
                <button onClick={this.onSearch}>{this.translate('plan.my.trip.continue')}</button>
              </div>
            </div>
            <div className={styles.contentItems}>
              {items}
            </div>
            <div className={styles.contentFooter}>
              <button onClick={this.onChangeAllSwith}>{this.translate('plan.my.trip.all.check')}</button>
              <button onClick={this.onSearch}>{this.translate('plan.my.trip.continue')}</button>
            </div>
          </GridContainer>
        )
    }
}

ChooseCategoriesPlanMyTrip.defaultProps = {

}

export default injectIntl(ChooseCategoriesPlanMyTrip)
